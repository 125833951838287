import React, { useEffect, useRef, useState } from 'react';
import './store-button-row.styles.scss';
import { faCubesStacked, faDice, faExchange, faGift, faGlobeAmericas, faPiggyBank, faTicket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const StoreButtonRow = ({ 
    gameData, 
    availableItems, 
    setItemType, 
    itemType 
}) => {
    
    const [ counts, setCounts ] = useState({})
    const [ showText, setShowText ] = useState(false);
    const [ initialTypeIsSet, setInitialTypeIsSet ] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            handleResize();
        }, 50)    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        
    }, [])

    useEffect(() => {
        if (!availableItems || initialTypeIsSet) return;
        const itemTypeArr = [
            'inventory',
            'realworld',
            'collectible',
            'lottery',
            'prizepack'
        ];
        for (let type of itemTypeArr) {
            let availItems = Object.values(availableItems)
            .filter(i =>
                i.type === 'item' &&
                i.opt_visible
            )
            const typeCount = availItems.filter(i => i.opt_itemType === type).length
            if (typeCount > 0) {
                setItemType(type);
                setInitialTypeIsSet(true);
                return;
            }
        }
    }, [availableItems, initialTypeIsSet])

    useEffect(() => {
        if (!availableItems) return;
        console.log(availableItems);
        let countObj = {
            'inventory': availableItems.filter(i => i.opt_itemType === 'inventory').length,
            'realworld': availableItems.filter(i => i.opt_itemType === 'realworld').length,
            'collectible': availableItems.filter(i => i.opt_itemType === 'collectible').length,
            'lottery': availableItems.filter(i => i.opt_itemType === 'lottery').length,
            'contribution': availableItems.filter(i => i.opt_itemType === 'contribution').length,
            'prizepack': availableItems.filter(i => i.opt_itemType === 'prizepack').length
        }
        // console.log(availItems)
        console.log(countObj);
        setCounts({...countObj});
        handleResize();
    }, [availableItems])

    const handleResize = () => {
        if (!textRef.current) {
            // setShowText(false);
            return;
        };
        const buttonWidth = textRef.current.offsetWidth;
        console.log(buttonWidth);
        setShowText(buttonWidth >= 150); // Adjust 150 for your desired minimum width
    };

    return (
        <div className='store-button-row'>
            {
                (counts.inventory > 0) &&
                <button 
                    className={
                        `g-button med-btn ${
                            itemType === "inventory" 
                            ? "primary"
                            : ""
                        }`
                    }
                    ref={textRef}
                    onClick={() => setItemType("inventory")}
                    title='Game Items'
                >
                    <FontAwesomeIcon icon={faDice} size={showText ? 'lg' : 'xl'} />
                    {
                        (showText) &&
                        <div >Game Items</div>
                    }
                </button>
            }
            {
                (counts.realworld > 0) &&
                <button 
                    className={
                        `g-button med-btn ${
                            itemType === "realworld" 
                            ? "primary" 
                            : ""
                        }`
                    }
                    onClick={() => setItemType("realworld")}
                    ref={textRef}
                    title='Real Items'
                >
                    <FontAwesomeIcon icon={faGlobeAmericas} size={showText ? 'lg' : 'xl'} />
                    {
                        (showText) &&
                        <div>Real Items</div>
                    }
                </button>
            }
            {
                (counts.collectible > 0) &&
                <button 
                    className={
                        `g-button med-btn ${
                            itemType === "collectible"
                            ? "primary"
                            : ""
                        }`
                    }
                    onClick={() => setItemType("collectible")}
                    ref={textRef}
                    title='Collectibles'
                >
                    <FontAwesomeIcon icon={faCubesStacked} size={showText ? 'lg' : 'xl'} />
                    {
                        (showText) &&
                        <div>Collectibles</div>
                    }
                </button>
            }
            {
                (counts.lottery > 0) &&
                <button 
                    className={
                        `g-button med-btn ${
                            itemType === "lottery" 
                            ? "primary"
                            : ""
                        }`
                    }
                    onClick={() => setItemType("lottery")}
                    ref={textRef}
                    title='Lottery Tickets'
                >
                    <FontAwesomeIcon icon={faTicket} size={showText ? 'lg' : 'xl'} />
                    {
                        (showText) &&
                        <div>Lottery Tickets</div>
                    }
                </button>
            }
            {
                (counts.contribution > 0) &&
                <button 
                    className={
                        `g-button med-btn ${
                            itemType === "contribution" 
                            ? "primary"
                            : ""
                        }`
                    }
                    onClick={() => setItemType("contribution")}
                    ref={textRef}
                    title='Contributions'
                >
                    <FontAwesomeIcon icon={faPiggyBank} size={showText ? 'lg' : 'xl'} />
                    {
                        (showText) &&
                        <div>Contributions</div>
                    }
                </button>
            }
            {
                (counts.prizepack > 0) &&
                <button 
                    className={
                        `g-button med-btn ${
                            itemType === "prizepack" 
                            ? 'primary' 
                            : ''
                        }`
                    }
                    onClick={() => setItemType("prizepack")}
                    ref={textRef}
                    title='Prize Packs'
                >
                    <FontAwesomeIcon icon={faGift} size={showText ? 'lg' : 'xl'} />
                    {
                        (showText) &&
                        <div>Prize Packs</div>
                    }
                </button>
            }
            {/* {
                (gameData.access.includes('tradePortal')) &&
                <button 
                    className={
                        `g-button med-btn ${
                            itemType === "tradeportal" 
                            ? 'primary' 
                            : ''
                        }`
                    }
                    onClick={() => setItemType("tradeportal")}
                    ref={textRef}
                    title='Trade Portal'
                >
                    <FontAwesomeIcon icon={faExchange} size={showText ? 'lg' : 'xl'} />
                    {
                        (showText) &&
                        <div>Trade Portal</div>
                    }
                </button>
            } */}
        </div>
    )
}

export default StoreButtonRow