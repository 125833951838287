import React, { useEffect, useState } from 'react'
import './side-menu.styles.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faBell, faTrophy, faSuitcase, faGem, faMagnifyingGlass, faGear, faShoppingCart, faUserSecret, faFlag, faMap, faUsers, faListDots, faRectangleList, faListCheck, faEarthAmericas, faDiceSix, faDice, faGlobeAmericas, faToolbox } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

export function switchPlayerMenu(id) {
    // const btns = document.getElementsByClassName('menu-btn')
    // for (let btn of btns) {
    //     btn.classList.remove('active');
    // }
    // document.getElementById(id).classList.add('active');
}

const PlayerSideMenu = ({ gameData, playerData, playerActivity }) => {
    
    const location = useLocation();
    const [ path, setPath ] = useState('');
    const [ rewardCount, setRewardCount ] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (!location) return;
        setPath(location.pathname);
    }, [location])

    useEffect(() => {
        if (!playerData || !playerActivity) return;
        if (playerData.newStuff && playerData.newStuff.length > 0) {
            let count = 0;
            for (let activityId of playerData.newStuff) {
                if (
                    playerActivity[activityId] && 
                    (
                        playerActivity[activityId].display === 'reward' ||
                        playerActivity[activityId].display === 'prizepack'
                    )
                ) {
                    count++;
                }
            }
            setRewardCount(count);
        } else {
            setRewardCount(0);
        }
    }, [playerData, playerActivity])

    return (
        <div className='player-side-menu'>
            <div className='card-title'>My Links</div>
            <div className="g-space-1"></div>
            <div className='button-list'>
                <button 
                    className={
                        `g-button menu-btn ${
                            (
                                path === `/play/${gameData.path}` || 
                                path.includes(`/play/${gameData.path}/my`)
                            )
                            ?
                            'active' : 
                            ''
                        }`
                    } 
                    title="Home" 
                    onClick={() => navigate('')} 
                >
                    <FontAwesomeIcon 
                        icon={faHouse} 
                        className='btn-icon' 
                        size='xl' 
                    />
                    <div className='btn-text'>
                        Home
                    </div>    
                </button>
                {/* INVENTORY */}
                {
                    (gameData && gameData.access.includes('items')) && 
                    <button className={`g-button menu-btn ${path.includes(`/play/${gameData.path}/inventory`) ? 'active' : ''}`} title="My Inventory" onClick={() => navigate('inventory')} >
                        <FontAwesomeIcon icon={faToolbox} className='btn-icon' size='xl' />
                        <div className='btn-text'>My Inventory</div>
                    </button>
                }
                {/* REWARDS */}
                {
                    (playerData) && 
                    <button className={`${playerData.newStuff && playerData.newStuff.length > 0 ? "g-button menu-btn pending" : "g-button menu-btn"} ${path.includes(`/play/${gameData.path}/rewards`) ? 'active' : ''}`} title="My Rewards" onClick={() => navigate('rewards')} >
                        <FontAwesomeIcon icon={faGem} className='btn-icon' size='xl' />
                        <div className='btn-text'>My Rewards{rewardCount > 0 ? ` (${rewardCount.toLocaleString()})` : ''}</div>
                    </button>
                }
                {/* STORE */}
                {
                    (gameData && gameData.access.includes('marketplace')) &&
                    <button 
                        className={`g-button menu-btn ${path.includes(`/play/${gameData.path}/marketplace`) ? 'active' : ''}`} 
                        title="Marketplace" 
                        onClick={() => navigate('marketplace')}
                    >
                        <FontAwesomeIcon 
                            icon={faShoppingCart} 
                            className='btn-icon' 
                            size='xl' 
                        />
                        <div className='btn-text'>
                            Marketplace
                        </div>
                    </button>
                }
                {/* SECRET CODES */}
                {
                    (gameData && gameData.access.includes('secretCodes')) &&
                    <button 
                        className={`g-button menu-btn ${path.includes(`/play/${gameData.path}/secretcode`) ? 'active' : ''}`} 
                        title="Submit a Secret Code" 
                        onClick={() => navigate('secretcode')}
                    >
                        <FontAwesomeIcon 
                            icon={faUserSecret} 
                            className='btn-icon' 
                            size='xl' 
                        />
                        <div className='btn-text'>
                            Submit a Secret Code
                        </div>
                    </button>
                }
                {/* CHALLENGES */}
                {
                    (gameData && gameData.access.includes('challenges')) &&
                    <button 
                        className={`g-button menu-btn ${path.includes(`/play/${gameData.path}/challenges`) ? 'active' : ''}`} 
                        title="Challenges" 
                        onClick={() => navigate('challenges')}
                    >
                        <FontAwesomeIcon 
                            icon={faFlag} 
                            className='btn-icon' 
                            size='xl' 
                        />
                        <div className='btn-text'>
                            Challenges
                        </div>
                    </button>
                }
                {/* QUESTS */}
                {
                    (gameData && gameData.access.includes('quests')) &&
                    <button 
                        className={`g-button menu-btn ${path.includes(`/play/${gameData.path}/quests`) ? 'active' : ''}`} 
                        title="Go on a Quest" 
                        onClick={() => navigate('quests')}
                    >
                        <FontAwesomeIcon 
                            icon={faMap} 
                            className='btn-icon'  
                            size='xl' 
                        />
                        <div className='btn-text'>
                            Go on a Quest
                        </div>
                    </button>
                }
                {/* TEAMS */}
                {/* {
                    (gameData && gameData.access.includes('teams')) &&
                    <button 
                        className={`g-button menu-btn ${path.includes(`/play/${gameData.path}/teams`) ? 'active' : ''}`} 
                        title="View My Teams" 
                        onClick={() => navigate('teams')}
                    >
                        <FontAwesomeIcon 
                            icon={faUsers} 
                            className='btn-icon' 
                            size='xl' 
                        />
                        <div className='btn-text'>
                            View My Teams
                        </div>
                    </button>
                } */}
                {/* LEADERBOARDS */}
                {
                    (
                        gameData && 
                        gameData.access.includes('leaderboards') 
                    ) &&
                    <button 
                        className={`g-button menu-btn ${path.includes(`/play/${gameData.path}/leaderboards`) ? 'active' : ''}`} 
                        title="Leaderboards" 
                        onClick={() => navigate('leaderboards')}
                    >
                        <FontAwesomeIcon 
                            icon={faRectangleList} 
                            className='btn-icon' 
                            size='xl' />
                        <div className='btn-text'>
                            Leaderboards
                        </div>
                    </button>
                }
                <button 
                    className={`g-button menu-btn ${
                        path.includes(`/play/${gameData.path}/gameactivity`) ||
                        path.includes(`/play/${gameData.path}/transaction`) 
                        ? 'active' : ''
                    }`} 
                    title="View Game Activity" 
                    onClick={() => navigate('gameactivity')}
                >
                    <FontAwesomeIcon 
                        icon={faGlobeAmericas} 
                        className='btn-icon' 
                        size='xl' 
                    />
                    <div className='btn-text'>
                        View Game Activity
                    </div>
                </button>
            </div>
                
        </div>
  )
}

export default PlayerSideMenu;