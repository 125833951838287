import React, { useEffect, useState } from 'react';
import './org-view.styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import OrgSettings from './org-settings/org-settings.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';


const OrgView = ({ orgList }) => {

    const { orgPath } = useParams();
    const [ currentOrg, setCurrentOrg ] = useState();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!orgPath || !orgList) return;
        const org = Object.values(orgList).filter(o => o.path === orgPath)[0];
        setCurrentOrg({...org});
    }, [orgPath, orgList])

    return (
        <div>
            {/* <BackButton cancel={() => navigate(-1)} /> */}
            <div className='g-space-1'></div>
            {currentOrg && 
                <OrgSettings orgData={currentOrg} />
            }
        </div>
    )
}

export default OrgView