import React, { useEffect, useState } from 'react'
import './managing-list.styles.scss'

import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { format } from 'date-fns';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import { properCase } from '@/utils/properCase';

const ManagingList = ({ managingList }) => {

    const membership = useSelector(state => state.userData.membership)
    const [ listToDisplay, setListToDisplay ] = useState(null);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(false);
    const [ upgradeMessage, setUpgradeMessage ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1500)
    }, [])

    useEffect(() => {
        if (!managingList || !membership || !membershipRestrictions) return;
        let displayArr = [];
        let index = 1;
        Object.values(managingList)
        .sort((a,b) => a.lastActivity < b.lastActivity ? 1 : -1)
        .map(game => {
            const newGame = testMemberRestrictions({'gameData': game, 'membership': membership, 'gameIndex': index});
            if (!newGame.disabled) {
                index++;
            }
            if (newGame.playerCount && newGame.createdTeams) {
                newGame.playersOnlyCount = newGame.playerCount - newGame.createdTeams;
            }
            displayArr.push(newGame);
        })
        // console.log(displayArr);
        setListToDisplay(displayArr);
    }, [managingList, membership, membershipRestrictions])

    function promptUpgrade(g) {
        console.log(g);
        const planRest = membershipRestrictions[membership.membership];
        let message = '';
        if (g.disabledReason.includes('games')) {
            message += `<p>While using the ${planRest.title}, you are only able to have ${planRest.gameCount} active Games.</p>`
        }
        if (g.disabledReason.includes('players')) {
            message += `<p>While using the ${planRest.title}, you are only able to have ${planRest.playerCount} active Players per game.</p>`
        }
        if (g.disabledReason.includes('elements')) {
            message += `<p>While using the ${planRest.title}, you are only able to have ${planRest.elementCount} active Game Elements per game.</p>`
        }
        setUpgradeMessage(message);
        setShowUpgradeModal(true);

    }

    return (
        <div className='managing-list'>
            {
                (loading)
                ?
                <div className='center'><ThreeDotsLoader /></div>
                :
                (listToDisplay && listToDisplay.length > 0)
                ?
                listToDisplay
                .map(g => 
                    g.disabled
                    ?
                    (
                        <button 
                            key={g.gameId} 
                            className={`g-button game-card`} 
                            onClick={() => promptUpgrade(g)}
                        >
                            <ImageContainer src={g.icon} className='game-icon disabled'/>
                            <div className='game-name disabled'>
                                {g.gameName}<br />  
                                <span className='meta'>Role: {properCase(g.role)}</span>
                            </div>
                            <div>Upgrade to access</div>
                        </button>
                    )
                    :
                    (
                    <button 
                        key={g.gameId} 
                        className={`g-button game-card`} onClick={() => navigate(`/manage/${g.path}`)}>
                        <ImageContainer src={g.icon} className='game-icon'/>
                        <div className='game-name'>
                            {g.gameName}<br />
                            <span className='meta'>Role: {properCase(g.role)}</span>
                        </div>
                        <div className='meta'><b>Last Activity:</b><br />{format(new Date(g.ts), 'P')}</div>
                        <div className='meta'><b>Join Code:</b><br />{g.joinCode}</div>
                        <div className='meta'><b>Player Count: </b>{g.playersOnlyCount ?? g.playerCount ?? '0'}</div>
                    </button>
                    )
                )
                :
                <div className='center-col'>
                    <h3>No games yet...</h3>
                </div>
            }
            <UpgradeRequiredModal show={showUpgradeModal} cancel={() => setShowUpgradeModal(false)} closeButton={true} message={upgradeMessage} />
        </div>
    )
}

export default ManagingList