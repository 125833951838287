import React from 'react';
import './data-card.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DataCard = ({
    icon,
    data,
    text,
    onclick,
    role
}) => {
    return (
        <>
            {
                (onclick && (role === 'owner' || role === 'manager')) 
                ?
                <button 
                    className='g-button g-card'
                    onClick={onclick}
                >
                    <div className='data-card'>
                        <div className='icon-circle'>
                            <FontAwesomeIcon icon={icon} size='xl' />
                        </div>
                        <div className='data-div'>
                            <div className='data-number'>
                                {data}
                            </div>
                            <div className='data-text'>
                                {text}
                            </div>
                        </div>
                    </div>
                </button>
                :
                <div className='g-card'>
                    <div 
                        className='data-card'
                    >
                        <div className='icon-circle'>
                            <FontAwesomeIcon icon={icon} size='xl' />
                        </div>
                        <div className='data-div'>
                            <div className='data-number'>
                                {data}
                            </div>
                            <div className='data-text'>
                                {text}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default DataCard