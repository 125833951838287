import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import './profile-icon.styles.scss'
import placeholder_icon from '@/assets/placeholder-icon.png'

import "croppie/croppie.css"
import Croppie from "croppie"

import UIkit from 'uikit'
import HostedIconModal from '@/components/MANAGE-GAME/create-elements/blocks/hosted-icon-modal.component'
import ImageContainer from '@/components/SHARED/image-container/image-container.component'
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRecycle, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { getRandomLinearGradient } from '@/utils/randomGradient'

const ProfileIcon = ({ formData, saveData, ofAge }) => {
	
	const provider = useSelector(state => state.userData.currentUser.provider)
	const photoURL = useSelector(state => state.userData.currentUser.photoURL)
	const [ showHostedIconModal, setShowHostedIconModal ] = useState(false);
	const [ showIconModal, setShowIconModal ] = useState(false) 
	const [ croppedImage, setCroppedImage ] = useState('')
	const [ croppie, setCroppie ] = useState(null)
    let croppieInstance

	function hideModal() {
		setShowIconModal(false)
	}
	
	const chooseGoogleIcon = async () => {
		setCroppedImage('')
		document.getElementById('selected-image').classList.remove('hide')
        document.getElementById('uploaded-image').classList.add('hide')
		destroyCroppie()
		saveData('icon', {upload: false, url: photoURL})
		console.log('choose Google Icon')
	}

	const destroyCroppie = () => {
		const el = document.getElementById("uploaded-image")
		el.innerHTML = ''
		el.classList.remove("croppie-container");
	}

    function clickUpload() {
        document.getElementById('fileUpload').click();
    }

    function chooseHostedIcon(url) {
        saveData('icon', url);
        destroyCroppie();
        document.getElementById('selected-image').classList.remove('hide')
        document.getElementById('uploaded-image').classList.add('hide')
    }

    function chooseGradient() {
        saveData('icon', null);
        destroyCroppie();
        document.getElementById('selected-image').classList.remove('hide')
        document.getElementById('uploaded-image').classList.add('hide')
    }

    function getNewGradient() {
        const icon = document.getElementById('new-gradient-icon');
        icon.classList.add('spinning-icon');
        setTimeout(() => {
            saveData('gradient', getRandomLinearGradient())
            icon.classList.remove('spinning-icon');
        }, 500);
    }

	function handleUpload(file) {
		document.getElementById('selected-image').classList.add('hide')
        document.getElementById('uploaded-image').classList.remove('hide')
		destroyCroppie()
		console.log(file.type)
		if (file.type.match(/image.*/)) {
			const fileType = file.type;
			const fileName = file.name;
			console.log('An image has been loaded');
			console.log(fileType)
			// Load the image
			if (fileType.indexOf('gif') == -1) {
				const newFile = window.URL.createObjectURL(file)
				// setCroppedImage(newFile)
				const el = document.getElementById("uploaded-image")
				if (el) {
					croppieInstance = new Croppie(el, {
						viewport: { width: 150, height: 150 },
						boundary: { width: 150, height: 150 },
						showZoomer: true,
						enableOrientation: true
					});
					croppieInstance.bind({
						url: newFile,
					});
					setCroppie(croppieInstance)
				}
				setCroppedImage(newFile)
				saveData('icon', {url: newFile, upload: true, croppie: croppieInstance, type: fileType, name: fileName})
				return;
			}
			document.getElementById('selected-image').classList.remove('hide');
            document.getElementById('uploaded-image').classList.add('hide');
			const newFile = window.URL.createObjectURL(file)
			saveData('icon', {upload: true, url: newFile, type: fileType, name: fileName, file: file})
			return;
		}
		UIkit.modal.alert('Please upload an image file.')
		return;
	}

	return (
		<div className='profile-icon-container'>
            <div className='field'>
                <label><b>User icon</b></label>
            </div>
            <div className="g-space-1"></div>
			<div className="g-grid">
                <div className="g-half-col">
					<div className="image-buttons">
						{
                            (provider == 'google.com') 
                            ? <button className="g-button" type="button" onClick={() => chooseGoogleIcon()}>Use Your Google Icon</button>
                            : ''
                        }					
                        {
                            (ofAge) &&
                            <div style={{cursor: 'pointer'}}>
                                <input type="file" id="fileUpload" onChange={(e) => handleUpload(e.target.files[0])}  />
                                <button className="g-button" type="button" tabIndex="-1" onClick={() => clickUpload()}>Upload an Icon</button>
                            </div>
                        }
                        <div className='g-button' type='button' onClick={() => setShowHostedIconModal(true)}>Choose an Icon</div>
                        <div className='g-button' type='button' onClick={() => chooseGradient()}>Use Gradient</div>
					</div>
				</div>
				
				<div className="g-half-col">
					<div className="image-div">
						<div id="uploadDiv">
							<div id="selected-image">
								{ 
									(formData.icon) 
										? 
                                        <ImageContainer className="profile-image" referrerPolicy="no-referrer" src={formData.icon.url || formData.icon} />
										: 
                                        <>
                                        <div className='gradient' style={{'background': formData.gradient}}>
                                            {formData.fName ? formData.fName[0].toUpperCase() : ''}
                                        </div>
                                        <div className="g-space-0-5"></div>
                                        <div className='center-col'>
                                            <button className='g-button med-btn' title='New Gradient' onClick={() => getNewGradient()}>
                                                <FontAwesomeIcon icon={faRefresh} id='new-gradient-icon' />
                                            </button>
                                        </div>
                                        </>
								}
                                {/* <UserIcon profile={formData} xlarge={true} /> */}
                                
							</div>
							<div id="uploaded-image" className='hide'>
							
							</div>
							<a className="rotateButton" id="rotateButton"><i className="large redo icon"></i></a>
						</div>
					</div>
				</div>
			</div>
            <HostedIconModal show={showHostedIconModal} cancel={() => setShowHostedIconModal(false)} selectIcon={chooseHostedIcon} type="profile" />
		</div>
	)
}

export default ProfileIcon
								