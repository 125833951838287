export function sortPlayers(e) {
    let playerList = Object.values(e.playerList);
    if (e.filter === 'players') {
        playerList = Object.values(e.playerList).filter(p => !p.team);
    } else if (e.filter === 'teams') {
        playerList = Object.values(e.playerList).filter(p => p.team);
    }
    // console.log(playerList);
    if (!e.sortBy || e.sortBy === 'name') {
        return playerList.sort((a, b) => {
            if (a.lName && b.lName && a.lName.toLowerCase() > b.lName.toLowerCase()) {
                return 1;
            } else if (a.lName && b.lName && a.lName.toLowerCase() === b.lName.toLowerCase() && a.fName.toLowerCase() > b.fName.toLowerCase()) {
                return 1;
            } else if (a.lName && !b.lName && a.lName.toLowerCase() > b.fName.toLowerCase()) {
                return 1;
            } else if (!a.lName && b.lName && a.fName.toLowerCase() > b.lName.toLowerCase()) {
                return 1;
            } else if (!a.lName && !b.lName && a.fName.toLowerCase() > b.fName.toLowerCase()) {
                return 1;
            } else {
                return -1;
            }
        })
    }

    else if (e.sortBy === 'alias') {
        return playerList.sort((a,b) => {
            if (a.alias && b.alias && a.alias.toLowerCase() > b.alias.toLowerCase()) {
                return 1;
            } else if (!a.alias && b.alias) {
                return 1;
            } else {
                return -1;
            }
        });
    }

    else if (e.sortBy === 'points') {
        return playerList.sort((a,b) => {
            if (a.points && b.points && a.points < b.points) {
                return 1;
            } else if (!a.points && b.points) {
                return 1;
            } else {
                return -1;
            }
        })
    }

    else if (e.sortBy === 'currencyTotal') {
        return playerList.sort((a,b) => {
            if (a.currencyTotal && b.currencyTotal && a.currencyTotal < b.currencyTotal) {
                return 1;
            } else if (!a.currencyTotal && b.currencyTotal) {
                return 1;
            } else {
                return -1;
            }
        });
    }

    else if (e.sortBy === 'currency') {
        return playerList.sort((a,b) => {
            if (a.currency && b.currency && a.currency < b.currency) {
                return 1;
            } else if (!a.currency && b.currency) {
                return 1;
            } else {
                return -1;
            }
        });
    }
    
    else if (e.sortBy === 'badges') {
        return playerList.sort((a,b) => {
            if (a.badges && b.badges && Object.values(a.badges).length < Object.values(b.badges).length) {
                return 1;
            } else if (!a.badges && b.badges) {
                return 1;
            } else {
                return -1;
            }
        })
    }

    else if (e.sortBy === 'items') {
        return playerList.sort((a,b) => {
            if (a.items && b.items) {
                const aCount = Object.values(a.items).reduce((acc, cur) => acc + cur.count, 0);
                const bCount = Object.values(b.items).reduce((acc, cur) => acc + cur.count, 0);
                if (aCount < bCount) {
                    return 1;
                } else {
                    return -1;
                }
            } else if (b.items) {
                return 1;
            } else {
                return -1;
            }
        })
    }

    else if (e.sortBy === 'codes') {
        return playerList.sort((a,b) => {
            if (a.codes && b.codes && a.codes.length > b.codes.length) {
                return 1;
            } else if (!a.codes && b.codes) {
                return 1;
            } else {
                return -1;
            }
        })
    }

    else if (e.sortBy === 'playerInfo') {
        return playerList.sort((a,b) => {
            if (a.info && b.info && a.info.toLowerCase() > b.info.toLowerCase()) {
                return 1;
            } else if (!a.info && b.info) {
                return 1;
            } else {
                return -1;
            }
        });
    }

    else if (e.sortBy === 'challenges') {
        return playerList.sort((a,b) => {
            if (a.challenges && b.challenges) {
                const aCount = Object.values(a.challenges).reduce((acc, cur) => acc + cur.count, 0);
                const bCount = Object.values(b.challenges).reduce((acc, cur) => acc + cur.count, 0);
                if (aCount < bCount) {
                    return 1;
                } else {
                    return -1;
                }
            } else if (b.chalenges) {
                return 1;
            } else {
                return -1;
            }
        });
    }
}