import React, { useEffect, useRef, useState } from 'react'
import './action-items.styles.scss';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBell, faFilter } from '@fortawesome/free-solid-svg-icons';
import CategoryFilterModal from '../create-elements/components/category-filter-modal.component';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import ViewActionItem from './view-action-item.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const ActionItems = ({ 
    gameData, 
    gameElements, 
    playerList, 
    actionItems,
    role 
}) => {

    const [ itemsToDisplay, setItemsToDisplay ] = useState(null);
    const [ pendingCount, setPendingCount ] = useState(0);
    const [ playerFilter, setPlayerFilter ] = useState([]);
    const [ selectedPlayers, setSelectedPlayers ] = useState([]);
    const [ playerInfoFilter, setPlayerInfoFilter ] = useState([]);
    const [ selectedPlayerInfo, setSelectedPlayerInfo ] = useState([]);
    const [ elementFilter, setElementFilter ] = useState([]);
    const [ selectedElements, setSelectedElements ] = useState([]);
    const [ filterType, setFilterType ] = useState(null);
    const [ view, setView ] = useState('pending');
    const availableRef = useRef([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!role) return;
        if (role !== 'owner' && role !== 'manager') {
            navigate(-1);
        }
    }, [role])
    
    useEffect(() => {
        if (!actionItems || !gameElements || !playerList || !view) return;
        let arr = [];
        let playerObj = {};
        let playerInfoArr = [];
        let elementObj = {};
        for (let i of Object.values(actionItems)
            .filter(i => 
                view === 'complete' ? 
                (
                    i.status === 'approved' ||
                    i.status === 'declined'
                ) :
                i.status === 'pending'
                &&
                i.type !== 'realworld'
            )
            .sort((a,b) => a.ts > b.ts ? 1 : -1)) 
        {
            if (!gameElements[i.elementId] || !playerList[i.playerId]) {
                continue;
            }
            const obj = {
                'fName': playerList[i.playerId].fName,
                'lName': playerList[i.playerId].lName,
                'info': playerList[i.playerId].info,
                'playerId': i.playerId,
                'icon': gameElements[i.elementId].icon,
                'elementId': i.elementId,
                'element': gameElements[i.elementId].name,
                'ts': i.ts,
                'id': i.id,
                'path': i.path,
                'type': i.type,
            }
            arr.push(obj);
            const player = {
                'name': obj.lName ? `${obj.lName}, ${obj.fName}` : obj.fName,
                'playerId': i.playerId
            }
            if (!playerObj[i.playerId]) playerObj[i.playerId] = player;
            if (!playerInfoArr.includes(obj.info)) playerInfoArr.push(obj.info);
            const element = {
                'name': obj.element,
                'id': i.elementId
            }
            if (!elementObj[i.elementId]) elementObj[i.elementId] = element;
        }
        availableRef.current = [...arr];
        setItemsToDisplay(availableRef.current);
        setPlayerFilter(Object.values(playerObj));
        setPlayerInfoFilter([...playerInfoArr]);
        setElementFilter(Object.values(elementObj));
    }, [actionItems, gameElements, playerList, view])
    
    useEffect(() => {
        if (!actionItems) return;
        setPendingCount(Object.values(actionItems).filter(i => 
            i.status === 'pending' &&
            i.type !== 'realworld'
        ).length)
    }, [actionItems])

    useEffect(() => {
        filterData();
    }, [selectedPlayers, selectedPlayerInfo, selectedElements])

    function cancel() {
        navigate(`/manage/${gameData.path}`);
    }

    function switchView() {
        if (view === 'pending') {
            setView('complete');
        } else {
            setView('pending');
        }
    }

    function selectFilter(id) {
        if (filterType === 'player') {
            let tempPlayers = [...selectedPlayers];
            if (tempPlayers.includes(id)) {
                tempPlayers.splice(tempPlayers.indexOf(id), 1);
            } else {
                tempPlayers.push(id);
            }
            setSelectedPlayers([...tempPlayers])
        } else if (filterType === 'playerInfo') {
            let tempPlayerInfo = [...selectedPlayerInfo];
            if (tempPlayerInfo.includes(id)) {
                tempPlayerInfo.splice(tempPlayerInfo.indexOf(id), 1);
            } else {
                tempPlayerInfo.push(id);
            }
            setSelectedPlayerInfo([...tempPlayerInfo]);
        } else if (filterType === 'element') {
            let tempElements = [...selectedElements];
            if (tempElements.includes(id)) {
                tempElements.splice(tempElements.indexOf(id), 1);
            } else {
                tempElements.push(id)
            }
            setSelectedElements([...tempElements]);
        }
    }

    function filterData() {
        const filteredItems = availableRef.current.filter(a => {
            let meets = true;
            if (
                selectedPlayers.length > 0 && 
                !selectedPlayers.includes(a.playerId)
            ) {
                meets = false;
            }
            if (
                selectedPlayerInfo.length > 0 && 
                !selectedPlayerInfo.includes(a.info)
            ) {
                meets = false;
            }
            if (
                selectedElements.length > 0 && 
                !selectedElements.includes(a.elementId)
            ) {
                meets = false;
            }
            return meets;
        })
        setItemsToDisplay([...filteredItems])
    }

    return (
        <div className='action-items-container'>
            <Routes>
                <Route path='' element={
                    <div className='action-items-content'>
                        <div className='g-card'>
                            <BackButton cancel={cancel} />
                            <div className="g-space-1"></div>
                            <div className='card-title'><FontAwesomeIcon icon={faBell} size='xl' />Action Items</div>
                            <p>Pending Items: {pendingCount}</p>
                            <div className='action-item-list-search'>
                                {/* <SearchBar search={searchItems} /> */}
                                <button type='button' className='g-button med-btn' onClick={() => setFilterType('player')}>
                                    Filter by Player
                                    <FontAwesomeIcon 
                                        icon={faFilter}
                                        className={selectedPlayers.length > 0 ? 'full' : 'empty'} 
                                    />
                                </button>
                                <button type='button' className='g-button med-btn' onClick={() => setFilterType('playerInfo')}>
                                    Filter by Player Info
                                    <FontAwesomeIcon 
                                        icon={faFilter}
                                        className={selectedPlayerInfo.length > 0 ? 'full' : 'empty'} 
                                    />
                                </button>
                                <button type='button' className='g-button med-btn' onClick={() => setFilterType('element')}>
                                    Filter by Element
                                    <FontAwesomeIcon 
                                        icon={faFilter}
                                        className={selectedElements.length > 0 ? 'full' : 'empty'} 
                                    />
                                </button>
                                <button type='button' className='g-button med-btn' onClick={() => switchView()}>
                                    Switch To {view === 'pending' ? 'Complete' : 'Pending'} Items
                                </button>
                            </div>
                            <div className="g-space-2"></div>
                            <div className='action-item-list'>
                                <div className='g-list-item action-item-list-head'>
                                    <div></div>
                                    <div>Name</div>
                                    <div>Action</div>
                                    <div>Element</div>
                                    <div>Submitted</div>
                                    <div></div>
                                </div>
                                {
                                    (itemsToDisplay) &&
                                    itemsToDisplay.sort((a,b) => a.ts > b.ts ? 1 : -1)
                                    .map(i => (
                                        <div key={i.id} className='action-item g-list-item'>
                                            <div className='action-item-icon'>
                                                <ImageContainer src={i.icon} alt={`${i.element} icon`} />
                                            </div>
                                            <div className='action-item-username'>
                                                {i.fName} {i.lName}
                                            </div>
                                            <div>
                                                {
                                                    i.type === 'challenge' ?
                                                    'Challenge Completed' :
                                                    i.type === 'item' ?
                                                    'Item Used' :
                                                    ''
                                                }
                                            </div>
                                            <div>
                                                {i.element}
                                            </div>
                                            <div>
                                                {format(new Date(i.ts), 'Pp')}
                                            </div>
                                            <div className='buttons'> 
                                                {
                                                    (view === 'pending') &&
                                                    <button 
                                                        className='g-button small-btn' 
                                                        onClick={() => navigate(i.path)}
                                                    >
                                                        <FontAwesomeIcon 
                                                            icon={faArrowRight} 
                                                            size='xl' 
                                                        />
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    ))                        
                                }
                            </div>
                        </div>
                        <CategoryFilterModal 
                            show={filterType}
                            cancel={() => setFilterType(null)}
                            chooseCategory={selectFilter}
                            categoryList={
                                filterType === 'player' ? playerFilter :
                                filterType === 'playerInfo' ? playerInfoFilter :
                                filterType === 'element' ? elementFilter : null
                            }
                            selectedCategories={
                                filterType === 'player' ? selectedPlayers : 
                                filterType === 'playerInfo' ? selectedPlayerInfo : 
                                filterType === 'element' ? selectedElements : null
                            }
                            text = {
                                filterType === 'player' ? 'Select a Player' :
                                filterType === 'playerInfo' ? 'Select Player Info' :
                                filterType === 'element' ? 'Select an Element' : null
                            }
                        />
                    </div>
                }></Route>
                <Route 
                    path=':itemPath' 
                    element={
                        <ViewActionItem 
                            gameData={gameData} 
                            gameElements={gameElements} 
                            playerList={playerList}
                            actionItems={actionItems} 
                        />
                    }
                ></Route>
                <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
            
        </div>
    )
}

export default ActionItems