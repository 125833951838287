import React, { useEffect, useRef, useState } from 'react'
import './player-home-content.styles.scss'
import { useSelector } from 'react-redux'
import PlayerStatusTable from './components/status-table.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGem, faRankingStar } from '@fortawesome/free-solid-svg-icons'
import gem from '@/assets/icons/xp.png'
import coin from '@/assets/icons/coin.png'
import { switchPlayerMenu } from '../side-menu.component'
import NewRewardModal from './modals/new-reward-modal.component'
import DeclinedChallengeModal from './modals/declined-challenge-modal.component'
import { prereqFilter } from '@/utils/prereqFilter'
import ImageContainer from '@/components/SHARED/image-container/image-container.component'
import { stripHtml } from '@/utils/sanitize'
import { properCase } from '@/utils/properCase'
import { useNavigate } from 'react-router-dom'
import Modal from '@/components/modal/modal.component'
import linkifyHtml from 'linkify-html'
import { format } from 'date-fns'

const PlayerHomeContent = ({ 
    gameData, 
    playerData, 
    gameElements, 
    playerActivity, 
    playerList,
    latestMessage 
}) => {

    const [ currentRank, setCurrentRank ] = useState(null); 
    const [ statusHeight, setStatusHeight ] = useState(0);
    const [ featuredElement, setFeaturedElement ] = useState(null);
    const [ showGameInfo, setShowGameInfo ] = useState(false);
    const [ messageHeight, setMessageHeight ] = useState(100);
    const timeoutRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const box = document.querySelector('.card5');
        if (box) {
            const height = box.offsetHeight;
            setStatusHeight(height - 75);
        }
        // findMessageHeight();
        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        }
    }, [])

    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            findMessageHeight();
        }, 250);
    }, [featuredElement])

    useEffect(() => {
        if (!gameData || !playerData || !gameElements) return;
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
            pageTitle.innerText = 'Game Home'
        }
        switchPlayerMenu('home-btn');
        const availableElements = Object.values(gameElements).filter(e => prereqFilter(e, playerData, gameElements)).filter(e => e.type !== 'secretCode');
        // console.log(availableElements);
        if (gameData.featuredElement && availableElements.filter(e => e.id === gameData.featuredElement).length > 0) {
            setFeaturedElement(gameElements[gameData.featuredElement]);
        } else {
            
            const num = Math.floor(Math.random() * Object.values(gameElements).filter(e => prereqFilter(e, playerData, gameElements)).length);
            // console.log(num);
            let elem = availableElements[num];
            setFeaturedElement(elem);
        }
    }, [gameData, playerData, gameElements])

    useEffect(() => {
        if (!gameData || !playerList || !playerData) return;
        const sort = gameData.sortLeaders;
        let sortedPlayers = Object.values(playerList)
        .filter(p => !p.team)
        .sort((a,b) => a[sort] < b[sort] ? 1 : -1)
        .map(p => p.playerId);
        setCurrentRank(sortedPlayers.includes(playerData.playerId) ? sortedPlayers.indexOf(playerData.playerId) + 1 : 0);
    }, [gameData, playerList, playerData])

    function findMessageHeight() {
        const cardHeight = document.getElementById('featured-card').offsetHeight;
        const featuredDiv = document.getElementById('featured-item');
        const featuredHeight = featuredDiv ? featuredDiv.offsetHeight : 400;
        // console.log(featuredHeight);
        setMessageHeight(cardHeight - featuredHeight - 106); 
    }

    return (
        <div className='player-home-container'>
            <div className='player-home-content'>
                <div className='g-card card1 game-title'>
                    <img src={gameData.icon} className='icon' />
                    <div className='title-box'>
                        <div className='welcome'>Welcome to</div>
                        <div className='title'>{gameData.gameName}</div>
                    </div>
                    <div className='button-box'>
                        <button className='g-button small-btn' onClick={() => setShowGameInfo(true)}>Game Info</button>
                    </div>
                </div>
                {
                    (!gameData.sortLeaders || gameData.sortLeaders === 'points')
                    ?
                    <div className='g-card card2 data-box'>
                        <div className='icon-circle'>
                            <img src={gem} />
                        </div>
                        <div className='data-div'>
                            <div className='data-number'>
                                {playerData.points ? playerData.points.toLocaleString() : '0'}
                            </div>
                            <div className='data-text'>
                                My XP
                            </div>
                        </div>
                    </div>
                    :
                    (gameData.sortLeaders === 'currency')
                    ?
                    <div className='g-card card2 data-box'>
                        <div className='icon-circle'>
                            <img src={coin} />
                        </div>
                        <div className='data-div'>
                            <div className='data-number'>
                                {playerData.currency ? playerData.currency.toLocaleString() : 0}
                            </div>
                            <div className='data-text'>
                                My Available Currency
                            </div>
                        </div>
                    </div>
                    :
                    <div className='g-card card2 data-box'>

                    </div>
                }
                
                <div className='g-card card3 data-box'>
                    <div className='icon-circle'>
                        <FontAwesomeIcon icon={faRankingStar} size='xl' />
                    </div>
                    <div className='data-div'>
                        <div className='data-number'>
                            {currentRank ?? 0}
                        </div>
                        <div className='data-text'>
                            My Current Rank
                        </div>
                    </div>
                </div>

                <div className='g-card card4' id='featured-card'>
                    {
                        (featuredElement) &&
                        <div id="featured-item">
                            <div className='card-title'>Featured</div>
                            <div className="g-space-1"></div>
                            <div 
                                className={`featured-element g-list-item ${featuredElement.type === 'challenge' ? 'clickable with-shadow' : ''}`}
                                onClick={featuredElement.type === 'challenge' ? () => navigate(`challenges/${featuredElement.path}`) : null} 
                            >
                                <ImageContainer src={featuredElement.icon} alt={`${featuredElement.name} ${featuredElement.type} icon`} className='featured-element-icon' />
                                <div className='featured-element-info'>
                                    <div className='title'>
                                        {featuredElement.name}
                                    </div>
                                    <div className='meta'>{properCase(featuredElement.type)}</div> 
                                    <div className='desc'>{featuredElement.desc ? stripHtml(featuredElement.desc).length > 90 ? `${stripHtml(featuredElement.desc).substring(0,90)}...` : stripHtml(featuredElement.desc) : ''}</div>
                                </div>
                            </div>
                            <div className='g-space-2'></div>
                        </div>
                    }    
                    {
                        (latestMessage) &&
                        <div className='message-container'>
                            <div className='card-title'>Latest News</div>
                            <div className="g-space-1"></div>
                            <div className='g-list-item message-box' style={{'height': messageHeight + 'px'}}>
                                {
                                    (latestMessage.title) &&
                                    <div className='headline'>{latestMessage.title}</div>
                                }
                                <div className='meta'>Posted {format(new Date(latestMessage.ts), "Pp")}</div>
                                <div dangerouslySetInnerHTML={{__html: linkifyHtml(latestMessage.message, {'target': '_blank'})}} ></div>
                            </div>
                        </div>
                    }
                </div>
                <div className='g-card card5'>
                    <div className='card-title'>My Status</div>
                    <div className="g-space-1"></div>
                    <div className='status-box' style={{'height': statusHeight}}>
                        <PlayerStatusTable gameData={gameData} playerData={playerData} gameElements={gameElements} playerActivity={playerActivity} />
                    </div>
                </div>
            </div>

            <Modal show={showGameInfo} cancel={() => setShowGameInfo(false)} closeButton={true} cls='narrow' text='Game Information' >
                <div className='modal-children'>
                    {
                        (gameData.desc) &&
                        <div dangerouslySetInnerHTML={{__html: linkifyHtml(gameData.desc)}}></div>
                    }
                </div>
                <div className='buttons'>
                    <div className='g-button' onClick={() => setShowGameInfo(false)}>Ok</div>
                </div>
            </Modal>
            
        </div>
    )
}

export default PlayerHomeContent