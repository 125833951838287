import React, { useEffect, useRef, useState } from 'react'
import './player-selector.styles.scss';
import SearchBar from '../search-bar/search-bar.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faFilter } from '@fortawesome/free-solid-svg-icons';
import CategoryFilterModal from '@/components/MANAGE-GAME/create-elements/components/category-filter-modal.component';
import { useLocation } from 'react-router-dom';
import ImageContainer from '../image-container/image-container.component';
import { sortPlayers } from '@/utils/sortPlayers';

const PlayerSelector = ({ 
    playerList, 
    teamMembers,
    chosenPlayers, 
    togglePlayer, 
    type 
}) => {

    const [ playersToDisplay, setPlayersToDisplay ] = useState([]);
    const [ teams, setTeams ] = useState([]);
    const [ showFilterModal, setShowFilterModal ] = useState(false);
    const [ showInfoFilterModal, setShowInfoFilterModal ] = useState(false);
    const [ chosenTeams, setChosenTeams ] = useState([]);
    const [ infoList, setInfoList ] = useState([]);
    const [ chosenInfo, setChosenInfo ] = useState([]);
    const playerRef = useRef();
    const chosenTeamsRef = useRef();
    const chosenInfoRef = useRef();
    const teamsRef = useRef();
    const location = useLocation();

    useEffect(() => {
        if (!playerList || !type) return;
        playerRef.current = Object.values(playerList)
        // if (type === 'teams') {
        //     setPlayersToDisplay(playerRef.current)
        // } else {
        //     setPlayersToDisplay(playerRef.current.filter(p => !p.team));
        // }
        const sortedPlayers = sortPlayers({
            'playerList': playerRef.current,
            'filter': type
        })
        setPlayersToDisplay([...sortedPlayers]);
        let teamArr = [];
        const tempTeamArr = Object.values(playerList)
            .filter(p => p.team)
            .sort((a,b) => a.fName.toLowerCase() > b.fName.toLowerCase() ? 1 : -1)
        for (let t of tempTeamArr) {
            if (teamMembers && teamMembers[t.playerId]) {
                t.opt_members = teamMembers[t.playerId].members;
                teamArr.push(t);
            }
        }
        setTeams([...teamArr])
        const infoArr = [...new Set(
            Object.values(playerList)
            .filter(p => !p.team && p.info)
            .map(p => p.info)
        )]
        setInfoList(infoArr.sort());
    }, [playerList, type, teamMembers])

    useEffect(() => {
        // console.log(chosenTeams);
        if (!type) return;
        if (chosenTeams.length === 0 && playerRef.current) {
            const sortedPlayers = sortPlayers({
                'playerList': playerRef.current,
                'filter': type
            })
            setPlayersToDisplay([...sortedPlayers]);
            return;
        }
        let filteredList = []
        for (let teamId of chosenTeams) {
            const team = playerList[teamId];
            if (!team.opt_members) continue;
            for (let playerId of team.opt_members) {
                if (!playerList[playerId]) continue;
                filteredList.push(playerList[playerId])
            }
        }
        const sortedFilteredPlayers = sortPlayers({
            'playerList': filteredList,
            'filter': type
        })
        setPlayersToDisplay([...sortedFilteredPlayers])
    }, [chosenTeams, type])
 
    useEffect(() => {
        if (!playerList) return;
        if (chosenInfo.length === 0 && playerRef.current) {
            const sortedPlayers = sortPlayers({
                'playerList': playerRef.current,
                'filter': type
            })
            setPlayersToDisplay([...sortedPlayers]);
            return;
        }
        let filteredList = Object.values(playerList)
        .filter(p => chosenInfo.includes(p.info))
        let sortedFilteredList = sortPlayers({
            'playerList': filteredList
        })
        setPlayersToDisplay([...sortedFilteredList])
    }, [chosenInfo, playerList])

    function selectAll() {
        for (let p of playersToDisplay) {
            togglePlayer(p, 'select')
        }
    }
 
    function deselectAll() {
        for (let p of playersToDisplay) {
            togglePlayer(p, 'deselect')
        }
    }

    function search(text) {
        if (!text) {
            const sortedPlayers = sortPlayers({
                'playerList': playerRef.current,
                'filter': type
            })
            setPlayersToDisplay([...sortedPlayers]);
            return;
        }
        const list = Object.values(playerList)
        .filter(t => (
            t.fName.toLowerCase().includes(text.toLowerCase()) || 
            (t.lName && t.lName.toLowerCase().includes(text.toLowerCase())) || 
            t.displayName.toLowerCase().includes(text.toLowerCase())
        ))
        const sortedSearchedPlayers = sortPlayers({
            'playerList': list,
            'filter': type
        })
        setPlayersToDisplay([...sortedSearchedPlayers]);
    }

    function chooseTeam(id) {
        if (!chosenTeamsRef.current) chosenTeamsRef.current = [];
        const tempTeams = [...chosenTeamsRef.current];
        if (tempTeams.includes(id)) {
            tempTeams.splice(tempTeams.indexOf(id), 1)
        } else {
            tempTeams.push(id);
        }
        chosenTeamsRef.current = [...tempTeams];
        setChosenTeams(chosenTeamsRef.current);

    }

    function chooseInfo(id) {
        if (!chosenInfoRef.current) chosenInfoRef.current = [];
        const tempInfo = [...chosenInfoRef.current];
        if (tempInfo.includes(id)) {
            tempInfo.splice(tempInfo.indexOf(id), 1)
        } else {
            tempInfo.push(id);
        }
        chosenInfoRef.current = [...tempInfo];
        setChosenInfo(chosenInfoRef.current);
    }

    return (
        <div className='player-selector'>
            <div className='search-row'>
                <SearchBar search={search} />
                {
                    (!location.pathname.includes('/teams/') && type !== 'teams') &&
                    <button className='g-button med-btn filter-button' onClick={() => setShowFilterModal(true)}>
                        <FontAwesomeIcon icon={faFilter} className={chosenTeams.length > 0 ? 'full' : 'empty'} />
                        Team
                    </button>
                }
                {
                    (type !== 'teams') &&
                    <button className='g-button med-btn filter-button' onClick={() => setShowInfoFilterModal(true)}>
                        <FontAwesomeIcon icon={faFilter} className={chosenInfo.length > 0 ? 'full' : 'empty'} />
                        Info
                    </button>
                }
                <button className='g-button med-btn' onClick={() => selectAll()}>
                    Select All
                </button>
                <button className='g-button med-btn' title='Clear Selected Players' onClick={() => deselectAll()}>
                    <FontAwesomeIcon icon={faBan} size='lg' />
                </button>
            </div>
            <div className="g-space-0-5"></div>
            <div className='g-list-item multiple-select player-selector-box'>
                {
                    (playersToDisplay.length > 0) &&
                    playersToDisplay
                    // .sort((a, b) => {
                    //     if ((a.lName && b.lName) && a.lName.toLowerCase() > b.lName.toLowerCase()) {
                    //         return 1;
                    //     } else if ((a.fName && b.lName) && a.fName.toLowerCase() > b.lName.toLowerCase()) {
                    //         return 1;
                    //     } else if ((a.lName && b.fName) && a.lName.toLowerCase() > b.fName.toLowerCase()) {
                    //         return 1;
                    //     } else if ((a.fName && b.fName) && a.fName.toLowerCase() > b.fName.toLowerCase()) {
                    //         return 1;
                    //     } else if ((a.lName && b.lName) && a.lName.toLowerCase() === b.lName.toLowerCase() && a.fName.toLowerCase() > b.fName.toLowerCase()) {
                    //         return 1;
                    //     } else {
                    //         return -1;
                    //     }
                    // })
                    .map(p => (
                        <div 
                            key={p.playerId} 
                            className={`multiple-select-option ${chosenPlayers.includes(p.playerId) ? 'active' : ''}`}
                            onClick={() => togglePlayer(p)}     
                        >
                            {
                                (type === 'teams')
                                ?
                                <ImageContainer src={p.icon}  />
                                :
                                (p.icon)
                                ?
                                <ImageContainer src={p.icon} />
                                :
                                <div className='gradient' style={{'background': p.gradient}}>{p.fName[0]}</div>
                            }
                            <div>{p.lName ? `${p.lName}, ${p.fName}` : p.fName}</div>
                            <div>{p.info}</div>
                        </div>
                    ))
                }
            </div>
            <p className='meta'>{chosenPlayers.length} {type === 'teams' ? 'Teams' : 'Players'} Chosen</p>
            {
                (!location.pathname.includes('/teams/') && type !== 'teams') &&
                <CategoryFilterModal 
                    show={showFilterModal} 
                    cancel={() => setShowFilterModal(false)} 
                    chooseCategory={chooseTeam} 
                    categoryList={teams} 
                    selectedCategories={chosenTeams} 
                    color='var(--primary)'
                    text='Filter by Team'
                />
            }
            {
                (type !== 'teams') &&
                <CategoryFilterModal 
                    show={showInfoFilterModal} 
                    cancel={() => setShowInfoFilterModal(false)} 
                    chooseCategory={chooseInfo} 
                    categoryList={infoList} 
                    selectedCategories={chosenInfo} 
                    color='var(--primary)'
                    text='Filter by Info'
                />
            }
        </div>
    )
}

export default PlayerSelector