import React, { useEffect, useState } from 'react'
import './rewards.styles.scss'

import { useSelector } from 'react-redux';
import ViewRewardsModal from './view-rewards-modal.component';
import { switchPlayerMenu } from '../side-menu.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import RewardItemUnclicked from './reward-item-unclicked.component';
import RewardItemClicked from './reward-item-clicked.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faGem, faGift } from '@fortawesome/free-solid-svg-icons';

const PlayerRewards = ({ 
    gameData, 
    playerData, 
    playerActivity, 
    gameElements 
}) => {

    const [ rewardModalIsOpen, setRewardModalIsOpen ] = useState(false);
    const [ modalActivity, setModalActivity ] = useState(null);
    const [ activityToDisplay, setActivityToDisplay ] = useState(null);
    const [ modalHeadText, setModalHeadText ] = useState(null);
    const [ notificationCounts, setNotificationCounts ] = useState(null);
    const [ viewCategory, setViewCategory ] = useState('reward');

    useEffect(() => {
        if (!playerActivity || !playerData || !viewCategory) {return;}
        let newActivityObj = {};
        const content = Object.values(playerActivity)
        .filter(a => a.display === viewCategory);
        for (let a of content) {
            newActivityObj[a.id] = a;
        }
        console.log(newActivityObj);
        setActivityToDisplay(newActivityObj)
    }, [ playerActivity, playerData, viewCategory ])

    useEffect(() => {
        if (!playerActivity || !playerData || !playerData.newStuff) return;
        let countObj = {
            'reward': 0,
            'achievement': 0,
            'prizepack': 0
        }
        for (let a of playerData.newStuff) {
            if (playerActivity[a]) {
                countObj[playerActivity[a].display]++
            }
        }
        setNotificationCounts(countObj);
    }, [playerActivity || playerData])

    useEffect(() => {
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
            pageTitle.innerText = 'My Rewards'
        }
        switchPlayerMenu('rewards-btn');
    }, []);


    function handleRewardModalOpen(activityId) {
        const act = playerActivity[activityId];
        setModalActivity(act)
        setModalHeadText(
            act.display === 'prizepack'
            ? 'New '+ act.title + '!'
            : 'Your New Rewards!'
        )
        setRewardModalIsOpen(true)
    }

    function handleRewardModalClose() {
        setRewardModalIsOpen(false)
    }

    

    return (
        <div className='player-rewards g-card'>
            <div className='player-title'>
                <ImageContainer src='/images/icons/treasure.png' alt='rewards icon' className='player-title-icon' />
                My Rewards
            </div>
            <hr />
            <div className='reward-grid'>
                <div className='left-menu'>
                    <button 
                        className={`g-button med-btn ${viewCategory === 'reward' ? 'primary' : ''}`}
                        onClick={() => setViewCategory('reward')}>
                        <div className='notification-with-badge'>
                            <FontAwesomeIcon icon={faGem} />
                            Rewards
                            {
                                (notificationCounts && notificationCounts.reward > 0) &&
                                <span className="badge">{notificationCounts.reward}</span>
                            }
                        </div>
                    </button>
                    <button 
                        className={`g-button med-btn ${viewCategory === 'achievement' ? 'primary' : ''}`}
                        onClick={() => setViewCategory('achievement')} >
                        <div className='notification-with-badge'>
                            <FontAwesomeIcon icon={faAward} />
                            Acheivements
                            {
                                (notificationCounts && notificationCounts.achievement > 0) &&
                                <span className="badge">{notificationCounts.achievement}</span>
                            }
                        </div>
                    </button>
                    {
                        (
                            gameData && 
                            gameData.access.includes('prizepacks') &&
                            gameElements &&
                            Object.values(gameElements).filter(e => 
                                e.status === 'active' &&
                                e.opt_itemType === 'prizepack'
                            ).length > 0
                        ) &&
                        <button 
                            className={`g-button med-btn ${viewCategory === 'prizepack' ? 'primary' : ''}`}
                            onClick={() => setViewCategory('prizepack')} >
                            <div className='notification-with-badge'>
                                <FontAwesomeIcon icon={faGift} />
                                Prize Packs
                                {
                                    (notificationCounts && notificationCounts.prizepack > 0) &&
                                    <span className="badge">{notificationCounts.prizepack}</span>
                                }
                            </div>
                        </button>
                    }
                </div>
                <div className='reward-list-grid'>
                {
                    (activityToDisplay && playerData) &&
                    Object.values(activityToDisplay)
                    .sort((a,b) => a.ts < b.ts ? 1 : -1)
                    .map(act => (
                        (playerData.newStuff && playerData.newStuff.includes(act.id))
                        ?
                        <div key={act.ts} >
                            <RewardItemUnclicked 
                                reward={act}
                                gameData={gameData}
                                playerData={playerData}
                                handleRewardModalOpen={handleRewardModalOpen} />
                        </div>
                        :
                        <div key={act.ts}>
                            <RewardItemClicked 
                                reward={act}
                                handleRewardModalOpen={handleRewardModalOpen} />
                        </div>
                    ))
                }
                </div>
            </div>
            <ViewRewardsModal 
                isOpen={rewardModalIsOpen} 
                onClose={() => handleRewardModalClose()} 
                activity={modalActivity} 
                width={'normal'} 
                closeButton={true}
                modalHeadText={modalHeadText} />
        </div>
    )
}

export default PlayerRewards