import React, { useEffect, useRef, useState } from 'react';
import './org-settings.styles.scss';
import OrgInfo from './settings-components/org-info.component';
import OrgIcon from './settings-components/org-icon.component';
import { useLocation, useNavigate } from 'react-router-dom';
import OrgInvites from './settings-components/org-invites.component';
import { getInvitesFromDb } from '@/utils/getAdminData';
import CreateEditOrg from '../create-org/create-edit-org.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';

const OrgSettings = ({ orgData, saveOrgData }) => {

    const [ invites, setInvites ] = useState(null)
    let inviteListener;
    const inviteRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        return () => {
            if (inviteListener) inviteListener();
        }
    } ,[])

    useEffect(() => {
        console.log(orgData)
        if (!orgData) return;
        getOrgInvites();
    }, [orgData])

    function getOrgInvites() {
        getInvitesFromDb({
            'orgId': orgData.orgId,
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data, id) {
            inviteRef.current = {...inviteRef.current, ...{[id]: data}}
            setInvites({...inviteRef.current});
        }
        function handleListener(unsub) {
            inviteListener = unsub;
        }
    }

    return (
        <div className='org-settings-container'>
            <div className='org-settings'>
                <BackButton cancel={() => navigate(-1)} />
                <div className='g-space-1'></div>
                <div className='g-card'>
                    <div className='card-title'>
                        <ImageContainer 
                            src={orgData.icon ?? '/images/icons/placeholder-icon-org.jpg'} 
                            className='org-icon' />
                        {orgData.name}
                    </div>
                </div>
                <div className="g-space-1"></div>
                <div className='g-card'>
                    <OrgInvites orgData={orgData} invites={invites} />
                </div>
                <div className="g-space-1"></div>
                <div className='g-card'>
                    <OrgIcon orgData={orgData} saveOrgData={saveOrgData} />
                </div>
                <div className="g-space-1"></div>
                <CreateEditOrg org={orgData} />
                <div className="g-space-1"></div>
            </div>
        </div>
    )
}

export default OrgSettings;