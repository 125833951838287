import React, { useEffect, useRef, useState } from 'react'
import './reward-item-unclicked.styles.scss';
import { useDispatch } from 'react-redux';
import { setNewStuffCount } from '@/state/slices/contentSlice';
import { UseCloudDistribute } from '@/utils/firebase.utils';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const RewardItemUnclicked = ({ 
    gameData,
    playerData,
    reward,
    handleRewardModalOpen 
}) => {

    const [ startingImage, setStartingImage ] = useState(null);
    const [ finishGif, setFinishGif ] = useState(null);
    const dispatch = useDispatch();
    const timeoutRef = useRef();

    useEffect(() => {
        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        }
    }, [])

    useEffect(() => {
        if (!reward) return;
        setStartingImage(
            (reward.display === 'reward')
            ?
            '/images/icons/treasure-cl.png'
            :
            (reward.display === 'achievement')
            ?
            '/images/icons/achievement_frame.gif'
            :
            (reward.display === 'prizepack')
            ?
            '/images/icons/prizepack_frame.gif'
            :
            ''
        )
        setFinishGif(
            (reward.display === 'reward')
            ?
            '/images/icons/treasure.gif'
            :
            (reward.display === 'achievement')
            ?
            '/images/icons/achievement_gif_half.gif'
            :
            (reward.display === 'prizepack')
            ?
            '/images/icons/prizepack_gif_short.gif'
            :
            ''
        )
    }, [reward])

    async function openReward(id, newItem) {
        let savedNewStuffCount = Number(window.localStorage.getItem(`${gameData.gameId}_newRewardCount`));
        savedNewStuffCount--;
        window.localStorage.setItem(`${gameData.gameId}_newRewardCount`, savedNewStuffCount);
        dispatch(setNewStuffCount(savedNewStuffCount));
        document.getElementById(`reward-${id}-div`).classList.add('unclickable-treasure');
        document.getElementById(`reward-${id}-div`).classList.remove('clickable-treasure');
        document.getElementById(`reward-${id}`).src = `${import.meta.env.VITE_ROOT}${finishGif}`
        timeoutRef.current = setTimeout( async () => {
            handleRewardModalOpen(id);
            const data = {
                'gameId': gameData.gameId,
                'playerId': playerData.playerId,
                'rewardId': id
            }
            console.log(data);
            const res = await UseCloudDistribute('markRewardAsViewed', data)
            console.log(res);
        }, 2000)
    }

    return (
        <div className='reward-item-unclicked'>
            <div 
                className='clickable-treasure' 
                id={`reward-${reward.id}-div`} 
                onClick={() => openReward(reward.id, true)}>
                <ImageContainer 
                    alt='closed treasure icon' 
                    id={`reward-${reward.id}`} 
                    src={startingImage} />
            </div>
        </div>
    )
}

export default RewardItemUnclicked