import React, { useEffect, useRef, useState } from 'react'
import './create-elements-landing.styles.scss';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faCloudUpload, faFireAlt, faMapMarkerAlt, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { membershipRestrictions } from '@/utils/membershipRestrictions';

const CreateElementsLanding = ({ gameData, gameElements }) => {

    const membership = useSelector(state => state.userData.membership);
    const screenSize = useSelector(state => state.contentData.screenSize);
    const [ access, setAccess ] = useState([]);
    const accessRef = useRef([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!gameData || !membership || !membershipRestrictions) return;
        if (
            gameData.access.includes('badges') ||
            gameData.access.includes('challenges') ||
            gameData.access.includes('quests') ||
            gameData.access.includes('secretCodes') 
        ) accessRef.current.push('interactives');
        if (
            gameData.access.includes('levels') ||
            gameData.access.includes('leaderboards')
        ) accessRef.current.push('pointsLeaderboards');
        if (
            gameData.access.includes('collections') ||
            gameData.access.includes('items') ||
            gameData.access.includes('prizepacks') 
        ) accessRef.current.push('currencyMarketplace');
        if (
            gameData.access.includes('badges') &&
            !membershipRestrictions[membership.membership].blocked.includes('badges')
        ) accessRef.current.push('badges');
        if (
            gameData.access.includes('challenges') &&
            !membershipRestrictions[membership.membership].blocked.includes('challenges')
        ) accessRef.current.push('challenges');
        if (
            gameData.access.includes('quests') &&
            !membershipRestrictions[membership.membership].blocked.includes('badges')
        ) accessRef.current.push('quests');
        if (
            gameData.access.includes('secretCodes') &&
            !membershipRestrictions[membership.membership].blocked.includes('secretCodes')
        ) accessRef.current.push('secretCodes');
        if (
            gameData.access.includes('riskCards') &&
            !membershipRestrictions[membership.membership].blocked.includes('riskCards')
        ) accessRef.current.push('riskCards');
        if (
            gameData.access.includes('bossBattles') &&
            !membershipRestrictions[membership.membership].blocked.includes('bossBattles')
        ) accessRef.current.push('bossBattles');
        if (
            gameData.access.includes('investments') &&
            !membershipRestrictions[membership.membership].blocked.includes('investments')
        ) accessRef.current.push('investments');
        if (
            gameData.access.includes('leaderboards') &&
            !membershipRestrictions[membership.membership].blocked.includes('leaderboards')
        ) accessRef.current.push('leaderboards');
        if (
            gameData.access.includes('levels') &&
            !membershipRestrictions[membership.membership].blocked.includes('levels')
        ) accessRef.current.push('levels');
        if (
            gameData.access.includes('collections') &&
            !membershipRestrictions[membership.membership].blocked.includes('collections')
        ) accessRef.current.push('collections');
        if (
            gameData.access.includes('items') &&
            !membershipRestrictions[membership.membership].blocked.includes('items')
        ) accessRef.current.push('items');
        if (
            gameData.access.includes('prizepacks') &&
            !membershipRestrictions[membership.membership].blocked.includes('prizepacks')
        ) accessRef.current.push('prizepacks');
        setAccess(accessRef.current);
    }, [gameData, membership, membershipRestrictions])

    return (
        <div className='create-elements-landing'>
            <div className='g-card elements-card'>
                <BackButton cancel={() => navigate(`/manage/${gameData.path}`)} />
                <div className="g-space-1"></div>
                <div className='card-title'>
                    Create Game Elements
                    {/* <button className='g-button med-btn'><FontAwesomeIcon icon={faCloudUpload} />Import Content</button> */}
                </div>
                <hr />
                {
                    (access.includes('interactives')) &&
                    <>
                    <h4>Player Interactives</h4>
                    <div className={`content-boxes ${screenSize}`}>
                        {
                            (access.includes('badges')) &&     
                            <button className='g-button element-button' onClick={() => navigate('badges')}>
                                <ImageContainer src='/images/icons/badges.png' alt='badges icon' className='head-icon' />
                                <div className='item-title'>
                                    <b>Badges</b>
                                </div>
                                <div className='meta count'>Count: {gameData.createdBadges ? `${gameData.createdBadges}` : '0'}</div>
                                <div className='meta'>Badges are credentials that can be distributed by the game manager or they can be earned by completing Challenges.</div>
                            </button>
                        }
                        {
                            (access.includes('challenges')) &&
                            <button className='g-button element-button' onClick={() => navigate('challenges')}>
                                <ImageContainer src='/images/icons/challenge.png' alt='challenge icon' className='head-icon' />
                                <div className='item-title'>
                                    <b>Challenges</b>
                                </div>
                                <div className='meta count'>Count: {gameData.createdChallenges ? `${gameData.createdChallenges}` : '0'}</div>
                                <div className='meta'>Challenges are one-off tasks that can be completed by players to earn XP, Currency, or other game elements. Players can submit text and also upload content.</div>
                            </button>
                        }
                        {
                            (access.includes('quests')) &&    
                            <button className='g-button element-button disabled'>
                                <FontAwesomeIcon icon={faMapMarkerAlt} size='xl' style={{'color': 'var(--quest-color)'}} />
                                <div className='item-title'>
                                    <b>Quests</b>
                                </div>   
                                <div className='meta count'>Count: {gameData.createdQuests ? `${gameData.createdQuests}` : '0'}</div>
                                <div className='meta'>Quest are multi-step collections of challenges that players can complete in a prescribed path. Rewards can be earned at each checkpoint.</div>
                            </button>
                        }
                        {
                            (access.includes('secretCodes')) &&    
                            <button className='g-button element-button' onClick={() => navigate('secretcodes')}>
                                <ImageContainer src='/images/icons/secret2.png' alt='secret codes icon' className='head-icon' />
                                <div className='item-title'> 
                                    <b>Secret Codes</b>
                                </div>
                                <div className='meta count'>
                                    Count: {gameData.createdSecretCodes ? `${gameData.createdSecretCodes}` : '0'}
                                </div>
                                <div className='meta'>When players find and input Secret Codes, they can earn XP, Currency, and other game elements.</div>
                            </button>
                        }
                        {
                            (access.includes('riskCards')) &&
                            <div className='g-card disabled'>
                                <FontAwesomeIcon icon={faQuestionCircle} size='xl' style={{'color': 'var(--risk-color)'}} />
                                <div className='item-title'>
                                    <b>Risk Cards</b>
                                </div>
                                <div className='meta count'>
                                    Count: {gameData.createdRiskCards ? `${gameData.createdRiskCards}` : '0'}
                                </div>
                                <div className='meta'>Risk Cards are interactive items that can be distributed by the game manager or they can be earned by completing Challenges, completing Quests, or by finding Secret Codes. These Cards will give or remove Currency or Items from a Player's inventory.</div>
                            </div>
                        }
                        {
                            (access.includes('bossBattles')) &&
                            <div className='g-card disabled'>
                                <FontAwesomeIcon icon={faFireAlt} size='xl' style={{'color': 'var(--boss-color)'}} />
                                <div className='item-title'>
                                    Boss Battles<br />
                                </div>
                                <div className='meta count'>
                                    Count: {gameData.createdBossBattles ? `${gameData.createdBossBattles}` : '0'}
                                </div>
                                <div className='meta'>Boss Battles are one-off events, run by the Game Manager that pit players or teams against an opponent.</div>
                            </div>
                        }
                        {
                            (access.includes('investments')) &&
                            <div className='g-card disabled'>
                                <FontAwesomeIcon icon={faChartLine} size='xl' style={{'color': 'var(--level-color)'}} />
                                <div className='item-title'>
                                    Investments<br />
                                </div>
                                <div className='meta count'>
                                    Count: {gameData.createdInvestments ? `${gameData.createdInvestments}` : '0'}
                                </div>
                                <div className='meta'>Investments allow players to invest currency in the number of times Challenges or Quests will be completed, levels will be earned, and items will be earned or purchased.</div>
                            </div>
                        }
                    </div>
                    </>
                }
                {
                    (access.includes('pointsLeaderboards')) &&
                    <>
                    <h4>Points and Leaderboard Features</h4>
                    <div className={`content-boxes ${screenSize}`}>
                        
                        {
                            (access.includes('leaderboards')) &&
                            <button className='g-button element-button' onClick={() => navigate(`/manage/${gameData.path}/leaderboards`)}>
                                <ImageContainer src='/images/icons/leaderboard.png' alt='leaderboards icon' className='head-icon' />
                                <div className='item-title'>
                                    <span><b>Leaderboards</b></span>
                                </div>
                                <div className='meta count'>
                                    Count: {gameData.createdLeaderboards ? `${gameData.createdLeaderboards}` : '0'}
                                </div>
                                <div className='meta'>Leaderboards allow Game Managers to create rankings of players or teams based on their own set of conditions.</div>
                            </button>
                        }
                        {
                            (access.includes('levels')) &&    
                            <button className='g-button element-button' onClick={() => navigate('levels')}>
                                <ImageContainer src='/images/icons/rankings.png' alt='levels icon' className='head-icon' />
                                <div className='item-title'>
                                    <span><b>Levels</b></span>
                                </div>
                                <div className='meta count'>
                                    Count: {gameData.createdLevels ? `${gameData.createdLevels}` : '0'}
                                </div>
                                <div className='meta'>Levels are earned by players when they reach a certain XP threshold. Players can be rewarded with Currency or other Game Elements when they reach each level.</div>
                            </button>
                        }
                    </div>
                    </>
                }
                {
                    (access.includes('currencyMarketplace')) &&
                    <>
                    <h4>Marketplace Features</h4>
                    <div className={`content-boxes ${screenSize}`}>
                        {
                            (access.includes('collections')) &&
                            <button className='g-button element-button' onClick={() => navigate('collections')}>
                                <ImageContainer src='/images/icons/collections.png' alt='collections icon' className='head-icon' />
                                <div className='item-title'>
                                    <span><b>Collections</b></span>
                                </div>
                                <div className='meta count'>
                                    Count: {gameData.createdCollections ? `${gameData.createdCollections}` : '0'}
                                </div>
                                <div className='meta'>Collections are groups of Items. When players earn all of the Items in a Collection, they can earn XP, Currency, or other Game Elements.</div>
                            </button>
                        }
                        {
                            (accessRef.current.includes('items')) &&
                            <button className='g-button element-button' onClick={() => navigate('items')}>
                                <ImageContainer src='/images/icons/items.png' alt='items icon' className='head-icon' />
                                <div className='item-title'>
                                    <span><b>Items</b></span>
                                </div>
                                <div className='meta count'>
                                    Count: {gameData.createdItems ? `${gameData.createdItems}` : '0'}
                                </div>
                                <div className='meta'>Items are rewards earned by Players. They can be Inventory Items which are used as a game mechanic, Real World Items, Collectible Items, Lottery Tickets, or Player-Choice Contributions.</div>
                            </button>
                        }
                        {
                            (access.includes('prizepacks')) &&    
                            <button 
                                className='g-button element-button'
                                onClick={() => navigate('prizepacks')} >
                                <ImageContainer src='/images/icons/prizepacks.png' alt='prize packs icon' className='head-icon' />
                                <div className='item-title'>
                                    <span><b>Prize Packs</b></span>
                                </div>
                                <div className='meta count'>
                                    Count: {gameData.createdPrizePacks ? `${gameData.createdPrizePacks}` : '0'}
                                </div>
                                <div className='meta'>Prize Packs are collections of Items that, when earned, distribute a number of chosen Items to the Player.</div>
                            
                            </button>
                        }
                    </div>
                    </>
                }    
                    
                </div>
            </div>

    )
}

export default CreateElementsLanding