export function prereqFilter(element, playerData, gameElements) {

    if (!element || !playerData || !gameElements) {
        return false;
    }
    
    // Check to see if the element is visible
    if (!element.opt_visible) {
        return false;
    }

    // Check to see if the element is available
    if (!element.opt_available) {
        return false;
    }

    // Check open and close date
    const now = new Date().getTime();
    if ((element.opt_dateOpen && element.opt_dateOpen > now) || (element.opt_dateClose && element.opt_dateClose < now)) {
        return false;
    }

    // Check points
    if (element.opt_prereqPoints) {
        if (playerData.points < element.opt_prereqPoints) {
            return false;
        }
    } 

    // Check level
    if (element.opt_prereqLevel) {
        if (!playerData.levels || !playerData.levels[element.opt_prereqLevel]) {
            return false;
        } 
    } 

    // Check Badges
    if (element.opt_prereqBadges && element.opt_prereqBadges.length > 0 && element.opt_prereqBadges[0]) {
        for (let b of element.opt_prereqBadges) {
            if (!playerData.badges || !playerData.badges[b]) {
                return false;
            }
        }
    } 

    // Check Items
    if (element.opt_prereqItems && element.opt_prereqItems.length > 0 && element.opt_prereqItems[0]) {
        for (let i of element.opt_prereqItems) {
            if (!playerData.items || !playerData.items[i]) {
                return false;
            } 
        }
    }

    // Check Groups
    if (element.opt_prereqGroups && element.opt_prereqGroups.length > 0 && element.opt_prereqGroups[0]) {
        for (gr of element.opt_prereqGroups) {
            let selectedGroup = gameElements[gr];
            if (selectedGroup.type !== 'group') {
                continue;
            }
            if (!selectedGroup.opt_members || !Object.keys(selectedGroup.opt_members).includes(playerData.playerId)) {
                return false;
            }
        }
    }

    return true;
}