import React, { useEffect, useRef, useState } from 'react'
import './activity-list.styles.scss'
import { format } from 'date-fns'
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const ActivityList = ({ 
    gameActivity, 
    playerList 
}) => {
    
    const [ moreActivity, setMoreActivity ] = useState(false);
    const [ activityToDisplay, setActivityToDisplay ] = useState([]);
    let start = 0
    const endRef = useRef(20);

    useEffect(() => {
        if (!gameActivity) {return;}
        // console.log(gameActivity);
        showActivity();
    }, [gameActivity])

    function showActivity() {
        let sortedActivity = Object.values(gameActivity).sort((a,b) => a.ts < b.ts ? 1 : -1)
        .filter(a => a.ts > 0);
        console.log(endRef.current);
        setActivityToDisplay(sortedActivity.slice(start, endRef.current));
        if (sortedActivity.length > endRef.current) {
            setMoreActivity(true);
        } else {
            setMoreActivity(false);
        }
    }

    function loadMoreActivity() {
        endRef.current += 20
        showActivity();
    }

    return (
        <div className='activity-list'>
            {
                (activityToDisplay.length > 0 && playerList) &&
                activityToDisplay
                .map(a => (
                    <div key={a.id} className='activity-item'>
                        <div className='activity-icon'>
                            <ImageContainer src={a.icon} alt='activity icon' className='icon' />
                        </div>
                        <div className='activity-text'>
                            <b>{a.title}</b>: {a.event} by <b>{a.userName ? a.userName : (playerList[a.playerId] && playerList[a.playerId].displayName) ? playerList[a.playerId].displayName : ''}</b> on {format(new Date(a.ts), 'Pp')}
                        </div>
                    </div>
                ))
            }
            {
                (moreActivity) &&
                <div className='more-button'>
                    <button className='g-button small-btn' onClick={() => loadMoreActivity()}>Load more...</button>
                </div>
            }
        </div>
    )
}

export default ActivityList