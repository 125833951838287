import React, { useEffect, useState } from 'react';
import './game-dashboard.styles.scss';
import Chart3 from './chart3.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faBell, faFlask, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import ActivityList from './activity-list.component';
import { useSelector } from 'react-redux';
import FeaturedContent from './featured-content.component';
import MessageCreator from './message-creator.component';
import LeadersList from './leaders-list.component';
import DataCard from './data-card.component';

const GameDashboard = ({ 
    gameData, 
    gameActivity, 
    playerList,  
    gameElements,
    latestMessage,
    actionItems,
    role
}) => {

    const screenSize = useSelector(state => state.contentData.screenSize);
    const navigate = useNavigate();

    return (
        <div className='game-dashboard'>
            {
                (gameData) &&
                <div className={`game-dashboard-content ${screenSize}`}>
                    <div className='player-count'>
                        <DataCard 
                            icon={faUsers}
                            data={gameData.playerCount ?? 0}
                            text='Players/Teams'
                            onclick={() => navigate('players')} 
                            role={role}
                        />
                    </div>
                    {/* <Link to='players'>
                        <div className='data-box player-count g-card clickable'>
                            <div className='icon-circle'>
                                <FontAwesomeIcon icon={faUsers} size='xl' />
                            </div>
                            <div className='data-div'>
                                <div className='data-number'>
                                    {gameData.playerCount ?? 0}
                                </div>
                                <div className='data-text'>
                                    Players/Teams
                                </div>
                            </div>
                        </div>
                    </Link> */}
                    <div className='box2'>
                        <DataCard 
                            icon={faBell}
                            data={
                                actionItems ? 
                                Object.values(actionItems)
                                .filter(a => a.status === 'pending')
                                .length : 
                                0
                            }
                            text='Action Items'
                            onclick={() => navigate('actionitems')} 
                        />
                    </div>
                    {/* <Link to='actionitems'>
                        <div className='data-box box2 g-card clickable'>
                            <div className='icon-circle'>
                                <FontAwesomeIcon icon={faBell} size='xl' />
                            </div>
                            <div className='data-div'>
                                <div className='data-number'>
                                    {gameData.elementCount ?? 0}
                                </div>
                                <div className='data-text'>
                                    Action Items
                                </div>
                            </div>
                        </div>
                    </Link> */}
                    <div className='box3'>
                        <DataCard 
                            icon={faFlask}
                            data={gameData.createdElementTotal ?? 0}
                            text='Game Elements'
                            onclick={() => navigate('elements')} 
                        />
                    </div>
                    {/* <Link to='elements'>
                        <div className='data-box box3 g-card clickable'>
                            <div className='icon-circle'>
                                <FontAwesomeIcon icon={faFlask} size='xl' />
                            </div>
                            <div className='data-div'>
                                <div className='data-number'>
                                    {gameData.createdElementTotal ?? 0}
                                </div>
                                <div className='data-text'>
                                    Game Elements
                                </div>
                            </div>
                        </div>
                    </Link>	 */}
                    <div className='activity-box'>
                        <div className='g-card'>
                            <div className='activity-card-title'>
                                <span>Activity</span>
                                <button 
                                    className='g-button text-only'
                                    onClick={() => navigate(`activity`)}
                                >
                                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                                </button>
                            </div>
                            <hr />
                            <ActivityList 
                                gameActivity={gameActivity} 
                                playerList={playerList} />
                        </div>
                    </div>
                    <div className='graph-box' id='graph-box'>
                        <div className='g-card' >
                            <div className='chart-text'>Activity Over Last 10 Days</div>
                            {
                                (gameActivity) &&
                                <Chart3 recentActivity={Object.values(gameActivity)} />
                            }
                        </div>
                    </div>
                    <div className='leaders-box'>
                        <div className='g-card' id='leader-card'>
                            <span>Leaders</span>
                            {
                                (gameData.sortLeaders === 'currency' || gameData.sortLeaders === 'currencyTotal' ? <span>&nbsp;&#129689;</span> : <span>&nbsp;💎</span>)
                            }
                            <hr />
                            <LeadersList 
                                gameData={gameData} 
                                playerList={playerList}
                                role={role} />
                        </div>
                    </div>
                    <div className='feature-box'>
                        <div className='g-card'>
                            <FeaturedContent 
                                gameData={gameData} 
                                gameElements={gameElements}
                                role={role} />
                        </div>
                    </div>
                    <div className='message-box'>
                        <div className='g-card' id='message-card'>
                            <MessageCreator 
                                gameData={gameData} 
                                latestMessage={latestMessage} 
                                role={role} />
                        </div>
                    </div>
                    {/* <div className='box6'>
                        <div className='g-card'>
                            
                        </div>
                    </div> */}
                </div>
            }
        </div>
    )
}

export default GameDashboard