import React, { useEffect, useRef, useState } from 'react';
import './prize-packs-form.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate, useParams } from 'react-router-dom';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import ManageContentInformation from '../blocks/information.component';
import ManageContentIconCropped from '../blocks/icon.component';
import ManageContentCategories from '../blocks/categories.component';
import ManageContentPrerequisites from '../blocks/prerequisites.component';
import ManageContentMarket from '../blocks/market.component';
import ManageContentPrizePackItems from '../blocks/prize-pack-items.component';
import ManageContentPrizePackWeights from '../blocks/prize-pack-weights.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { useSelector } from 'react-redux';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { RandomString } from '@/utils/randomString';
import FullPageLoader from '@/components/loader/full-page-loader.component';
import ManageContentVisibility from '../blocks/visibility.component';
import ManageContentDates from '../blocks/dates.component';
import PrizePackCount from '../blocks/prize-pack-count.component';

const PrizePacksForm = ({
    gameData,
    gameElements,
    playerList
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({});
    const [ categories, setCategories ] = useState([]);
    const [ availableItems, setAvailableItems ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const { packPath } = useParams();
    const navigate = useNavigate();
    const storage = getStorage();
    const dataRef = useRef();
    const approveRef = useRef();

    useEffect(() => {
        if (!gameElements || !packPath) return;
        const packToEdit = Object.values(gameElements)
        .filter(p => p.path === packPath)[0];
        dataRef.current = {...packToEdit};
        setFormData(dataRef.current);
    }, [gameElements, packPath])

    useEffect(() => {
        if (!gameElements) {return;}
        let arr = [];
        for (let c of Object.values(gameElements).filter(c => c.type === 'prizePack')) {
            if (c.opt_cat && !arr.includes(c.opt_cat)) {
                arr.push(c.opt_cat)
            }
        }
        setCategories(arr.sort())
        const avail = Object.values(gameElements)
        .filter(e => 
            e.type === 'item' &&
            e.opt_itemType !== 'contribution' &&
            e.opt_itemType !== 'prizepack'
        )
        setAvailableItems([...avail])
    }, [gameElements])

    function saveData(key, value) {
        if (key === 'opt_prizes') {
            const oldPrizes = formData.opt_prizes ? [...formData.opt_prizes] : [];
            updateWeights(oldPrizes, value);
        }
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFormData(dataRef.current);
    }

    function updateWeights(oldPrizes, chosenPrizes) {
        let weights = formData.opt_prizeWeights ? {...formData.opt_prizeWeights} : {};
        for (let old of oldPrizes) {
            if (!chosenPrizes.includes(old)) {
                delete weights[old];
            }
        }
        for (let p of chosenPrizes) {
            if (!weights[p]) {
                weights[p] = {
                    'id': p,
                    'weight': 20
                }
            }
        }
        saveData('opt_prizeWeights', weights);
    }

    function handleSubmit() {
        let tempData = {...dataRef.current};
        if (tempData.opt_market && !tempData.opt_marketPrice) {
            setAlertMessage('You must assign a Market Price if you want the Prize Pack to be available in the Store.');
            return;
        }
        if (!tempData.opt_itemCount) {
            setAlertMessage('You must')
        }
        setConfirmMessage('Are you sure you are ready to save this Prize Pack? All of these settings can be changed later.')
        approveRef.current = () => {
            finishApproval()
        }
    }

    async function finishApproval() {
        setLoading(true);
        let tempData = {...dataRef.current}
        if (tempData.icon.upload) {
			tempData.icon.croppie.result({
				type:'blob',
				size: {'width': 352, 'height': 352},
                quality: 0.7,
			})
			.then((blob) => {
				uploadImage(tempData.icon, blob)
				return;
			})
		} else if (tempData.icon.url) {
			savePrizePack(tempData.icon.url)
			return;
		} else if (tempData.icon) {
            savePrizePack(tempData.icon)
            return; 
        } else {
			setAlertMessage('Something went wrong with the icon. Please try using a different image.');
            setLoading(false);
            return;
		}
    }

    function uploadImage(icon, blob) {		
		const now = new Date().getTime();
		const iconRef = ref(storage, `${gameData.gameId}/prizePackIcons/${now}-${icon.name}`)
		const metadata = {
			contentType: icon.type,
            customMetadata: {
                appId: userProfile.appId,
                email: userProfile.email
            }
		};
		uploadBytes(iconRef, blob, metadata).then((snapshot) => {
			getDownloadURL(iconRef)
			.then((url) => {
				savePrizePack(url)
				return;
			})
		})
	}

    async function savePrizePack(iconUrl) {
        let tempData = {...dataRef.current};
        const now = new Date().getTime();
        const gameCode = gameData.gameId.substring(gameData.gameId.indexOf('-')-5, gameData.gameId.indexOf('-')+6)
        if (!tempData.id) {
            tempData.appId = userProfile.appId;
            tempData.ts_created = now;
            tempData.gameId = gameData.gameId;
            tempData.id = now + '-' + gameCode + '-prizepack';
            tempData.status = 'active';
            tempData.type = 'item';
            tempData.opt_itemType = 'prizepack';
            tempData.path = RandomString(18, 'abcdefghijklmnopqrstuvwxyz-_0123456789');
        } 
        tempData.ts = now;
        tempData.icon = iconUrl;
        console.log(tempData);
        const res = await UseCloudFunction(
            'saveGameElement', 
            {
                'appId': userProfile.appId, 
                'elementData': tempData, 
                'userName': `${userProfile.fName} ${userProfile.lName}`
            }
        )
        console.log(res);
        if (res.error) {
            setAlertMessage('Something went wrong. Please try again later. Error: ' + res.error);
            setLoading(false);
            return;
        }
        setAlertMessage('Prize Pack saved successfully.');
        dataRef.current = {};
        setFormData(dataRef.current);
        setLoading(false);
        navigate(-1);
    }

    return (
        <div className='prize-packs-form'>
            <div className='g-card prize-packs-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className="g-space-1"></div>
                <div className='card-title'>
                    <ImageContainer src='/images/icons/prizepacks.png' alt='items icon' className='head-icon' />
                    Create and Edit Prize Packs
                </div>
                <div className="g-space-1"></div>
                <div className='meta'>{formData.path ?? ''}</div>
                <div className='create-item-form'>
                    <ManageContentInformation 
                        type='prizePack' 
                        saveData={saveData} 
                        formData={formData} 
                        textEditorLabel='Prize Pack Description (optional)'
                    />
                    <div className='g-space-2'></div>
                    <ManageContentIconCropped
                        type='prizePack'
                        saveData={saveData}
                        formData={formData}
                    />
                    <div className='g-space-2'></div>
                    <ManageContentCategories
                        type='prizePack'
                        saveData={saveData}
                        formData={formData}
                        categories={categories} />
                    <div className='g-space-2'></div>
                    <ManageContentVisibility 
                        type='prizePack' 
                        saveData={saveData} 
                        formData={formData} 
                        previewText=''
                        visibilityText="Turn visibility ON for this Prize Pack. If toggled off, it will be totally hidden from players." /> 
                    <div className='g-space-2'></div>
                    <ManageContentPrerequisites 
                        type='prizePack'
                        saveData={saveData}
                        formData={formData}
                        gameElements={gameElements}
                        availableItems={availableItems}
                        teams={
                            Object.values(playerList)
                            .filter(p => p.team && p.status === 'active')
                            .sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                        } />
                    <div className='g-space-2'></div>
                    <ManageContentMarket
                        type='prizePack'
                        formData={formData}
                        saveData={saveData}
                        gameElements={gameElements} />
                    <div className='g-space-2'></div>
                    <ManageContentPrizePackItems
                        formData={formData}
                        saveData={saveData}
                        gameData={gameData}
                        gameElements={availableItems} />
                    <div className='g-space-2'></div>
                    <ManageContentPrizePackWeights
                        formData={formData}
                        saveData={saveData}
                        gameElements={gameElements} />
                    <div className='g-space-2'></div>
                    <PrizePackCount
                        formData={formData}
                        saveData={saveData} />
                    <div className='g-space-2'></div>
                    <ManageContentDates
                        type='prizePack' 
                        saveData={saveData} 
                        formData={formData} 
                        gameElements={gameElements}
                        text={`Prize Packs will only be available to purchase before or after these dates.`} />
                </div>
                <div className='g-space-2'></div>
                {
                    (loading) 
                    ?
                    <div className='buttons'>
                        <button className='g-button '>
                            Cancel
                        </button>
                        <button className='g-button'>
                            <ThreeDotsLoader />
                        </button>
                    </div>
                    :
                    <div className='buttons'>
                        <button 
                            className='g-button'
                            onClick={() => navigate(-1)}>
                            Cancel
                        </button>
                        <button
                            className={`g-button primary ${
                                !formData.name ||
                                !formData.icon ||
                                !formData.opt_itemCount ||
                                (
                                    !formData.opt_prizes ||
                                    formData.opt_prizes.length === 0
                                )
                                ?
                                'disabled'
                                :
                                ''
                            }`}
                            onClick={() => handleSubmit()}>
                            Save Prize Pack
                        </button>
                    </div>
                }
            </div>
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current} />
            <FullPageLoader 
                show={loading} />
        </div>
    )
}

export default PrizePacksForm