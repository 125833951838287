import React from 'react';
import './prize-pack-count.styles.scss';
import QuantityInput from '@/components/PLAYER/store/store-components/quantity-input.component';

const PrizePackCount = ({
    formData,
    saveData
}) => {
    return (
        <div className='manage-content-pp-count g-list-item form-section'>
            <h3>How many items should be awarded in this Prize Pack?<span className='required'>*</span></h3>
            <hr />
            <div className="pp-count-content">
                <div className='field small'>
                    <input 
                        type='number' 
                        className='short-input' 
                        placeholder='Pack Item Count' 
                        value={formData.opt_itemCount ?? ''} 
                        onChange={(e) => saveData(
                            'opt_itemCount', 
                            (Number(e.target.value) < 0)
                            ? 0 : Number(e.target.value)
                        )} />
                </div>
                <div className='g-space-1'></div>
            </div>
        </div>
    )
}

export default PrizePackCount