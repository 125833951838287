import React, { useState, useEffect } from 'react'
import './modal.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import linkifyHtml from 'linkify-html';

const Modal = ({ cancel, show, children, text, closeButton, cls, cls2, backgroundClose, buttonRow }) => {
	const showHideClassName = show ? "modal display-block" : "modal display-none";
	
	useEffect(() => {
		// console.log(closeButton)
	},[])

	useEffect(() => {
        // console.log(show)
		if (show) {
		    document.body.style.overflow = 'hidden'; 
		} else {
			document.body.style.overflow = 'unset';
		}
	},[show]) 

    function closeModal() {
        if (backgroundClose) {
            cancel();
        }
    }

    
  	
	return (
    	<div className={showHideClassName} onClick={() => closeModal()}>
            <section className={`modal-main ${cls ?? ''}`}>
                <div className='close-button-div'>
                    <h3>{text ?? ''}</h3>
                    {
                        (closeButton) &&
                        <FontAwesomeIcon className='close-button' icon={faXmark} size='lg' onClick={cancel} />
                    }
                </div>
                {
                    (text || closeButton) &&
                    <hr />
                }
                {children}
            </section>
    	</div>
  	);
}

export default Modal

// export const useAlertConfirmation = () => {
//     const [ showAlert, setShowAlert ] = useState(true);

//     Alert
// }


export const ModalConfirm = ({ show, cancel, onApprove, message, noCancel, buttonText, buttonClass }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    function approve() {
        cancel();
        onApprove();
    }

    return (
        <div className={showHideClassName} >
      		
            <section className={`modal-main alert`}>
                <div className={`modal-children`}>
                    <div dangerouslySetInnerHTML={{__html: message && linkifyHtml(message, {'target': '_blank'})}} />
                </div>
                <div className="g-space-20"></div>
                <div className='alert-buttons'>
                    {
                        (!noCancel) &&
                        <button className='g-button med-btn' onClick={() => cancel()}>Cancel</button>
                    }
                    <button className={`g-button med-btn ${buttonClass ?? ''}`} onClick={() => approve()}>{buttonText ?? 'OK'}</button>
                </div>
            </section>
        </div>
    )
}

export const ModalAlert = ({ show, cancel, message }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName} >
      		
            <section className={`modal-main alert`}>
                <div className={`modal-children`}>
                    <div dangerouslySetInnerHTML={{__html: message && linkifyHtml(message, {'target': '_blank'})}} />
                </div>
                <div className="g-space-20"></div>
                <div className='alert-buttons'>
                    <button className='g-button med-btn' onClick={() => cancel()}>Ok</button>
                </div>
            </section>
        </div>
    )
}