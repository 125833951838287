import React from 'react';
import './prize-pack-items.styles.scss';
import ItemSelector from './item-selector.component';

const ManageContentPrizePackItems = ({
    gameData,
    formData,
    saveData,
    gameElements
}) => {
    
    return (
        <div className='manage-content-pp-items g-list-item form-section'>
            <h3>Choose Items for this Prize Pack</h3>
            <hr />
            <div className="pp-items-content">
                <ItemSelector
                    type='prizePacks'
                    formData={formData}
                    saveData={saveData}
                    gameElements={gameElements}
                    gameData={gameData}
                    inst='Choose items to add as potential prizes in this Prize Pack. Once you choose, you can change the commonality of each item below.' 
                    saveKey='opt_prizes'  />
                <div className='g-space-2'></div>
                
            </div>
        </div>
    )
}

export default ManageContentPrizePackItems