import React, { useRef, useState } from 'react';
import './reset-data.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate } from 'react-router-dom';
import { ModalConfirm } from '@/components/modal/modal.component';
import { UseCloudFunction } from '@/utils/firebase.utils';

const ResetData = ({ gameData }) => {

    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const approveRef = useRef();
    const navigate = useNavigate();

    function resetData(e) {
        setConfirmMessage(`Are you sure you want to reset <b>${
            e == 'all' ?
            'ALL GAME DATA INCLUDING GAME ELEMENTS AND PLAYERS' :
            '' 
        }</b>? This action cannot be undone.`);
        approveRef.current = () => {
            continueReset();
        }

        async function continueReset() {
            console.log(e);
            const res = await UseCloudFunction(
                'resetData',
                {
                    'dataToReset': e,
                    'gameId': gameData.gameId,
                }
            )
            console.log(res);
        }
    }

    return (
        <div className='reset-data-container'>
            <div className='reset-data-content g-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className="g-space-1"></div>
                <div className='card-title'>Reset Game Data</div>
                <hr />
                <div className='reset-buttons'>
                    <div className='reset-section g-list-item'>
                        <h3>Full Game Reset</h3>
                        <p>You can reset the game to its initial state. 
                            This will remove all of the Game Elements, Players, 
                            and game data. This action cannot be undone.</p>
                        <div className='buttons'>
                            <button
                                className='g-button disabled'
                                onClick={() => resetData('all')}>
                                Reset All Game Data
                            </button>
                        </div>
                        <div className='g-space-1'></div>
                    </div>
                </div>
            </div>
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current} />
        </div>
    )
}

export default ResetData