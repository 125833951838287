import React, { useEffect, useLayoutEffect, useState } from 'react';
import './inventory-landing.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubesStacked, faDice, faFilter, faGlobeAmericas, faPiggyBank, faTicket } from '@fortawesome/free-solid-svg-icons';
import ItemMiniCard from './item-mini-card.component';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import CategoryFilterModal from '@/components/MANAGE-GAME/create-elements/components/category-filter-modal.component';
import { RandomString } from '@/utils/randomString';
import ItemDisplayCard from './item-display-card.component';
import { Route, Routes, useLocation } from 'react-router-dom';
import ItemHistory from './item-history.component';

const InventoryLanding = ({ 
    gameData,
    playerData,
    gameElements,
    playerActivity,
    gameStats,
    teamMembers,
    myTeams
 }) => {

    const [ itemType, setItemType ] = useState(null);
    const [ categoryList, setCategoryList ] = useState(null);
    const [ filterCategory, setFilterCategory ] = useState([]);
    const [ itemsToDisplay, setItemsToDisplay ] = useState(null);
    const [ showCategoryModal, setShowCategoryModal ] = useState(false);
    const [ selectedItem, setSelectedItem ] = useState(null);
    const [ searchText, setSearchText ] = useState(null);
    const [ counts, setCounts ] = useState({});
    const [ collections, setCollections ] = useState([]);
    const [ initialTypeIsSet, setInitialTypeIsSet ] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
            pageTitle.innerText = 'My Inventory';
        }   
    }, [])

    useEffect(() => {
        if (!gameElements || initialTypeIsSet) return;
        const itemTypeArr = [
            'inventory',
            'realworld',
            'collectible',
            'lottery',
            'prizepack'
        ];
        for (let type of itemTypeArr) {
            let availItems = Object.values(gameElements)
            .filter(i =>
                i.type === 'item' &&
                i.opt_visible
            )
            const typeCount = availItems.filter(i => i.opt_itemType === type).length
            if (typeCount > 0) {
                setItemType(type);
                setInitialTypeIsSet(true);
                return;
            }
        }
    }, [gameElements])

    useEffect(() => {
        if (!playerData || !gameElements) return;
        getItems();
        let availItems = Object.values(gameElements)
        .filter(i =>
            i.type === 'item' &&
            i.opt_visible
        )
        // create object of available item counts to decide whether or not to display the button 
        let countObj = {
            'inventory': availItems.filter(i => i.opt_itemType === 'inventory').length,
            'realworld': availItems.filter(i => i.opt_itemType === 'realworld').length,
            'collectible': availItems.filter(i => i.opt_itemType === 'collectible').length,
            'lottery': availItems.filter(i => i.opt_itemType === 'lottery').length,
            'contribution': availItems.filter(i => i.opt_itemType === 'contribution').length,
            'prizepack': availItems.filter(i => i.opt_itemType === 'prizepack').length
        }
        
        setCounts(countObj);
        // create array of collections
        let colls = Object.values(gameElements)
        .filter(e => e.type === 'collection')
        .sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        setCollections([...colls]);
    }, [playerData, gameElements, itemType])

    useEffect(() => {
        if (!filterCategory || !itemsToDisplay) return;
        search(searchText);
    }, [filterCategory])

    function getItems() {
        let categoryArr = [];
        // create array of current items to display
        const arr = Object.values(gameElements)
        .filter(el => 
            el.type === 'item' && 
            el.opt_itemType === itemType &&
            el.opt_visible
        )
        // create category list
        for (let el of arr) {
            if (el.opt_cat && !categoryArr.includes(el.opt_cat)) {
                categoryArr.push(el.opt_cat);
            }
        }
        setCategoryList(categoryArr);
        // console.log(arr);
        sortItems(arr);
        if (searchText) {
            search(searchText);
        }
    }

    function sortItems(arr) {
        let elArr = [];
        let activeElArr = [];
        for (let el of arr) {
            let tempEl = {...el};
            if (playerData.items && playerData.items[tempEl.id]) {
                tempEl.count = playerData.items[tempEl.id].count;
                activeElArr.push(tempEl)
            } else if (tempEl.opt_visible && tempEl.opt_preview) {
                elArr.push(tempEl);
            } else if (tempEl.opt_visible) {
                elArr.push({
                    'id': tempEl.id,
                    'name': 'Mystery Item',
                    'icon': '/images/icons/mysterytreasure.png'
                })
            }
        }
        let count = 16
        if (itemType === 'collectible') count = 0;
        while ((elArr.length + activeElArr.length) < count) {
            elArr.push({name: 'zzzblankzzz', id: RandomString(12)});
        }
        const combinedItems = [
            ...activeElArr.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1), 
            ...elArr.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
        ]
        // console.log(combinedItems)
        setItemsToDisplay(combinedItems);
    }

    function search(text) {
        setSearchText(text);
        let arr = Object.values(gameElements)
            .filter(el => 
                el.type === 'item' && 
                el.opt_itemType === itemType &&
                (filterCategory.length > 0 ? filterCategory.includes(el.opt_cat) : true) && 
                (text 
                    ? (
                        el.name.toLowerCase().includes(text) || 
                        el.desc.toLowerCase().includes(text)
                    ) 
                    : true
                ) 
            )
        sortItems(arr);
    }

    function chooseCategoryFilter(cat) {
        let catArr = [...filterCategory];
        if (catArr.includes(cat)) {
            catArr.splice(catArr.indexOf(cat), 1)
        } else {
            catArr.push(cat);
        }
        setFilterCategory([...catArr]);
    }

    return (
        <div className='inventory-landing'>
            <Routes>
                <Route path='' element={
                    <div className='inventory-content g-card'>
                        <div className='player-title'>
                            <ImageContainer src='/images/icons/backpack.png' alt='rewards icon' className='player-title-icon' />
                            {
                                playerData && 
                                location.pathname.includes('/manage/') ? 
                                `${playerData.fName}${playerData.lName ? ` ${playerData.lName}` : ''}'s` : 
                                'My'
                            } Inventory
                        </div>
                        <hr />
                        <div className='category-buttons'>
                            {
                                (counts.inventory > 0) &&
                                <button 
                                    className={`g-button med-btn ${itemType === 'inventory' ? 'primary' : ''}`}
                                    onClick={() => setItemType('inventory')}
                                >
                                    <FontAwesomeIcon icon={faDice} />
                                    Game Items
                                </button>
                            }
                            {
                                (counts.realworld > 0) &&
                                <button 
                                    className={`g-button med-btn ${itemType === 'realworld' ? 'primary' : ''}`}
                                    onClick={() => setItemType('realworld')}
                                >
                                    <FontAwesomeIcon icon={faGlobeAmericas} />
                                    Real Items
                                </button>
                            }
                            {
                                (counts.collectible > 0) &&
                                <button 
                                    className={`g-button med-btn ${itemType === 'collectible' ? 'primary' : ''}`}
                                    onClick={() => setItemType('collectible')}
                                >
                                    <FontAwesomeIcon icon={faCubesStacked} />
                                    Collectibles
                                </button>
                            }
                            {
                                (counts.lottery > 0) &&
                                <button 
                                    className={`g-button med-btn ${itemType === 'lottery' ? 'primary' : ''}`}
                                    onClick={() => setItemType('lottery')}
                                >
                                    <FontAwesomeIcon icon={faTicket} />
                                    Lottery Tickets
                                </button>
                            }
                            {
                                (counts.contribution > 0) &&
                                <button 
                                    className={`g-button med-btn ${itemType === 'contribution' ? 'primary' : ''}`}
                                    onClick={() => setItemType('contribution')}
                                >
                                    <FontAwesomeIcon icon={faPiggyBank} />
                                    Contributions
                                </button>
                            }
                        </div>
                        <div className="g-space-1"></div>
                        <div className='inventory-grid'>
                            <div className='left-col'>
                                <div className='search-div'>
                                    <SearchBar search={search} />
                                    <button 
                                        className='g-button med-btn'
                                        onClick={() => setShowCategoryModal(true)}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faFilter}
                                            className={`filter-icon ${filterCategory.length > 0 ? 'active' : ''}`}
                                        />
                                        Categories
                                    </button> 
                                </div>
                                <div className="g-space-1"></div>
                                <div className='inventory-blocks'>
                                {
                                    (itemType === 'collectible')
                                    ?
                                    <div className='collection-grid'>
                                        {
                                            (collections.length > 0) ?
                                            collections.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                            .map(c => (
                                                <div key={c.id} className='collection-title'>
                                                    <div className='card-title'>
                                                        <ImageContainer 
                                                            src={c.icon}
                                                            className='head-icon' 
                                                        />
                                                        {c.name}
                                                    </div>
                                                    <hr />
                                                    <div className='block-grid'>
                                                        {
                                                            // Object.values(itemsToDisplay)
                                                            (
                                                                c.opt_itemList && 
                                                                c.opt_itemList.length > 0
                                                            )
                                                            ?
                                                            c.opt_itemList
                                                            .map(itemId => {
                                                                // console.log(itemsToDisplay);
                                                                if (
                                                                    itemsToDisplay && 
                                                                    itemsToDisplay.length > 0) {
                                                                    const item = itemsToDisplay.filter(i => i.id === itemId)[0];
                                                                    if (!item) return;
                                                                    return (
                                                                        <div key={item.id}>
                                                                            <ItemMiniCard 
                                                                                item={item}
                                                                                selectedItem={selectedItem}
                                                                                setSelectedItem={setSelectedItem}
                                                                            />
                                                                        </div>
                                                                    )
                                                                } 
                                                            })
                                                            :
                                                            Object.values(itemsToDisplay)
                                                            .map(item => (
                                                                <div key={item.id} className='blank-item-box'></div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="g-space-1"></div>
                                                </div>
                                            ))
                                            :
                                            <div className='center-col'>
                                                <h4>No Collections yet...</h4>
                                            </div>
                                        }
                                    </div>
                                    :    
                                    <div className='block-grid'>
                                        {
                                            (itemsToDisplay) &&
                                            itemsToDisplay
                                            .map(item => {
                                                if (item.name === 'zzzblankzzz') {
                                                    return (
                                                        <div key={item.id} className='blank-item-box'></div>
                                                    )
                                                } else {
                                                    return (
                                                        <div key={item.id}>
                                                            <ItemMiniCard 
                                                                item={item}
                                                                selectedItem={selectedItem}
                                                                setSelectedItem={setSelectedItem} 
                                                            />
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                }
                                </div>
                            </div>
                            <div className='inventory-display'>
                                <ItemDisplayCard 
                                    item={selectedItem}
                                    gameData={gameData} 
                                    gameStats={gameStats}
                                    playerData={playerData}
                                />
                            </div>
                        </div>
                    </div>
                    }
                ></Route>
                <Route path='history/:itemPath' element={
                    <ItemHistory
                        gameData={gameData}
                        playerData={playerData}
                        playerActivity={playerActivity}
                        gameElements={gameElements} 
                    />
                }></Route>
            </Routes>    
            <CategoryFilterModal
                show={showCategoryModal}
                cancel={() => setShowCategoryModal(false)}
                chooseCategory={chooseCategoryFilter}
                categoryList={categoryList}
                selectedCategories={filterCategory}
            />
        </div>
    )
}

export default InventoryLanding;