import React, { useEffect, useRef, useState } from 'react'
import './secret-codes-upload.styles.scss';
import { useNavigate } from 'react-router-dom';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { useSelector } from 'react-redux';
import { usePapaParse } from 'react-papaparse';
import { membershipRestrictions } from '@/utils/membershipRestrictions';
import { RandomString } from '@/utils/randomString';
import UploadItemPreview from '../items/upload-item-preview.component';
import UploadCodePreview from './upload-code-preview.component';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import FullPageLoader from '@/components/loader/full-page-loader.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';

const SecretCodesUpload = ({ 
    gameData,
    gameElements 
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const membership = useSelector(state => state.userData.membership);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ arrToUpload, setArrToUpload ] = useState([]);
    const [ availableSpots, setAvailableSpots ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const fileReader = new FileReader();
    const { readString } = usePapaParse();
    const approveRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        if (!membership || !membershipRestrictions) return;
        const num = membershipRestrictions[membership.membership].elementCount;
        if (num === Infinity) {
            setAvailableSpots(num);
            return;    
        }
        setAvailableSpots(num - gameData.createdElementTotal);
    }, [membership, membershipRestrictions])

    function clickUpload() {
        document.getElementById('fileUpload').value = null;
        document.getElementById('fileUpload').click();
    }

    async function handleUpload(file) {
        const ext = file.name.match(/\.([^\.]+)$/)[1].toLowerCase();
        console.log(ext);
        const allowed = ['csv']
        if (!allowed.includes(ext)) {
            setAlertMessage('That file type is not allowed to be uploaded. Try a different file.');
            setArrToUpload([]);
            document.getElementById('fileUpload').value = null;
            return;
        }
        const csvOutput = await readFileAsync(file)
		// console.log(csvOutput)
		const arr = await getParsedData(csvOutput)
        console.log(arr);
		const objArr = createObjs(arr)
        console.log(objArr);
        setArrToUpload(objArr);
    }

    function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            fileReader.onload = (event) => {
                resolve(event.target.result);	
            };
            fileReader.readAsText(file);
        })
    }

    function getParsedData(arr) {
		return new Promise((resolve, reject) => {
			readString(arr, {
		      	worker: true,
		      	complete: (results) => {
			        // console.log('---------------------------');
			        // console.log(results.data);
			        // console.log('---------------------------');
					resolve(results.data)
			  	},
		    });
		})
	}

    function createObjs(arr) {
        let duplicateArr = [];
        const template = [
            "Code",
            "Description",
            "Category",
            "Prerequisite Points",
            "Minimum Level",
            "Prerequisite Badge(s)",
            "Prerequisite Item(s)",
            "Team Requirement",
            "Minimum XP Earned",
            "Maximum XP Earned",
            "Minimum Currency Earned",
            "Maximum Currency Earned",
            "Badge(s) Earned",
            "Item(s) Earned",
            "Prize Pack(s) Earned",
            "Available Date",
            "Unavailable Date"
        ]
		const headers = arr[0]
        console.log(template);
        console.log(headers);
        let validCSV = true;
        for (let i=0; i<template.length; i++) {
            if (template[i] !== headers[i]) {
                console.log(template[i], headers[i])
                validCSV = false;
                break;
            }
        }
		if (!validCSV) {
            setAlertMessage('Please use the correct template when uploading a list of Secret Codes.');
            return false;
        }
        let objArr = [];
        let startingPoint = 1;
        if (arr[1][0].includes('required')) {
            startingPoint = 2;
        }
        const keyList = [
            "name",
            "desc",
            "opt_cat",
            "opt_prereqPoints",
            "opt_prereqLevel",
            "opt_prereqBadges",
            "opt_prereqItems",
            "opt_prereqTeams",
            "opt_earnedPointsMin",
            "opt_earnedPointsMax",
            "opt_earnedCurrencyMin",
            "opt_earnedCurrencyMax",
            "opt_earnedBadges",
            "opt_earnedItems",
            "opt_earnedPrizePacks",
            "opt_dateOpen",
            "opt_dateClose"
        ]
        const now = new Date().getTime();
        console.log(arr);
        const gameCode = gameData.gameId.substring(gameData.gameId.indexOf('-')-5, gameData.gameId.indexOf('-')+6);
        let len = 200;
        if (availableSpots > 200 && arr.length <= availableSpots) {
            len = arr.length
        } else if (arr.length > availableSpots) {
            len = availableSpots
        }
        console.log(len);
		for (let a=startingPoint; a<len; a++) {
            const row = arr[a];
            let obj = {};
            obj.code = row[0].toUpperCase().replace(/\s/gi,'');
            if (
                Object.values(gameElements)
                .filter(e => e.code === obj.code).length > 0
            ) {
                duplicateArr.push(row[0]);
                continue;
            }
			
			for (let r=0; r<row.length; r++) {
				if (row[r] && (r === 3 || r === 8 || r === 9 || r === 10 || r === 11 || r === 15 || r === 16)) {
                    obj[keyList[r]] = Number(row[r])
                } else if (row[r] && (r === 17 || r === 18)) {
                    obj[keyList[r]] = new Date(row[r]).getTime();
                } else if (row[r] && (r === 5 || r === 6 || r === 12 || r === 13 || r === 14)) {
                    const val = row[r].replace(/\s/g, '').split(',');

                    // go through item list to get id's instead of paths
                    // go through item list to get id's instead of paths
                    if (r === 13 || r ===14) {
                        let newObj = {};
                        for (let v of val) {
                            const el = Object.values(gameElements).filter(e => e.path === v)[0];
                            if (el) {
                                newObj[el.id] = {
                                    'id': el.id,
                                    'quantity': 1
                                }
                            }
                        }
                        obj[keyList[r]] = newObj;
                    } else {    
                        let newArr = [];
                        for (let v of val) {
                            const el = Object.values(gameElements).filter(e => e.path === v)[0];
                            if (el) {
                                newArr.push(el.id);
                            } else {
                                newArr.push(v);
                            }
                        }
                        obj[keyList[r]] = newArr;
                    }
                } else if (row[r] && r === 7) {
                    obj[keyList[r] ]= row[r].replace(/\s/g, '').split(',');
                } else if (row[r]) {
                    obj[keyList[r]] = row[r].trim()
                }
			}
            obj.appId = userProfile.appId;
            obj.ts_created = now + a;
            obj.ts = now + a;
            obj.gameId = gameData.gameId;
            obj.icon = "/images/icons/secret.png";
            obj.id = obj.ts + '-' + gameCode + '-secretCode';
            obj.status = 'active';
            obj.type = 'secretCode';
            obj.opt_visible = true;
            obj.path = RandomString(18, 'abcdefghijklmnopqrstuvwxyz0123456789');
            obj.opt_available = true;
			objArr.push(obj)
		}
        if (duplicateArr.length > 0) {
            setAlertMessage(`The following codes were not added because they already exist:<ul>${duplicateArr.map(d => {return (`<li>${d}</li>`)}).join(' ')}</ul>`);
        }
        return objArr;
	}

    function removeCode(code) {
        setConfirmMessage(`Are you sure you want to remove <b>${code.name}</b> from the upload list? This action cannot be undone.`);
        approveRef.current = () => {
            let tempArr = [...arrToUpload]
            setArrToUpload([...tempArr.filter(i => i.id !== code.id)])
        }    
    }

    function cancelUpload() {
        setArrToUpload([]);
        document.getElementById('fileUpload').value = null;
        navigate(-1);
    }

    function proceedWithUpload() {
        setConfirmMessage(`Are you sure you are ready to upload ${arrToUpload.length} Secret Codes?`);
        async function uploadList() {
            setLoading(true);
            const res = await UseCloudFunction(
                'uploadElements', 
                {
                    'arr': arrToUpload, 
                    'appId': userProfile.appId, 
                    'gameId': gameData.gameId, 
                    'userName': `${userProfile.fName} ${userProfile.lName}`
                }
            )
            if (res.error) {
                setAlertMessage('Something went wrong. Please try again later. Details: ' + res.error);
                return;
            }
            setLoading(false);
            cancelUpload();
        }
        approveRef.current = () => {
            uploadList();
        }
    }

    return (
        <div className='secret-codes-upload'>
            <div className='g-card secret-codes-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className="g-space-1"></div>
                <div className='card-title'>
                    <ImageContainer src='/images/icons/secret2.png' alt='secret codes icon' className='head-icon' />
                    Upload a List of Secret Codes
                </div>
                <hr />
                <p><b>Available Spots: {availableSpots === Infinity ? 'Unlimited' : availableSpots}</b></p>
                <p>You can upload a list of Secret Codes for convenience. 
                    The only part of each Secret Code that cannot be created during this process is the icon. 
                    You will have to edit each Code after it has been uploaded to give it an icon. A maximum of 200 elements can be uploaded at once.</p>
                <p>To upload a list of Secret Codes, make a copy of <b><a href='https://docs.google.com/spreadsheets/d/1rWig0E6sjmLk5qilY3Fs4PyEmgsbPb86VE2XhyJz3gA/copy' target="_blank">THIS GOOGLE SHEET</a></b> and export it as a CSV or download <b><a href={`${import.meta.env.VITE_ROOT}/images/files/GamablySecretCodeUploadTemplate.csv`} target='_blank' download>THIS CSV</a></b>. Then, upload the completed roster here.</p>
                <div className="g-space-1"></div>
                <div style={{cursor: 'pointer'}}>
                    <input type="file" id="fileUpload" accept=".csv" onChange={(e) => handleUpload(e.target.files[0])}  />
                    <button className="g-button" type="button" tabIndex="-1" onClick={() => clickUpload()}>Upload a List of Secret Codes</button>
                </div>
                <div className="g-space-1"></div>
                {
                    (arrToUpload.length > 0) &&
                    <div>
                        <hr />
                        <p><b>Secret Codes to be Created</b></p>
                        <div className='upload-list'>
                        {
                            arrToUpload.map(c => (
                                <div key={c.id}>
                                    <UploadCodePreview
                                        code={c}
                                        removeCode={removeCode} 
                                        gameElements={gameElements}
                                    />
                                </div>
                            ))
                        }
                        </div>
                        <div className='g-space-1'></div>
                        {
                            (loading)
                            ?
                            <div className='buttons'>
                                <button type='button' className='g-button'>Cancel</button>
                                <button type='button' className='g-button' ><ThreeDotsLoader /></button>
                            </div>
                            :
                            <div className='buttons'>
                                <button type='button' className='g-button' onClick={() => cancelUpload()}>Cancel</button>
                                <button type='button' className='g-button primary' onClick={() => proceedWithUpload()}>Create These {arrToUpload.length} Codes</button>
                            </div>
                        }
                        <div className='g-space-1'></div>
                    </div>
                }
            </div>
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current} />
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default SecretCodesUpload