import React, { useState } from 'react'
import { faRocket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component'
import { UseCloudFunction } from '@/utils/firebase.utils'
import { ModalAlert } from '@/components/modal/modal.component'
import { useNavigate } from 'react-router-dom'
import { createAlias } from '@/utils/aliases'
import { RandomString } from '@/utils/randomString'

const JoinGameFunction = ({ gameData }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const enrolledList = useSelector(state => state.contentData.enrolledList);
    const [ loading, setLoading ] = useState(false);
    const [ notApproved, setNotApproved ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const navigate = useNavigate();

    async function joinGame() {
        setLoading(true);
        console.log(gameData)

        // check to see if already enrolled

        if (enrolledList && enrolledList[gameData.gameId]) {
            navigate(`/play/${gameData.path}`);
            return;
        }
        
        // check if enroll_email is true and enroll_limitList exists - if so, check if they have access

        if (gameData.enroll_email && gameData.enroll_limitList) {
            let approved = false;
            for(let l of gameData.enroll_limitList) {
                if (userProfile.email.includes(l)) {
                    approved = true;
                }
            }
            if (!approved) {
                setNotApproved(true);
                setLoading(false);
                return;
            }
        }

        const now = new Date().getTime();
        const playerObj = {
            'alias': createAlias(),
            'appId': userProfile.appId,
            'displayName': `${userProfile.fName}${userProfile.lName ? ` ${userProfile.lName[0]}` : ''}`,
            'fName': userProfile.fName,
            'lName': userProfile.lName,
            'info': '',
            'icon': userProfile.icon,
            'path': RandomString(18),
            'gradient': userProfile.gradient,
            'playerId': `${gameData.gameId}_${userProfile.appId}`,
            'points': 0,
            'currencyTotal': 0,
            'currency': 0,
            'status': 'active',
            'gameId': gameData.gameId,
            'ts': now,
            'ts_joined': now,
            'enroll_method': 'email',
        }

        const res = await UseCloudFunction('joinGame', {'playerObj': playerObj, 'email': userProfile.email})
        console.log(res);
        if (res.error) {
            setAlertMessage(`Something went wrong. Please try again later. (Error: ${res.error})`);
            return;
        }
        if (res.denied) {
            setAlertMessage(`Sorry, you do not have access to this game. If you think this is an error, please contact the game manager.`);
            return;
        }
        navigate(`/play/${gameData.path}`)

    }

    return (
        <div>
            {
                (loading) 
                ?
                <button className='g-button huge-btn'><ThreeDotsLoader /></button>
                :
                (notApproved) 
                ?
                <div className='center'><h3>Sorry, you do not have access to this game. Please contact the game creator if you think this is in error.</h3></div>
                :
                <button className='g-button huge-btn' onClick={() => joinGame()} >Join This Game! <FontAwesomeIcon icon={faRocket} className='rocket' /></button>
            }
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
        </div>
    )
}

export default JoinGameFunction