import React, { useEffect, useState } from 'react'
import './org-invites.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Modal from '@/components/modal/modal.component';
import { properCase } from '@/utils/properCase';
import { format } from 'date-fns';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import FullPageLoader from '@/components/loader/full-page-loader.component';
import OrgInviteModal from '@/components/SHARED/org-invite-modal/org-invite-modal.component';

const OrgInvites = ({ orgData, invites }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ showInviteModal, setShowInviteModal ] = useState(false);
    const [ invitesToDisplay, setInvitesToDisplay ] = useState(null);
    const [ activeToDisplay, setActiveToDisplay ] = useState(null);

    useEffect(() => {
        console.log(invites);
        if (!invites) return;
        const sortedPendingInvites = Object.values(invites)
        .filter(i => i.status !== 'accepted')
        .sort((a,b) => a.email > b.email ? 1 : -1)
        setInvitesToDisplay([...sortedPendingInvites]);
        const sortedActiveInvites = Object.values(invites)
        .filter(i => i.status === 'accepted')
        .sort((a,b) => a.email > b.email ? 1 : -1)
        setActiveToDisplay([...sortedActiveInvites]);
    }, [invites])

    

    return (
        <div className='org-invites'>
            <div className='with-icon'>
                <h3>Invited Users</h3>
                <button 
                    className='g-button med-btn'
                    onClick={() => setShowInviteModal(true)}
                >
                    <FontAwesomeIcon icon={faPlusCircle} />
                </button>
            </div>
            <div className='meta'>Member Count: {orgData.memberCount}/{orgData.memberMax}</div>
            <hr />
            <h4>Active Members</h4>
            <div className='invite-list'>
            {
                (activeToDisplay) &&
                activeToDisplay.map((i, index) => (
                    <div key={i.id} className='g-list-item invite-list-item'>
                        <div>{index+1}</div>
                        <div>{i.email}</div>
                        <div className='meta'>{properCase(i.status)}</div>
                        <div className='meta'>{format(new Date(i.ts_created), "P")}</div>
                        <div className='meta'>{i.ts_accepted ? format(new Date(i.ts_accepted), "P") : ''}</div>
                    </div>
                ))
            }
            </div>
            <div className='g-space-1'></div>
            <h4>Pending/Declined Invites</h4>
            <div className='invite-list'>
            {
                (invitesToDisplay) &&
                invitesToDisplay.map((i, index) => (
                    <div key={i.id} className='g-list-item invite-list-item'>
                        <div>{index+1}</div>
                        <div>{i.email}</div>
                        <div className='meta'>{properCase(i.status)}</div>
                        <div className='meta'>{format(new Date(i.ts_created), "P")}</div>
                        <div className='meta'>{i.ts_accepted ? format(new Date(i.ts_accepted), "P") : ''}</div>
                    </div>
                ))
            }
            </div>
            <OrgInviteModal
                show={showInviteModal}
                cancel={() => setShowInviteModal(false)}
                orgData={orgData}
                invites={invites} />
        </div>
    )
}

export default OrgInvites