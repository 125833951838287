import React, { useEffect, useState } from 'react'
import './accepted-item-modal.styles.scss';
import { useNavigate } from 'react-router-dom';
import Modal from '@/components/modal/modal.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { UseCloudDistribute } from '@/utils/firebase.utils';

const AcceptedItemModal = ({ 
    gameData, 
    playerData, 
    playerActivity, 
    gameElements 
}) => {
    
    const [ element, setElement ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ activityId, setActivityId ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!playerData || !gameElements || !playerActivity) return;
        if (playerData.newStuff && playerData.newStuff.length > 0) {
            for (let actId of playerData.newStuff) {
                if (actId.includes('approvedItem') && playerActivity[actId]) {
                    console.log(gameElements[playerActivity[actId].elementId]);
                    setElement(gameElements[playerActivity[actId].elementId]);
                    setLoading(false);
                    setActivityId(actId);
                    break;
                }
            }
        }
    }, [playerData, gameElements, playerActivity])

    async function handleOk() {
        const data = {
            'gameId': gameData.gameId,
            'playerId': playerData.playerId,
            'rewardId': activityId
        }
        UseCloudDistribute('markRewardAsViewed', data);
        setElement(null);
    }

    return (
        <div>
            <Modal show={element} cancel={() => setElement(null)} cls='narrow' text='Accepted Item'>
                <div className='modal-children'> 
                    {
                        (element) &&
                        <div className='accepted-challenge-modal'>
                            <ImageContainer 
                                src={element.icon} 
                                alt={`${element.name} icon`} 
                                className='treasure-gif' 
                            />
                            <p>{element.name}</p>
                            <h2>An item you submitted has been ACCEPTED!</h2>
                            
                            <hr />
                            <div className="buttons">
                                {
                                    (loading)
                                    ?
                                    <button className="g-button"><ThreeDotsLoader /></button>
                                    :
                                    <button className="g-button" onClick={() => handleOk()}>
                                        Ok
                                    </button>
                                }
                            </div>
                        </div>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default AcceptedItemModal