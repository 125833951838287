import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './org-list-container.styles.scss'
import OrgList from './org-list.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Route, Routes, useNavigate } from 'react-router-dom'
import CreateEditOrg from './create-org/create-edit-org.component'
import { getOrgsFromDb } from '@/utils/getAdminData'
import OrgView from './org-view.component'

const OrgListContainer = () => {

    const [ orgList, setOrgList ] = useState(null);
    let orgListener;
    const orgListRef = useRef();
    const navigate = useNavigate();
    
    useEffect(() => {
        getOrgs(); 
        return () => {
            if (orgListener) {
                console.log('detaching org listener');
                orgListener();
            }
        }
    }, [])

    async function getOrgs() {
        console.log('getting orgs');
        getOrgsFromDb({
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data) {
            console.log(data);
            orgListRef.current = {...orgListRef.current, ...{[data.orgId]: data}}
            setOrgList(orgListRef.current);
        }
        function handleListener(unsub) {
            orgListener = unsub;    
        }
    } 

    return (
        <div className='org-list-container'>
            <Routes>
                <Route path=''
                    element={         
                        <div className='manage-org-list'>       
                            <div className='section-title with-icon'>
                                <span>Manage Organizations</span>
                                <FontAwesomeIcon className='icon' title="Create New Organization" icon={faPlusCircle} onClick={() => navigate('create')} />
                            </div>
                            <hr />
                            <OrgList orgList={orgList} />
                        </div>
                    }></Route>
                <Route path='create'
                    element={
                        <div className='edit-container'>
                            <CreateEditOrg orgList={orgList} />
                        </div>
                    }></Route>
                <Route path=':orgPath'
                    element={
                        <OrgView orgList={orgList} />
                    }></Route>
            </Routes>
        </div>
    )
}

export default OrgListContainer