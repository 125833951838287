import React, { useEffect, useRef, useState } from 'react'
import './rewards-form.styles.scss';
import gem from '@/assets/icons/xp.png'
import coin from '@/assets/icons/coin.png'
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import ItemSelector from './item-selector.component';

const RewardsForm = ({ 
    type, 
    formData, 
    saveData, 
    gameElements, 
    disabled, 
    playerId, 
    gameData, 
    teams, 
    review 
}) => {

    const [ badges, setBadges ] = useState([]);
    const [ selectedBadges, setSelectedBadges ] = useState([]);
    const [ showBadges, setShowBadges ] = useState(false);
    const [ badgesToDisplay, setBadgesToDisplay ] = useState(null);
    const badgesRef = useRef();
    const [ items, setItems ] = useState([]);
    const [ selectedItems, setSelectedItems ] = useState([]);
    const [ showItems, setShowItems ] = useState(false);
    const itemsRef = useRef();
    const [ prizePacks, setPrizePacks ] = useState([]);
    const [ selectedPrizePacks, setSelectedPrizePacks ] = useState([]);
    const [ showPrizePacks, setShowPrizePacks ] = useState(false);
    const prizePacksRef = useRef();

    useEffect(() => {
        if (!review) return;
        setShowBadges(true);
        setShowItems(true);
        setShowPrizePacks(true);
    }, [review])

    useEffect(() => {
        if (!formData) {return;}
        if (formData.opt_earnedBadges && formData.opt_earnedBadges.length > 0) {
            badgesRef.current = [...formData.opt_earnedBadges]
            setSelectedBadges(badgesRef.current);
            setShowBadges(true);
        }
        if (formData.opt_earnedItems && Object.values(formData.opt_earnedItems).length > 0) {
            itemsRef.current = {...formData.opt_earnedItems}
            setSelectedItems(itemsRef.current);
            setShowItems(true);
        }
        if (formData.opt_earnedPrizePacks && Object.values(formData.opt_earnedPrizePacks).length > 0) {
            prizePacksRef.current = {...formData.opt_earnedPrizePacks}
            setSelectedPrizePacks(prizePacksRef.current);
            setShowPrizePacks(true);
        }
    }, [formData])

    useEffect(() => {
        if (!gameElements) {return;}
        if (teams) {
            setBadges([
                ...Object.values(gameElements)
                .filter(c => 
                    c.type === 'badge' && 
                    c.status === 'active' && 
                    c.opt_purposeGroup
                )
            ])    
        } else {
            setBadges([
                ...Object.values(gameElements)
                .filter(c => 
                    c.type === 'badge' && 
                    c.status === 'active' && 
                    !c.opt_purposeGroup
                )
            ])
        }
        setItems(Object.values(gameElements).filter(c => 
            c.type === 'item' && 
            c.status === 'active' && 
            (
                c.opt_itemType === 'inventory' ||
                c.opt_itemType === 'collectible' ||
                c.opt_itemType === 'lottery'
            )
        ));
        // itemsRef.current = [];
        setPrizePacks(
            Object.values(gameElements)
            .filter(c => 
                c.opt_itemType === 'prizepack' && 
                c.status === 'active'
            )
        );
        // prizePacksRef.current = [];
    }, [gameElements, teams])

    useEffect(() => {
        if (disabled) {
            saveData('opt_earnedPoints', null);
            saveData('opt_earnedPointsMin', null);
            saveData('opt_earnedPointsMax', null);
            saveData('opt_earnedCurrency', null);
            saveData('opt_earnedCurrencyMin', null);
            saveData('opt_earnedCurrencyMax', null);
            badgesRef.current = [];
            setSelectedBadges(badgesRef.current);
            saveData('opt_earnedBadges', badgesRef.current);
            itemsRef.current = {};
            setSelectedItems(itemsRef.current);
            saveData('opt_earnedItems', itemsRef.current);
            prizePacksRef.current = {};
            setSelectedPrizePacks(prizePacksRef.current);
            saveData('opt_earnedPrizePacks', prizePacksRef.current);
        }
    }, [disabled])

    function removeChoices(e) {
        if (e === 'badges') {
            badgesRef.current = [];
            setSelectedBadges(badgesRef.current);
            saveData('opt_earnedBadges', badgesRef.current);
            setShowBadges(false);
        }
        if (e === 'items') {
            itemsRef.current = {};
            setSelectedItems(itemsRef.current);
            saveData('opt_earnedItems', itemsRef.current);
            setShowItems(false);
        }
        if (e === 'prizePacks') {
            prizePacksRef.current = {};
            setSelectedPrizePacks(prizePacksRef.current);
            saveData('opt_earnedPrizePacks', prizePacksRef.current);
            setShowPrizePacks(false);
        }
    }

    function chooseMultipleOption(id, category) {
        let tempList;
        if (category === 'badges') {
            tempList = badgesRef.current ? [...badgesRef.current] : [];
            if (tempList.includes(id)) {
                tempList.splice(tempList.indexOf(id), 1);   
            } else {
                tempList.push(id);
            }
            badgesRef.current = [...tempList];
            setSelectedBadges(badgesRef.current)
            saveData('opt_earnedBadges', badgesRef.current, playerId);
        }
        
    }

    return (
        <div className='rewards-form'>
            {
                (
                    gameData && 
                    gameData.access.includes('points') &&
                    type !== 'level'
                ) &&
                <div>
                    <h3 className={disabled ? 'disabled' : ''}>XP</h3>
                    <div className={`field small ${disabled ? 'disabled' : ''}`}>
                        <label>What is the default amount of EXPERIENCE POINTS that should be earned?</label>
                        {
                            (type === 'secretCode')
                            ?
                            <div className='input-with-icon'>
                                <img src={gem} />
                                <input 
                                    type='number' 
                                    className='short-input' 
                                    placeholder='Minimum XP' 
                                    value={formData.opt_earnedPointsMin ?? ''} 
                                    onChange={(e) => saveData(
                                        'opt_earnedPointsMin',
                                        Number(e.target.value), playerId
                                    )} 
                                />
                                <input 
                                    type='number' 
                                    className='short-input' 
                                    placeholder='Maximum XP' 
                                    value={formData.opt_earnedPointsMax ?? ''} 
                                    onChange={(e) => saveData(
                                        'opt_earnedPointsMax',
                                        Number(e.target.value), playerId
                                    )} 
                                />
                            </div>
                            :
                            <div className='input-with-icon'>
                                <img src={gem} />
                                <input 
                                    type='number' 
                                    className='short-input' 
                                    placeholder='XP Earned' 
                                    value={formData.opt_earnedPoints ?? ''} 
                                    onChange={(e) => saveData(
                                        'opt_earnedPoints',
                                        Number(e.target.value), playerId
                                    )} 
                                />
                            </div>
                        }
                    </div>
                </div>
            }
            {
                (gameData && gameData.access.includes('currency') && !teams) &&
                <div>
                    <h3 className={disabled ? 'disabled' : ''}>Currency</h3>
                    <div className={`field small ${disabled ? 'disabled' : ''}`}>
                        <label>What is the default amount of CURRENCY that should be earned?</label>
                        {
                            (type === 'secretCode')
                            ? 
                            <div className='input-with-icon'>
                                <img src={coin}  />
                                <input 
                                    type='number' 
                                    className='short-input' 
                                    placeholder='Minimum Currency' 
                                    value={formData.opt_earnedCurrencyMin ?? ''} 
                                    onChange={(e) => saveData(
                                        'opt_earnedCurrencyMin',
                                        Number(e.target.value), playerId
                                    )}
                                />
                                <input 
                                    type='number' 
                                    className='short-input' 
                                    placeholder='Maximum Currency' 
                                    value={formData.opt_earnedCurrencyMax ?? ''} 
                                    onChange={(e) => saveData(
                                        'opt_earnedCurrencyMax',
                                        Number(e.target.value), playerId
                                    )}
                                />
                            </div>
                            :
                            <div className='input-with-icon'>
                                <img src={coin}  />
                                <input 
                                    type='number' 
                                    className='short-input' 
                                    placeholder='Currency Earned' 
                                    value={formData.opt_earnedCurrency ?? ''} 
                                    onChange={(e) => saveData(
                                        'opt_earnedCurrency',
                                        Number(e.target.value), playerId
                                    )}
                                />
                            </div>
                        }
                    </div>
                </div>
            }
            {
                (
                    gameData && 
                    gameData.access.includes('badges') &&
                    gameElements &&
                    Object.values(gameElements).filter(e => e.type === 'badge').length > 0
                ) &&
                <div>
                    <h3 className={disabled ? 'disabled' : ''}>Badges</h3>
                    {
                        (showBadges)
                        ?
                        <div className={`field small ${disabled ? 'disabled' : ''}`}>
                            <div className='label-with-button'>
                                <label>
                                    What default Badge(s) should the player earn?
                                </label>
                                {
                                    (!review) &&
                                    <button className='g-button text-only' onClick={() => removeChoices('badges')}>Remove All</button>
                                }
                            </div>
                            <div className='g-list-item multiple-select'>
                                {
                                    (badges.length > 0) 
                                    ?
                                    badges.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                    .map(b => {
                                        return (
                                            <div 
                                                key={b.id} 
                                                className={`multiple-select-option ${selectedBadges.includes(b.id) ? 'active' : ''}`} 
                                                onClick={() => chooseMultipleOption(b.id, 'badges')}
                                            >
                                                <ImageContainer src={b.icon} alt={`${b.name} badge icon`} />
                                                {b.name}
                                            </div>
                                        )
                                    })
                                    :
                                    <span>No Badges Yet...</span>
                                }
                            </div>
                        </div>
                        :
                        <>
                            <button className={`g-button med-btn ${disabled ? 'disabled' : ''}`} onClick={() => setShowBadges(true)}>Add Badges</button>
                            <div className="g-space-1"></div>
                        </>
                    }
                </div>
            }
            {
                (
                    gameData && 
                    gameData.access.includes('items') && 
                    !teams &&
                    gameElements &&
                    Object.values(gameElements).filter(e => e.type === 'item').length > 0
                ) &&
                <div>
                    <h3 className={disabled ? 'disabled' : ''}>Items</h3>
                    {
                        (showItems)
                        ?
                        <div className={`field small ${disabled ? 'disabled' : ''}`}>
                            <div className='label-with-button'>
                                <label>
                                    What Item(s) should a player earn?
                                </label>
                                {
                                    (!review) &&
                                    <button className='g-button text-only' onClick={() => removeChoices('items')}>Remove All</button>
                                }
                            </div>
                            <ItemSelector 
                                type='distribute' 
                                formData={formData} 
                                saveData={saveData} 
                                gameElements={items} 
                                gameData={gameData}
                                headText={null}
                                inst={null}
                                playerId={playerId}
                                saveKey='opt_earnedItems'
                            />
                            {/* <div className='g-list-item multiple-select'>
                                {
                                    (items.length > 0) 
                                    ?
                                    items.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                    .map(i => (
                                        <div 
                                            key={i.id} 
                                            className={`multiple-select-option ${selectedItems.includes(i.id) ? 'active' : ''}`} 
                                            onClick={() => chooseMultipleOption(i.id, 'items')}
                                        >
                                            <ImageContainer src={i.icon} alt={`${i.name} item icon`} />
                                            {i.name}
                                        </div>
                                    ))
                                    :
                                    <span>No Items Yet...</span>
                                }
                            </div> */}
                        </div>
                        :
                        <>
                            <button 
                                className={`g-button med-btn ${
                                    disabled ? 
                                    'disabled' : 
                                    ''
                                }`} 
                                onClick={() => setShowItems(true)} >
                                Add Items
                            </button>
                            <div className="g-space-1"></div>
                        </>
                    }
                </div>
            }
            {
                (
                    gameData && 
                    gameData.access.includes('prizepacks') && 
                    !teams&&
                    gameElements &&
                    Object.values(gameElements).filter(e => e.opt_itemType === 'prizepack').length > 0
                ) &&
                <div>
                    <h3 className={disabled ? 'disabled' : ''}>Prize Packs</h3>
                    {
                        (showPrizePacks)
                        ?
                        <div className='prize-pack-div'>
                            <div className={`field ${disabled ? 'disabled' : ''}`}>
                                <div className='label-with-button'>
                                    <label className='space-between'>
                                        What Prize Pack(s) should a player earn?
                                        
                                    </label>
                                    {
                                        (!review) &&
                                        <button 
                                            className='g-button text-only' 
                                            onClick={() => removeChoices('prizePacks')}>
                                            Remove All
                                        </button>
                                    }
                                </div>
                                <ItemSelector 
                                    type='distribute' 
                                    formData={formData} 
                                    saveData={saveData} 
                                    gameElements={prizePacks} 
                                    gameData={gameData}
                                    headText={null}
                                    inst={null}
                                    playerId={playerId}
                                    saveKey='opt_earnedPrizePacks'
                                />
                                {/* <div className='g-list-item multiple-select'>
                                    {
                                        (prizePacks.length > 0) 
                                        ?
                                        prizePacks.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                        .map(i => (
                                            <div 
                                                key={i.id} 
                                                className={`multiple-select-option ${selectedPrizePacks.includes(i.id) ? 'active' : ''}`} 
                                                onClick={() => chooseMultipleOption(i.id, 'prizePacks')}
                                            >
                                                <ImageContainer src={i.icon} alt={`${i.name} item icon`} />
                                                {i.name}
                                            </div>
                                        ))
                                        :
                                        <span>No Prize Packs Yet...</span>
                                    }
                                </div>
                            </div>
                            <div className={`field ${disabled ? 'disabled' : ''}`}>
                                <label>What are the minimum and maximum number of items from the Prize Pack above that should be awarded?</label>
                            </div>
                            <div className={`field ${disabled ? 'disabled' : ''}`}>
                                <div className='g-double-col'>
                                    <div className='field small'>
                                        <label>Minimum Number of Items</label>
                                        <input 
                                            type='number' 
                                            placeholder='Minimum' 
                                            value={formData.opt_earnedPrizeMin ?? ''} 
                                            onChange={(e) => saveData(
                                                'opt_earnedPrizeMin',
                                                Number(e.target.value), playerId
                                            )} />
                                    </div>
                                    <div className='field small'>
                                        <label>Maximum Number of Items</label>
                                        <input 
                                            type='number' 
                                            placeholder='Maximum' 
                                            value={formData.opt_earnedPrizeMax ?? ''} 
                                            onChange={(e) => saveData(
                                                'opt_earnedPrizeMax',
                                                Number(e.target.value), playerId
                                            )}
                                        />
                                    </div>
                                </div> */}
                            </div> 
                        </div>
                        :
                        <>
                            <button className='g-button med-btn' onClick={() => setShowPrizePacks(true)}>Add Prize Packs</button>
                            <div className="g-space-1"></div>
                        </>
                    }
                </div>
            }
        </div>
    )
}

export default RewardsForm