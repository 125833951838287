import React, { useRef, useState } from 'react';
import './invite-modal.styles.scss';
import Modal, { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import org_icon from '@/assets/placeholder-icon-org.jpg'
import { useDispatch, useSelector } from 'react-redux';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { format } from 'date-fns';
import { properCase } from '@/utils/properCase';
import { UseCloudFunction } from '@/utils/firebase.utils';
import FullPageLoader from '@/components/loader/full-page-loader.component';
import { setUserProfile } from '@/state/slices/userSlice';

const InviteModal = ({
    invite,
    cancel,

}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const approveRef = useRef();
    const dispatch = useDispatch();

    function acceptDecline(e) {
        let message;
        const now = new Date().getTime();
        message = `Are you sure you want to <b>${e ? 'ACCEPT' : 'DECLINE'}</b> this invitation?`
        setConfirmMessage(message);
        approveRef.current = () => {
            continueAcceptDecline();
        }

        async function continueAcceptDecline() {
            setLoading(true);
            const res = await UseCloudFunction(
                'acceptDeclineInvite', 
                {
                    'accept': e,
                    'inviteId': invite.id, 
                    'ts': now, 
                    'appId': userProfile.appId
                }
            )
            console.log(res);
            setLoading(false);
            if (res.error) {
                setAlertMessage(`Something went wrong. Error: ${res.error}`);
                return;
            }
            if (e) {
                dispatch(setUserProfile(res.profile));
                // navigate(`orgs/${invite.orgId}`)
            }
            
        }
    }

    return (
        <div className='invite-modal'>
            <Modal 
                show={invite}
                cancel={cancel}
                closeButton={true}
                text='You have been invited to join an Organization'
            >
                
            {
                (invite) &&
                <div className='invite-modal-content'>
                    <div className='g-card org-card'>
                        <img src={invite.icon ?? org_icon} className='org-img' />
                        <div className='org-info'>
                            <div className='org-title'>{invite.orgName}</div>
                            <div className='meta invite-date'>Invited {format(new Date(invite.ts_created), "P")}</div>
                            <div className='meta'>Role: {properCase(invite.role[0])}</div>
                        </div>
                    </div>
                    <div className="g-space-20"></div>
                    <p>By accepting the invitation, you will have access to all of the features Gamably offers. Plus you will have access to media and game content created and shared by other members of your organization. You will also be able to add other members of your organization as managers or distributors on games you create. Why would you say no?</p>
                    <div className='button-row'>
                        <button className='g-button' onClick={() => acceptDecline(false)}>Decline</button>
                        <button className='g-button primary' onClick={() => acceptDecline(true)}>Accept the Invitation!</button>                    
                    </div>
                </div> 
            }
            </Modal>    
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current} />
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
            <FullPageLoader
                show={loading} />
        </div>
    )
}

export default InviteModal