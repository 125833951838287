import React, { useEffect, useRef, useState } from 'react'
import './game-settings.styles.scss';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import SettingsInfo from './settings-info.component';
import FullPageLoader from '@/components/loader/full-page-loader.component';
import SettingsDesign from './settings-design.component';
import SettingsElements from './settings-elements.components';
import SettingsRostering from './settings-rostering.component';
import SettingsRemoved from './settings-removed.component';
import Collaborators from './collaborators.component';

const GameSettings = ({ 
    gameData, 
    playerList, 
    orgs,
    role
}) => {

    const profile = useSelector(state => state.userData.userProfile);
    const [ validJoinCode, setValidJoinCode ] = useState(true);
    const [ formData, setFormData ] = useState({});
    const [ confirmMessage, setConfirmMessage] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ joinCodeEdited, setJoinCodeEdited ] = useState(false);
    const navigate = useNavigate();
    const storage = getStorage();
    const location = useLocation();
    const dataRef = useRef();
    const approveRef = useRef();

    useEffect(() => {
        return () => {
            const settingsMenu = document.getElementById('settings-drop-menu');
            if (settingsMenu) {
                settingsMenu.classList.remove('active');
            }
            const settingsCaret = document.getElementById('settings-drop-menu-caret');
            if (settingsCaret) {
                settingsCaret.classList.remove('rotated');
            }
        }
    }, [])

    useEffect(() => {
        if (!role) return;
        if (role !== 'owner' && role !== 'manager') {
            navigate(-1);
        }
    }, [role])

    useEffect(() => {
        // document.getElementById('scroll-point').scrollIntoView({'behavior': 'smooth', 'top': '-20px'});
        document.getElementById('scroll-to-top').scrollTo({'top': '0px', 'behavior': 'smooth'})
        document.getElementById('settings-drop-menu').classList.add('active');
        document.getElementById('settings-drop-menu-caret').classList.add('rotated');

    }, [location])

    useEffect(() => {
        if (!gameData) {return;}
        console.log(gameData);
        dataRef.current = gameData;
        setFormData(dataRef.current);
    }, [gameData])

    function cancel() {
        navigate(`/manage/${gameData.path}`);
    }

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
    }

    // console.log(formData)
    //         if (path === 'part2') {
    //             const joinCodeIsAvailable = await UseCloudFunction('checkJoinCode', {'joinCode': formData.joinCode, 'appId': profile.appId})
    //             setLoading(false);
    //             if (!joinCodeIsAvailable.result) {
    //                 setValidCode(false)
    //                 return;
    //             }
    //         }

    async function saveSettings(e) {
        setConfirmMessage(`Are you sure you're ready to save these settings?`);
        approveRef.current = approveSave;
        async function approveSave() {
            setLoading(true);
            const now = new Date().getTime()
            const newObj = {...formData};
            newObj.ts = now;
            if (e === 'info') {
                console.log(newObj.joinCode, gameData.joinCode);
                if (newObj.joinCode !== gameData.joinCode) {
                    const joinCodeIsAvailable = await UseCloudFunction(
                        'checkJoinCode', 
                        {
                            'joinCode': newObj.joinCode, 
                            'appId': profile.appId
                        }
                    )
                    setLoading(false);
                    if (!joinCodeIsAvailable.result) {
                        setValidJoinCode(false)
                        return;
                    }
                }
            } else if (e === 'design') {
                if (newObj.icon.upload) {
                    const newIconUrl = await uploadImage(newObj.icon.file, newObj.gameId, 'game-icon');
                    newObj.icon = newIconUrl;
                } else if (newObj.icon.url) {
                    newObj.icon = newObj.icon.url;
                }

                if (newObj.header.upload) {
                    const newHeaderUrl = await uploadImage(newObj.header.file, newObj.gameId, 'game-header');
                    newObj.header = newHeaderUrl;
                } else if (newObj.header.url) {
                    newObj.header = newObj.header.url;
                }
            } else if (e === 'rostering') {
                if (newObj.banned) {
                    const bannedList = newObj.banned.split(/\s*,\s*/);
                    delete newObj.banned;
                    UseCloudFunction(
                        'saveBannedList', 
                        {
                            'bannedList': bannedList, 
                            'gameId': gameData.gameId, 
                            'appId': profile.appId
                        }
                    )
                }
            }
            const res = await UseCloudFunction(
                'saveGame', 
                {
                    'gameObj': newObj, 
                    'appId': profile.appId, 
                    'action': 'save game settings'
                }
            )
            if (res.error) {
                setAlertMessage(`Something went wrong. Please try again later. (Error:${res.error})`);
                setLoading(false);
                return;
            }
            setAlertMessage('Settings saved successfully!')
            setLoading(false);
        }
    }

    async function uploadImage(blob, gameId, type) {		
		const now = new Date().getTime();
		const profileRef = ref(storage, `${gameId}/${now}-${type}`)
		const metadata = {
			customMetadata: {
                'appId': profile.appId,
                'gameId': gameId,
            }
		};
		return uploadBytes(profileRef, blob, metadata).then(async (snapshot) => {
			const newUrl = await getDownloadURL(profileRef)
			.then((url) => {
				return url;
			})
            return newUrl;
		})
	}

    return (
        <div className='game-settings-container'>
            <div className='game-settings-content'>
                <div id='scroll-point'></div>
                <Routes>
                    <Route path='' element={
                        <SettingsInfo 
                            edit={true} 
                            formData={formData} 
                            saveData={saveData} 
                            validJoinCode={validJoinCode} 
                            saveSettings={saveSettings}
                            cancel={cancel}
                            setJoinCodeEdited={setJoinCodeEdited}
                        />
                    }></Route>
                    <Route path='collaborators' element={
                        <Collaborators 
                            gameData={gameData}
                            orgs={orgs}
                            formData={formData}
                            saveData={saveData}
                            saveSettings={saveSettings} 
                        />
                    }></Route>
                    <Route path='design' element={
                        <SettingsDesign
                            edit={true}
                            formData={formData}
                            saveData={saveData}
                            saveSettings={saveSettings}
                            cancel={cancel}
                        />
                    }></Route>
                    <Route path='rostering' element={
                        <SettingsRostering
                            edit={true}
                            formData={formData}
                            saveData={saveData}
                            saveSettings={saveSettings}
                            cancel={cancel}
                        />
                    }></Route>
                    <Route path='elements' element={
                        <SettingsElements
                            edit={true}
                            formData={formData}
                            saveData={saveData}
                            saveSettings={saveSettings}
                            gameData={gameData}
                            cancel={cancel}
                        />
                    }></Route>
                    <Route path='removedplayers' element={
                        <SettingsRemoved
                            gameData={gameData}
                            cancel={cancel}
                        />
                    }></Route>
                    <Route path="*" element={<Navigate replace to="/404" />} />
                </Routes>
                {/* {
                    (gameData) &&
                    <div className='g-card'>
                        <BackButton cancel={cancel} />
                        <div className="g-space-1"></div>
                        <div className='card-title'>Game Settings</div>
                        <div className='g-space-1'></div>
                        <div className='settings-box g-list-item'>
                            <h3>Game Information</h3>
                            <hr />
                            <CreateEditForm1 edit={true} formData={formData} saveData={saveData} validJoinCode={validJoinCode} />
                        </div>
                        <div className="g-space-2"></div>
                        <div className='settings-box g-list-item'>
                            <h3>Game Design</h3>
                            <hr />
                            <CreateEditForm2v2 edit={true} formData={formData} saveData={saveData} />
                        </div>
                        <div className="g-space-2"></div>
                        <div className='settings-box g-list-item'>
                            <h3>Rostering Settings</h3>
                            <hr />
                            <CreateEditForm4 edit={true} formData={formData} saveData={saveData} gameData={gameData}  />
                        </div>

                        <div className="g-space-2"></div>
                        <div className='settings-box g-list-item'>
                            <h3>Content Settings</h3>
                            <hr />
                            <GameContentSettingsForm formData={formData} saveData={saveData} gameData={gameData}  />
                        </div>
                        <div className="g-space-2"></div>
                        {
                            (loading)
                            ?
                            <div className='buttons'>
                                <button className='g-button'>Cancel</button>
                                <button className='g-button'><ThreeDotsLoader /></button>
                            </div>
                            :
                            <div className='buttons'>
                                <button className='g-button' onClick={() => navigate(`/manage/${gameData.path}`)}>Cancel</button>
                                <button className='g-button primary' onClick={() => saveSettings()}>Save Settings</button>
                            </div>
                        }
                    </div>
                } */}
            </div>   
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage}
            />
            <ModalConfirm 
                show={confirmMessage} 
                cancel={() => setConfirmMessage(false)} 
                message={confirmMessage} 
                onApprove={approveRef.current} 
            />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default GameSettings