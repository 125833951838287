import React, { useEffect, useRef, useState } from 'react';
import './add-collaborator-modal.styles.scss';
import Modal from '@/components/modal/modal.component';
import { sortPlayers } from '@/utils/sortPlayers';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';

const AddCollaboratorModal = ({ 
    gameData,
    showAddCollabModal,
    setShowAddCollabModal,
    selectedOrg,
    orgMembers,
    collabs,
    addCollaborator
 }) => {

    const [ collabsToDisplay, setCollabsToDisply ] = useState([]);
    const [ newCollabs, setNewCollabs ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const membersRef = useRef();
    const newCollabRef = useRef();

    useEffect(() => {
        if (!collabs || !orgMembers) return;
        const existingCollabs = collabs.map(c => c.appId);
        console.log(existingCollabs);
        let orgMemberArr = []
        Object.values(orgMembers).map(m => {
            if (existingCollabs.includes(m.appId)) return;
            orgMemberArr.push(m);
        })
        console.log(orgMemberArr);
        setCollabsToDisply(sortPlayers({'playerList': orgMemberArr}));
        membersRef.current = [...orgMemberArr];
    }, [collabs, orgMembers])

    function search(text) {
        const availableCollabs = [...membersRef.current];
        setCollabsToDisply([
            ...availableCollabs
            .filter(c => 
                c.fName.toLowerCase().includes(text.toLowerCase()) ||
                c.lName.toLowerCase().includes(text.toLowerCase())
            )
        ])
    }

    function setNewCollab(appId, role) {
        const tempNewCollabs = {...newCollabRef.current}
        if (!role) {
            delete tempNewCollabs[appId];
        } else {
            tempNewCollabs[appId] = {
                'appId': appId,
                'role': role,
            }
        }
        newCollabRef.current = {...tempNewCollabs};
        setNewCollabs(newCollabRef.current);
        console.log(newCollabRef.current);
    }

    async function saveCollaborators() {
        let tempNewCollabs = {...newCollabRef.current};

        for (let collab of Object.values(tempNewCollabs)) {
            addCollaborator(collab.appId, collab.role);
        }
        newCollabRef.current = {};
        setNewCollabs(newCollabRef.current);
        setShowAddCollabModal(false);
        // const now = new Date().getTime();
        // tempGameData.orgId = selectedOrg;
        // tempGameData.ts = now;
        // const res = await UseCloudFunction(
        //     'saveGame', 
        //     {
        //         'gameObj': tempGameData, 
        //         'appId': profile.appId, 
        //         'action': 'save collabs',
        //         'collabData': newCollabRef.current
        //     }
        // )
        // if (res.error) {
        //     setAlertMessage(`Something went wrong. Please try again later. (Error:${res.error})`);
        //     setLoading(false);
        //     return;
        // }
        // setAlertMessage('Settings saved successfully!')
        // setLoading(false);
    }

    return (
        <div className='add-collaborator-modal'>
            <Modal 
                show={showAddCollabModal}
                cancel={() => setShowAddCollabModal(false)}
                text='Add Collaborators'
                closeButton={true}
                cls='narrow' >
                    <div className='search-bar'>
                        <SearchBar
                            search={search} />
                    </div>
                    <div className="g-space-1"></div>
                    <div className='collab-list'>
                    {
                        (collabsToDisplay.length > 0)
                        ?
                        sortPlayers({'playerList': collabsToDisplay})
                        .filter(m => !collabs.includes(m))
                        .map(m => (
                            <div 
                                key={m.appId} 
                                className={`g-list-item collab-item ${
                                    newCollabs[m.appId] ? 'active' : ''
                                }`}>
                                <UserIcon profile={m} small={true} hover={false} />
                                <div>{m.fName} {m.lName}</div>
                                <div>
                                    <div className="field small">
                                        <select 
                                            className='short-input'
                                            onChange={(e) => setNewCollab(m.appId, e.target.value)}>
                                            <option value=''>Add Role</option>
                                            <option value='manager'>Game Manager</option>
                                            <option value='distributor'>Distributor Only</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        <div></div>
                    }
                    </div>
                    <hr />
                    {
                        (loading) 
                        ?
                        <div className='buttons'>
                            <button className='g-button'>
                                Cancel
                            </button>
                            <button className='g-button'>
                                <ThreeDotsLoader />
                            </button>
                        </div>
                        :
                        <div className='buttons'>
                            <button 
                                className='g-button'
                                onClick={() => setShowAddCollabModal(false)} >
                                Cancel
                            </button>
                            <button
                                className='g-button primary'
                                onClick={() => saveCollaborators()} >
                                Done
                            </button>
                        </div>
                    }
            </Modal>
        </div>
    )
}

export default AddCollaboratorModal