import React, { useEffect, useRef, useState } from 'react'
import './my-org-view.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate, useParams } from 'react-router-dom';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import { ModalAlert } from '@/components/modal/modal.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { getInvitesFromDb } from '@/utils/getAdminData';
import OrgInviteModal from '@/components/SHARED/org-invite-modal/org-invite-modal.component';
import { format } from 'date-fns';

const MyOrgView = ({ orgs }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const { orgPath } = useParams();
    const [ currentOrg, setCurrentOrg ] = useState(null);
    const [ currentMembers, setCurrentMembers ] = useState([]);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ invites, setInvites ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ loadingInvites, setLoadingInvites ] = useState(false);
    const [ showInviteModal, setShowInviteModal ] =  useState(false);
    const inviteRef = useRef();
    let inviteListener;
    const navigate = useNavigate();

    useEffect(() => {

        return () => {
            if (inviteListener) inviteListener();
        }
    }, [])

    useEffect(() => {
        if (!orgPath || !orgs) return;
        const selectedOrg = Object.values(orgs)
        .filter(o => o.path === orgPath)[0];
        if (!selectedOrg) {
            navigate('/home/myorgs');
            return;
        }
        setCurrentOrg({...selectedOrg});
    }, [orgPath, orgs])

    useEffect(() => { 
        if (!currentOrg || !userProfile) return;
        getOrgMembers();
        if (currentOrg.managers.includes(userProfile.email)) {
            getOrgInvites();
        }
    }, [currentOrg, userProfile])

    async function getOrgMembers() {
        const res = await UseCloudFunction(
            'getOrgMembers',
            {
                'orgId': currentOrg.orgId,
                'appId': userProfile.appId,
            }
        )
        console.log(res);
        if (res.error) {
            if (res.error === 'no access') {
                navigate(-1);
                return;
            }
            setAlertMessage('Something went wrong: ' + res.error);
            return;
        }
        const sortedMembers = Object.values(res.members)
        .sort((a,b) => 
            a.lName.toLowerCase() > b.lName.toLowerCase() ? 1 : 
            (a.lName.toLowerCase() === b.lName.toLowerCase()) ?
                a.fName.toLowerCase() > b.fName.toLowerCase() ?
                1 : -1
            : -1
        )
        setCurrentMembers([...sortedMembers]);
        setLoading(false);
    }

    async function getOrgInvites() {
        getInvitesFromDb({
            'orgId': currentOrg.orgId,
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data, id) {
            inviteRef.current = {...inviteRef.current, ...{[id]: data}}
            setInvites({...inviteRef.current});
        }
        function handleListener(unsub) {
            inviteListener = unsub;
        }
    }

    return (
        <div className='my-org-view'>
            <div className='g-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className='g-space-1'></div>
                {
                    (currentOrg && userProfile) &&
                    <div className='my-org-view-content'>
                        <div className='card-title'>
                            <ImageContainer 
                                src={currentOrg.icon ?? '/images/icons/placeholder-icon-org.jpg'}
                                className='head-icon' />
                            {currentOrg.name}
                        </div>
                        <hr />
                        <div>{currentOrg.name}</div>
                        <div>{currentOrg.address}</div>
                        <div>{currentOrg.address2}</div>
                        <div>{currentOrg.city}, {currentOrg.state} {currentOrg.zip}</div>
                        <div>{currentOrg.contactPhone}</div>
                        <div className='g-space-1'></div>
                        <div>Contact: <a href={`mailto:${currentOrg.contactEmail}`} target='_blank'>
                            {currentOrg.contactName}
                        </a>
                        </div>
                        <div className='g-space-1'></div>
                        <div className='card-title with-button'>
                            <div className='info'>
                                <h3>Current Members</h3>
                                <div className='meta'>Count: {currentOrg.memberCount}/{currentOrg.memberMax}</div>
                            </div>
                            {
                                (currentOrg.managers.includes(userProfile.email))
                                ?
                                <button 
                                    className='g-button med-btn'
                                    onClick={() => setShowInviteModal(true)}
                                    >
                                    Add Members
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </button>
                                :
                                <div></div>
                            }
                        </div>
                        <div className='g-space-1'></div>
                        {
                            (loading)
                            ?
                            <ThreeDotsLoader />
                            :
                            (currentMembers.length > 0) 
                            ?
                            <div className='member-list'>
                                {
                                    currentMembers.map(m => (
                                        <div key={m.appId} className='g-list-item member-list-item'>
                                            <div>{m.lName}, {m.fName}</div>
                                            <div className='meta'>{m.email}</div>
                                            <div className='meta'>Accepted {format(new Date(m.ts_created), "P")}</div>
                                        </div>
                                    ))
                                }
                            </div>
                            :
                            <div>No Members Yet...</div>
                        }
                        {
                            (currentOrg.managers.includes(userProfile.email)) &&
                            <div className='invites-div'>
                                <div className='g-space-2'></div>
                                <div className='card-title'>
                                    <h3>Pending Invites</h3>
                                </div>
                                <div className="g-space-1"></div>
                                {
                                    (loadingInvites)
                                    ?
                                    <ThreeDotsLoader />
                                    :
                                    (Object.values(invites).length > 0)
                                    ?
                                    <div className='member-list'>
                                        {
                                            Object.values(invites)
                                            .filter(i => i.status === 'pending')
                                            .sort((a,b) => a.email > b.email ? 1 : -1)
                                            .map(m => (
                                                <div key={m.ts} className='g-list-item member-list-item'>
                                                    <div>{m.email}</div>
                                                    <div className='meta'>Sent {format(new Date(m.ts), "P")}</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div>No pending invites...</div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
            <OrgInviteModal
                show={showInviteModal}
                cancel={() => setShowInviteModal(false)}
                orgData={currentOrg}
                invites={invites} />

            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
        </div>
    )
}

export default MyOrgView