import React, { useEffect, useState } from 'react'
import './secret-codes-landing.styles.scss';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import SecretCodesList from './secret-codes-list.component';
import SecretCodesForm from './secret-codes-form.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import { useSelector } from 'react-redux';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import { properCase } from '@/utils/properCase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import SecretCodesUpload from './secret-codes-upload.component';

const SecretCodesLandingPage = ({ 
    gameElements, 
    gameData, 
    playerList 
}) => {
    
    const membership = useSelector(state => state.userData.membership);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        checkMembership();
    }, [])

    function checkMembership(e) {
        const element = 'secretCodes'
        const res = testMemberRestrictions({'gameData': gameData, 'membership': membership, 'element': element})
        // console.log(res);

        if (res.disabled) {
            let message = ''
            const planRest = membershipRestrictions[membership.membership];
            if (res.disabledReason.includes(element)) {
                message = `While using the ${planRest.title}, you are not able to access Secret Codes.`;
            }
            setShowUpgradeModal(message)
            return;   
        }
        navigate(e);
    }
    
    return (
        <div className='secret-codes-landing'>
            <Routes>
                <Route path='' element={
                    <div className='g-card secret-codes-card'>
                        <BackButton cancel={() => navigate(-1)} />
                        <div className="g-space-1"></div>
                        <div className='card-title with-button'>
                            <ImageContainer src='/images/icons/secret2.png' alt='secret codes icon' className='head-icon' />
                            Create and Edit Secret Codes
                            <div className='buttons'>
                                <button 
                                    type='button' 
                                    className='g-button med-btn' 
                                    onClick={() => checkMembership('create')}>
                                    Create New Code
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                                <button 
                                    type='button' 
                                    className='g-button med-btn' 
                                    onClick={() => checkMembership('upload')}>
                                    <FontAwesomeIcon icon={faUpload} />
                                </button>
                            </div>
                        </div>
                        <div className='g-space-1'></div>
                        <div className='secret-codes-landing-content'>
                            <SecretCodesList 
                                gameElements={gameElements} 
                                gameData={gameData} 
                            />
                        </div>
                    </div>
                }></Route>
                <Route path='create' element={
                    <SecretCodesForm
                        gameElements={gameElements} 
                        gameData={gameData} 
                        playerList={playerList}  />
                }></Route>
                <Route path='edit/:secretCodePath' element={
                    <SecretCodesForm
                        gameElements={gameElements} 
                        gameData={gameData} 
                        playerList={playerList}  />
                }></Route>
                <Route path='upload' element={
                    <SecretCodesUpload
                        gameData={gameData}
                        gameElements={gameElements} />
                }></Route>
                <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(null)}
                message={showUpgradeModal}
            />
        </div>
    )
}

export default SecretCodesLandingPage