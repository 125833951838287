import React, { useEffect, useRef, useState } from 'react';
import './item-search-bar.styles.scss';
import SearchBar from './search-bar.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import CategoryFilterModal from '@/components/MANAGE-GAME/create-elements/components/category-filter-modal.component';

const ItemSearchBar = ({
    availableItems,
    setItemsToDisplay,
    noTypes,
    collections,
    itemType
}) => {

    const [ categories, setCategories ] = useState([]);
    const [ types, setTypes ] = useState([]);
    const [ showFilterModal, setShowFilterModal ] = useState(false);
    const [ showTypeFilterModal, setShowTypeFilterModal ] = useState(false);
    const [ showCollectionFilterModal, setShowCollectionFilterModal ] = useState(false);
    const [ chosenCategories, setChosenCategories ] = useState([]);
    const [ chosenTypes, setChosenTypes ] = useState([]);
    const [ chosenCollections, setChosenCollections ] = useState([]);
    const categoryRef = useRef([]);
    const typesRef = useRef([]);
    const availableRef = useRef([]);
    const searchRef = useRef();

    useEffect(() => {
        if (!availableItems) return;
        availableRef.current = [...availableItems];
        let catsArr = [];
        let typesArr = []
        availableItems.map(i => {
            if (i.opt_cat && !catsArr.includes(i.opt_cat)) {
                catsArr.push(i.opt_cat);
            }
            if (!typesArr.includes(i.opt_itemType)) {
                typesArr.push(i.opt_itemType);
            }
        })
        setCategories([...catsArr.sort()])
        setTypes([...typesArr.sort()])
    }, [availableItems])

    useEffect(() => {
        if (!availableItems) return;
        search(searchRef.current);
    }, [availableItems, chosenCategories, chosenTypes, chosenCollections])

    function chooseCategory(cat) {
        // console.log(cat);
        let tempCats = [...chosenCategories];
        // console.log(tempCats)
        if (tempCats.includes(cat)) {
            tempCats.splice(tempCats.indexOf(cat), 1)
        } else {
            tempCats.push(cat);
        }
        setChosenCategories([...tempCats]);
    }

    function chooseType(type) {
        // console.log(type);
        let tempTypes = [...chosenTypes];
        if (tempTypes.includes(type)) {
            tempTypes.splice(tempTypes.indexOf(type), 1)
        } else {
            tempTypes.push(type);
        }
        setChosenTypes([...tempTypes]);
    }

    function chooseCollection(collectionId) {
        let tempColl = [...chosenCollections];
        if (tempColl.includes(collectionId)) {
            tempColl.splice(tempColl.indexOf(collectionId), 1)
        } else {
            tempColl.push(collectionId);
        }
        setChosenCollections([...tempColl]);
    }

    function search(text) {
        // if (!text) resetItems();
        searchRef.current = text;
        setItemsToDisplay(
            availableItems
            .filter(e => 
                (
                    text 
                    ? 
                    e.name.toLowerCase().includes(text.toLowerCase())
                    :
                    true
                ) &&
                (
                    chosenCategories.length > 0
                    ?
                    chosenCategories.includes(e.opt_cat)
                    :
                    true
                ) &&
                (
                    chosenTypes.length > 0
                    ?
                    chosenTypes.includes(e.opt_itemType)
                    :
                    true
                ) && (
                    chosenCollections.length > 0
                    ?
                    (
                        e.opt_collections &&
                        chosenCollections.some(collId => e.opt_collections.includes(collId))
                    )
                    :
                    true
                )
            ))
    }

    return (
        <div className='item-search-bar'>
            <div className='search-bar'>
                <SearchBar search={search} />
                {
                    (itemType !== 'collectible') &&
                    <button className='g-button med-btn filter-button' onClick={() => setShowFilterModal(true)}>
                        <FontAwesomeIcon icon={faFilter} className={chosenCategories.length > 0 ? 'full' : 'empty'} />
                        Categories
                    </button>
                }
                {
                    (itemType === 'collectible') &&
                    <button className='g-button med-btn filter-button' onClick={() => setShowCollectionFilterModal(true)}>
                        <FontAwesomeIcon icon={faFilter} className={chosenCollections.length > 0 ? 'full' : 'empty'} />
                        Collections
                    </button>
                }
                {
                    (!noTypes) &&
                    <button className='g-button med-btn filter-button' onClick={() => setShowTypeFilterModal(true)}>
                        <FontAwesomeIcon icon={faFilter} className={chosenTypes.length > 0 ? 'full' : 'empty'} />
                        Item Types
                    </button>
                }
            </div>
            
            {/** Category Modal */}
            {
                (itemType !== 'collectible') &&
                <CategoryFilterModal 
                    show={showFilterModal} 
                    cancel={() => setShowFilterModal(false)} 
                    chooseCategory={chooseCategory} 
                    categoryList={categories} 
                    selectedCategories={chosenCategories} 
                    color='var(--primary)'
                    text='Filter by Item Category'
                />
            }

            {/** Collection */}
            {
                (itemType === 'collectible') &&
                <CategoryFilterModal 
                    show={showCollectionFilterModal} 
                    cancel={() => setShowCollectionFilterModal(false)} 
                    chooseCategory={chooseCollection} 
                    categoryList={collections} 
                    selectedCategories={chosenCollections} 
                    color='var(--primary)'
                    text='Filter by Collection'
                />
            }

            {/** Item Type Modal */}
            {
                (!noTypes) &&
                <CategoryFilterModal 
                    show={showTypeFilterModal} 
                    cancel={() => setShowTypeFilterModal(false)} 
                    chooseCategory={chooseType} 
                    categoryList={types} 
                    selectedCategories={chosenTypes} 
                    color='var(--primary)'
                    text='Filter by Item Type'
                />
            }

        </div>
    )
}

export default ItemSearchBar