import React, { useEffect, useState } from 'react'
import './play-head-bar.styles.scss'

import { useSelector } from 'react-redux';
import UserDropdown from './play-user-dropdown.component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';
import gamably_icon_grey from '@/assets/icon-greyscale.png';
import ShoppingCartIcon from './shopping-cart-icon.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { formatNumber } from '@/utils/formatNumber';

const PlayHeadBar = ({ gameData, playerData }) => {

    const screenSize = useSelector(state => state.contentData.screenSize);
    const currentUser = useSelector(state => state.userData.currentUser);
    const userProfile = useSelector(state => state.userData.userProfile);
    const shoppingCart = useSelector(state => state.userData.shoppingCart);
    const [ showDropdown, setShowDropdown ] = useState(false);
    const [ pageTitle, setPageTitle ] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className={`play-head-bar-container ${screenSize}`}>
            <div className="logo-container">
                <Link to={`/play/${gameData.path}`} >
                    <img src={screenSize !== 'desktop' ? gameData.icon : gameData.header} alt="Logo" className="logo" />
                </Link>
                
                <div className='menu-spacer'></div>
                <h3 id='page-title'></h3>
                
            </div>
            {/* <SearchContainer setShowSearchModal={setShowSearchModal} location='head-bar' /> */}
            <div className='head-menu-box'>
                <div className='head-menu-flex'>
                    <div className='head-menu'>
                        {/* <div className='head-menu-item' onClick={() => navigate('/coach')}>
                            Coach Home
                        </div>
                        <div className='menu-spacer'></div>
                        <div className='head-menu-item' onClick={() => navigate('/manage')}>
                            Manage Org
                        </div>
                        <div className='menu-spacer'></div>
                        <div className='head-menu-item' onClick={() => setShowMyOrgs(true)}>
                            Switch Organization
                        </div>
                        <div className='menu-spacer'></div> */}
                        {
                            (userProfile && userProfile.admin) &&
                            <button className='g-button text-only' onClick={() => navigate('/admin')}>
                                Admin
                            </button>
                        }
                        {
                            (shoppingCart && Object.values(shoppingCart).length > 0) &&
                            <button 
                                className='g-button text-only'
                                onClick={() => navigate(`/play/${gameData.path}/marketplace/shoppingcart`)}
                            >
                                <ShoppingCartIcon cart={shoppingCart} />
                            </button>
                        }
                        {
                            (playerData && gameData.access.includes('points')) &&
                            <button 
                                className='count-div g-button small-btn'
                                onClick={() => navigate(`/play/${gameData.path}/myxpearnings`)}
                            >
                                <ImageContainer 
                                    src='/images/xp.png' 
                                    className='icon' 
                                />
                                <div className='count'>
                                    {formatNumber(playerData.points)}
                                </div>
                            </button>
                        }
                        {
                            (playerData && gameData.access.includes('currency')) &&
                            <button 
                                className='count-div g-button small-btn'
                                onClick={() => navigate(`/play/${gameData.path}/mycurrency`)}
                            >
                                <ImageContainer 
                                    src='/images/icons/coin.png' 
                                    className='icon' 
                                />
                                <div className='count'>
                                    {formatNumber(playerData.currency)}
                                </div>
                            </button>
                        }
                    </div>
                    <button className='head-user-info g-button text-only' onClick={() => setShowDropdown(!showDropdown)}>
                        {
                            (currentUser && userProfile) &&
                            <>
                                {/* <div>{userProfile.fName}{userProfile.lName ? ` ${userProfile.lName}` : ''}</div> */}
                                <div className='user-icon' title={userProfile.name}>
                                    <UserIcon profile={userProfile} reverse={true} planIcon={true} />
                                </div>

                            </>
                            
                        }
                    </button>
                </div>
                <UserDropdown show={showDropdown} />
            </div>
        </div>
    )
}

export default PlayHeadBar;