import React, { useEffect } from 'react'
import './item-mini-card.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';

const ItemMiniCard = ({ item, selectedItem, setSelectedItem }) => {

    useEffect(() => {
        const imageContainer = document.querySelector('.image-container');
        const image = document.getElementById('icon-image');

        image.onload = function() {
        const imageAspectRatio = image.naturalWidth / image.naturalHeight;

        // Check if image is wider or taller
        if (imageAspectRatio > 1) {
            imageContainer.style.objectFit = 'contain'; // Fill width
        } else {
            imageContainer.style.objectFit = 'cover'; // Fill height
        }
        };
    }, [item])
    return (
        <button 
            className={`item-mini-card g-button ${selectedItem && selectedItem.id === item.id ? 'primary' : ''} ${item.name === 'Mystery Item' ? 'disabled' : ''}`}
            title={item.name}    
            onClick={() => setSelectedItem(item)}
        >
            {
                (item) &&
                <>
                <div className={`mini-card-image-container`}>
                    <ImageContainer 
                        src={item.icon} 
                        id="icon-image" 
                        className={`item-icon ${!item.count ? 'disabled' : ''}`} 
                    />
                </div>
                {
                    (item.count > 0) &&
                    <div className='count-flag'>
                        x {item.count}
                    </div>
                }
                </>
            }
        </button>
    )
}

export default ItemMiniCard