import React, { 
    useEffect, 
    useRef, 
    useState 
} from 'react';
import './play-game.styles.scss';
import { 
    useNavigate, 
    useParams 
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
    getGameDataFromDb, 
    getPlayerDataFromDb, 
    getActionItemsFromDb, 
    getGameElementsFromDb, 
    getUniqueDocumentFromDb, 
    getCollectionFromDb, 
    getLatestGameMessageFromDb, 
    getGameActivityFromRtDb,
    getActionItemsFromRtDb,
    getPlayersFromPlayerDocs
} from '@/utils/getDataFromDb';
import PlayerGameContent from '@/components/PLAYER/player-game-content.component';
import { 
    Desktop, 
    LargeTablet, 
    Mobile, 
    Tablet 
} from '@/utils/responsiveQueries';
import PlayHeadBar from '@/components/PLAYER/play-game-head-bar/play-head-bar.component';
import Loader from '@/components/loader/loader.comp';
import { 
    UseCloudDistribute, 
    UseCloudFunction, 
    auth 
} from '@/utils/firebase.utils';
import useDocumentTitle, { changeDocumentTitle } from '@/utils/useDocumentTitle';
import { off } from 'firebase/database';

const PlayGamePage = () => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ gameData, setGameData ] = useState(null);
    const [ playerData, setPlayerData ] = useState(null);
    const [ gameElements, setGameElements ] = useState(null);
    const [ playerActivity, setPlayerActivity ] = useState(null);
    const [ gameActivity, setGameActivity ] = useState(null);
    const [ actionItems, setActionItems ] = useState(null);
    const [ gameStats, setGameStats ] = useState(null);
    const [ playerList, setPlayerList ] = useState(null);
    const [ teamMembers, setTeamMembers ] = useState(null);
    const [ myTeams, setMyTeams ] = useState(null);
    const [ latestMessage, setLatestMessage ] = useState(null); 
    const { gamePath } = useParams();
    const elementsRef = useRef();
    const activityRef = useRef();
    const actionItemsRef = useRef();
    const playerListRef = useRef();
    const alreadyChecked = useRef();
    const navigate = useNavigate();
    let gameListener;
    let playerListener;
    let elementsListener;
    let activityListener;
    let checkVisInterval;
    let actionItemsListener;
    let statsListener;
    let playerListListener;
    let teamListener;
    let messageListener;

    useEffect(() => {

        // checkElementVisibility();
        checkForAuth();
        checkVisInterval = setInterval(() => {
            // checkElementVisibility();
        }, 100000)

        function checkForAuth() {
            setTimeout(() => {
                const currentlySignedInUser = auth.currentUser;
                // console.log(currentlySignedInUser);
                if (!currentlySignedInUser) {
                    navigate('/logout');
                }
            }, 2000);
        }

        return () => {
            window.localStorage.removeItem('gameElements');
            window.localStorage.removeItem('playerActivity');
            window.localStorage.removeItem('actionItems');
            if (gameListener) gameListener();
            if (playerListener) playerListener();
            if (elementsListener) elementsListener();
            if (activityListener) off(activityListener);
            if (checkVisInterval) clearInterval(checkVisInterval);
            if (actionItemsListener) off(actionItemsListener);
            if (statsListener) statsListener();
            if (playerListListener) off(playerListListener);
            if (teamListener) teamListener();
            if (messageListener) messageListener();
        }
    }, [])

    useEffect(() => {
        if (!userProfile) {return;}
        getGameData()
    }, [userProfile])

    // useEffect(() => {
    //     if (!teamMembers || !gameElements || !playerData) return;
    //     let tempElements = {...gameElements};
    //     const playerTeams = 
    //         Object.values(teamMembers)
    //         .filter(m => m.members.includes(playerData.playerId))
    //         .map(m => m.teamId);
    //     // console.log(playerTeams);
    //     // console.log(teamMembers);
    //     for (let el of Object.values(tempElements)) {
    //         if (
    //             el.opt_prereqTeams && 
    //             el.opt_prereqTeams.length > 0
    //         ) {
    //             let included = false;
    //             for (let t of el.opt_prereqTeams) {
    //                 if (playerTeams.includes(t)) {
    //                     included = true;
    //                 }
    //             }
    //             if (!included) {
    //                 delete tempElements[el.id];
    //             }    
    //         }
    //     }
    //     setGameElements(tempElements);
    // }, [teamMembers, playerData, gameElements])

    useEffect(() => {
        if (!playerData || !gameElements || !gameData) return;
        checkZeroLevel();
    }, [playerData, gameElements, gameData])
    
    async function checkZeroLevel() {
        let zeroLevels = Object.values(gameElements)
        .filter(e => e.type === 'level' && e.opt_pointMinimum <= 0);
        for (let level of zeroLevels) {
            if (!playerData.levels || !playerData.levels[level.id] && !alreadyChecked.current) {
                alreadyChecked.current = true;
                const res = await UseCloudDistribute(
                    'checkLevel',
                    {
                        'playerData': playerData,
                        'howItWasEarned': 'Joined The Game',
                        'gameId': gameData.gameId
                    }
                )
                console.log(res);
            }
        }
    }

    function checkElementVisibility() {
        // console.log('checking visbility')
        if (!elementsRef.current) return;
        const now = new Date().getTime();
        let tempElements = {...elementsRef.current};
        for (let e of Object.values(tempElements)) {
            if (!e.opt_visible || (e.opt_dateOpen && now < e.opt_dateOpen) || (e.opt_dateClose && now > e.opt_dateClose)) {
                delete tempElements[e.id]
            }
        }
        elementsRef.current = tempElements;
        setGameElements(elementsRef.current);
    }

    async function signPlayerOut(playerId) {
        const res = await UseCloudFunction('resetPlayerSignOut', {'playerId': playerId})
        console.log(res);
        navigate('/logout');
    }

    function getGameData() {
        getGameDataFromDb({'gamePath': gamePath, 'status': 'active', 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            setGameData(data);
            changeDocumentTitle(`Gamably | ${data.gameName}`, data.icon);
            // change var(--primary) to game color
            var r = document.querySelector(':root');
            r.style.setProperty('--primary', data.color);
            r.style.setProperty('--primary-trans', data.color+'a6');
            getPlayerData(data.gameId);
            getGameElements(data.gameId);
            getGameStats(data.gameId);
            getPlayerList(data.gameId);
            getLatestMessage(data.gameId);
        }
        function handleListener(unsub) {
            gameListener = unsub;
        }
    }

    function getPlayerData(gameId) {
        getPlayerDataFromDb({'gameId': gameId, 'appId': userProfile.appId, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            if (data.status !== 'active') {
                navigate('/logout');
                return;
            }
            if (data.signout) {
                signPlayerOut(data.playerId);
                return;
            }
            setPlayerData(data);
            getActionItems(data.gameId, data.playerId);
            getTeamMembers(data.gameId, data.playerId);
            getGameActivity(data.gameId, data.playerId);
        }
        function handleListener(unsub) {
            playerListener = unsub;
        }
    }

    function getGameElements(gameId) {
        getGameElementsFromDb({'gameId': gameId, 'callback': callback, 'handleListener': handleListener})
        function callback(data) {
            let elements = {...data.elements};
            console.log('element update')
            const now = new Date().getTime();
            for (let el of Object.values(elements)) {
                if (
                    el.status !== 'active' ||
                    el.type === 'secretCode' || 
                    !el.opt_visible
                ) {
                    delete elements[el.id];
                }
            }
            elementsRef.current = {...elementsRef.current, ...elements}
            setGameElements(elementsRef.current);
            // checkElementVisibility();
        }
        function handleListener(unsub) {
            elementsListener = unsub;
        }
    }

    function getGameActivity(gameId, playerId) {
        getGameActivityFromRtDb({
            "gameId": gameId,
            "callback": callback,
            "handleListener": handleListener
        })
        function callback(data) {
            let activityObj = {};
            for (let a of Object.values(data)) {
                let temp = {...a}
                if (temp.playerId !== playerId && temp.type === 'secretCode') {
                    delete temp.title
                }
                activityObj[temp.id] = temp;
            }
            activityRef.current = {...activityRef.current, ...activityObj}
            setGameActivity(activityRef.current);
        }
        function handleListener(unsub) {
            activityListener = unsub
        }
    }

    function getActionItems(gameId, playerId) {
        getActionItemsFromRtDb({
            'gameId': gameId, 
            'playerId': playerId,
            'callback': callback, 
            'handleListener': handleListener
        })
        function callback(data) {
            actionItemsRef.current = {...actionItemsRef.current, ...data}
            setActionItems(actionItemsRef.current);
        }
        function handleListener(unsub) {
            actionItemsListener = unsub
        }
    }

    function getGameStats(gameId) {
        getUniqueDocumentFromDb({
            'collection': 'gameStats',
            'docId': gameId,
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data) {
            setGameStats(data);
        }
        function handleListener(unsub) {
            statsListener = unsub;
        }
    } 

    function getPlayerList(gameId) {
        getPlayersFromPlayerDocs({
            'gameId': gameId,
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data) {
            playerListRef.current = {
                ...playerListRef.current, 
                ...data
            }
            let playerObj = {}
            for (let p of Object.values(playerListRef.current)) {
                if (
                    p.status === 'active' &&
                    !p.team
                ) {
                    playerObj[p.playerId] = p
                }
            }
            setPlayerList(playerObj)
        }
        function handleListener(unsub) {
            playerListListener = unsub;
        }
    }

    function getTeamMembers(gameId, playerId) {
        getUniqueDocumentFromDb({
            "collection": "teamMembers",
            "docId": gameId,
            "callback": callback,
            "handleListener": handleListener
        })
        function callback(data) {
            if (!data) return;
            setTeamMembers(data);
            let myTeamArr = [];
            for (let team of Object.values(data)) {
                if (team.members && team.members.includes(playerId)) {
                    myTeamArr.push(team.path);
                }
            }
            setMyTeams([...myTeamArr]);
        }
        function handleListener(unsub) {
            teamListener = unsub;
        }
    }

    async function getLatestMessage(gameId) {
        getLatestGameMessageFromDb({
            'gameId': gameId,
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data) {
            setLatestMessage(data)
        }
        function handleListener(unsub) {
            messageListener = unsub;
        }
    }

    return (
        <div>
        {
            (gameData && playerData) 
            ?
            <div className='play-game-page' style={
                (gameData.bg.type === 'gradient')
                ?
                {'background': `linear-gradient(to bottom, ${gameData.bg.hexTop}, ${gameData.bg.hexBottom})`}
                :
                {'background': `url("${gameData.bg.url}") no-repeat center center fixed`}
            }>
                <Desktop>
                    <PlayHeadBar 
                        gameData={gameData}
                        playerData={playerData} 
                    />
                    <div className='play-game-container'>
                        <div className='play-game-content'>
                            <PlayerGameContent 
                                gameData={gameData} 
                                playerData={playerData} 
                                gameElements={gameElements} 
                                gameActivity={gameActivity}
                                actionItems={actionItems} 
                                gameStats={gameStats}
                                playerList={playerList}
                                teamMembers={teamMembers}
                                myTeams={myTeams}
                                latestMessage={latestMessage}
                            />
                        </div>
                    </div>
                </Desktop>

                <LargeTablet>
                    <PlayerGameContent 
                        gameData={gameData} 
                        playerData={playerData} 
                        gameElements={gameElements} 
                        gameActivity={gameActivity}
                        actionItems={actionItems} 
                        gameStats={gameStats}
                        playerList={playerList}
                        teamMembers={teamMembers}
                        myTeams={myTeams}
                        latestMessage={latestMessage}
                    />
                </LargeTablet>
                
                <Tablet>
                    <PlayerGameContent 
                        gameData={gameData} 
                        playerData={playerData} 
                        gameElements={gameElements} 
                        gameActivity={gameActivity}
                        actionItems={actionItems} 
                        gameStats={gameStats}
                        playerList={playerList}
                        teamMembers={teamMembers}
                        myTeams={myTeams}
                        latestMessage={latestMessage}
                    />
                </Tablet>

                <Mobile>
                    <PlayHeadBar gameData={gameData} />
                    <PlayerGameContent 
                        gameData={gameData} 
                        playerData={playerData} 
                        gameElements={gameElements} 
                        gameActivity={gameActivity}
                        actionItems={actionItems} 
                        gameStats={gameStats}
                        playerList={playerList}
                        teamMembers={teamMembers}
                        latestMessage={latestMessage}
                    />
                </Mobile>
            </div>
            :
            <div className='loading-page'>
                <Loader />
            </div>
        }
        </div>
    )
}

export default PlayGamePage