import React, { useState } from 'react'
import './sidebar-left.styles.scss'

import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faDice, faGear, faMountainCity, faSliders } from '@fortawesome/free-solid-svg-icons';
import { mediaQueries } from '@/utils/responsiveQueries';
import { useMediaQuery } from 'react-responsive';
import InfoLinks from '@/components/SHARED/info-links/info-links.component';

const SidebarLeft = ({ toggleMenu, orgId, setShowBugModal }) => {

    const [ viewDrop, setViewDrop ] = useState(false);
    const isMobileDevice = useMediaQuery({[mediaQueries.mobile.type]: mediaQueries.mobile.size});
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div>
            <div className={`sidebar-container ${isMobileDevice ? 'mobile' : ''}`}>

                
                <div className="g-height-20"></div>
                {/* <div className="button-container">
                    
                    <button className={`start-button ${showSearchModal ? 'clicked' : ''}`} onClick={() => setShowSearchModal(true)}>
                        <span className="circle" aria-hidden="true">
                            <span className="icon arrow"></span>
                        </span>
                        <span className="button-text">Get Started</span>
                    </button>
                </div> */}
                <div className={`drop-container ${viewDrop ? 'active' : ''}`}>
                    <div className="drop-menu">
                        
                        {/* <div className="drop-menu-item" id="newFolder" onClick={() => setShowSearchModal(true)}> 
                            <img src={require('assets/icons/play.png')} className="drop-menu-item-icon" />
                            Add To WatchList
                        </div>
                        <div className="drop-menu-item" id="newFolder" onClick={() => navigate('/home/newsuggestion')}> 
                            <img src={require('assets/icons/suggestion.png')} className="drop-menu-item-icon" />
                            Recommend
                        </div>
                        <div className="drop-menu-item" id="newFolder" onClick={() => navigate('/home/newchat')}> 
                            <img src={require('assets/icons/chat.png')} className="drop-menu-item-icon" />
                            New Chat
                        </div> */}
                        
                        
                    </div>
                </div>
                <div className="g-height-20"></div>
                <div className="menu-container">
                    {/* <div className='menu-item' id='home-menu-home' onClick={() => navigate(`/home`)}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faHouse} size='lg' />
                        Home
                    </div> */}
                    <div className={`menu-item ${location.pathname.includes('/home/managing') ? 'active' : ''}`} onClick={() => navigate('/home/managing')}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faSliders} size='lg' />
                        Games I Manage
                    </div>
                    <div className={`menu-item ${location.pathname.includes('/home/enrolled') ? 'active' : ''}`} onClick={() => navigate(`/home/enrolled`)}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faDice} size='lg' />
                        Games I'm Playing
                    </div>
                    <div className={`menu-item ${location.pathname.includes('/home/myorgs') ? 'active' : ''}`} onClick={() => navigate('/home/myorgs')}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faMountainCity} size='lg' />
                        My Orgs
                    </div>
                    <div className={`menu-item ${location.pathname.includes('/home/settings') ? 'active' : ''}`} onClick={() => navigate('/home/settings')}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faGear} size='lg' />
                        Settings
                    </div>
                    <div className="menu-item" id='home-menu-bugs' onClick={() => setShowBugModal(true)}>
                        <FontAwesomeIcon className='menu-item-icon' icon={faBug} size='lg' />
                        Report a Bug
                    </div>
                    <div className="g-space-1"></div>
                    <InfoLinks />
                </div>
            </div>
            
        </div>
    )
}

export default SidebarLeft;