import React, { useRef, useState } from 'react';
import './bug-modal.styles.scss';

import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import { UseCloudFunction } from '@/utils/firebase.utils';
import UIkit from 'uikit';
import Modal, { ModalAlert } from '@/components/modal/modal.component';
import { resizeImage } from '@/utils/resizeImage';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';

const BugModal = ({ cancel, show }) => {

    const profile = useSelector(state => state.userData.userProfile);
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ image, setImage ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const dataRef = useRef();
    const imageRef = useRef();
    const storage = getStorage();

    function clickUpload() {
        document.getElementById('bug-image-upload').value = null;
        document.getElementById('bug-image-upload').click();
    }

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current)
    }

    function handleUpload(file) {
        console.log(file);
        setImage({
            "file": file,
            "name": file.name
        })
    }
    //     function cancelUpload() {
    //         document.getElementById('fileUpload').value = null;
    //         saveData('icon', null);
    //         // setFileName(null);
    //         return;
    //     }
    //     const ext = file.name.match(/\.([^\.]+)$/)[1].toLowerCase();
    //     console.log(ext);
    //     const allowed = ['jpg','bmp','png','gif','tif','webp']
    //     if (!allowed.includes(ext)) {
    //         setAlertMessage('That file type is not allowed to be uploaded. Try a different file.');
    //         cancelUpload();
    //         return;
    //     }
    //     console.log(file.size);
    //     if (file.size > 2 * 1024 * 1024) {
    //         setAlertMessage('That file is too large to be uploaded. You cannot upload files larger than 2MB.');
    //         cancelUpload();
    //         return;
    //     }
    //     setFile(file);
    // }

	// async function setFile(file) {
	// 	// document.getElementById('selected-image').classList.add('hide')
	// 	console.log(file.type)
	// 	if (file.type.match(/image.*/)) {
	// 		const fileType = file.type;
	// 		const fileName = file.name;
	// 		console.log('An image has been loaded');
	// 		console.log(fileType)
	// 		// Load the image
	// 		if (fileType.indexOf('gif') == -1) {
	// 			const newFile = await resizeImage(file);
    //             const newUrl = window.URL.createObjectURL(file);
	// 			saveData('icon', {upload: true, url: newUrl, file: newFile, type: fileType, name: fileName})
    //             console.log(newFile);
	// 			return;
	// 		}
    //         // setAlertMessage('Please upload an image file.')
	// 		// document.getElementById('selected-image').classList.remove('hide')
    //         // saveData('icon', null);
	// 		const newFile = window.URL.createObjectURL(file)
	// 		saveData('icon', {upload: true, url: newFile, type: fileType, name: fileName, file: file})
	// 		return;
	// 	}
	// 	setAlertMessage('Please upload an image file.')
	// 	return;
    // }

    async function handleSubmit() {
        setLoading(true);
        const now = new Date().getTime();
        const file = document.getElementById('bug-image-upload').files[0]
        let newURL = '';
        if (file) {
            console.log(file.size)
            const resizedImage = await resizeImage(file)
            console.log(resizedImage.size)
            const bugStorageRef = ref(storage, `uploaded-bug-images/${now}-${file.name}`)
			const metadata = { contentType: resizedImage.type }
			await uploadBytes(bugStorageRef, resizedImage, metadata)
			.then(snap => console.log(snap))
			newURL = await getDownloadURL(bugStorageRef)
			console.log(newURL)
        }
        const obj = {...dataRef.current}
        obj.imageURL = newURL;
        obj.email = profile.email;
        obj.appId = profile.appId;
        obj.fName = profile.fName;
        obj.lName = profile.lName;
        obj.status = 'active';
        obj.ts = now;
        obj.ts_created = now;
        console.log(obj);
        const res = await UseCloudFunction('saveBugReport', {'bugObj': obj})
        console.log(res)
        if (res.success) {
            dataRef.current = {}
            setFormData(dataRef.current)
            document.getElementById('bug-image-upload').value = null;
            cancel();
            setAlertMessage('Thank you for reporting this bug. Someone will be in contact with you soon!');
        } else {
            setAlertMessage('Something went wrong. Please try again later. ' + res.error);
        }
        setLoading(false);
	}

    return (
        <div className='bug-modal'>
            <Modal cancel={cancel} show={show} text={`Report a Bug`} closeButton={true} >
                <div className='modal-children bug-report-container'>
                    <p>Thanks for reporting this bug. Your user information has already been collected, but please add details below to describe the issue. You can also upload a screenshot if that will help clarify the issue. Thanks for your help!</p>
                    <div className='field'>
                        <label>Give this bug a short title<span className='required'>*</span></label>
                        <input type='text' value={formData.title ?? ''} onChange={(e) => saveData('title', e.target.value)} />
                    </div>
                    <div className="g-space-1"></div>
                    <div className='field'>
                        <label>Describe the issue<span className='required'>*</span></label>
                        <textarea rows="4" value={formData.desc ?? ''} onChange={(e) => saveData('desc', e.target.value)}></textarea>
                    </div>
                    <div className="g-space-1"></div>
                    <div className='upload'>
                        <div style={{cursor: 'pointer'}}>
                            <input type="file" id="bug-image-upload" accept="image/*" onChange={(e) => handleUpload(e.target.files[0])}  />
                            <button className="g-button" type="button" tabIndex="-1" onClick={() => clickUpload()}>Upload a Screenshot</button>
                        </div>
                        <div>
                            {
                                (!image || !image.name)
                                ?
                                <p>Nothing uploaded yet...</p>
                                :
                                <div>{
                                    image.name.length > 30
                                    ?
                                    image.name.substring(0, 30) + '...'
                                    :
                                    image.name
                                }</div>
                            }
                        </div>
                    </div>
                    <hr />
                    <div className='buttons'>
                        {
                            (!loading)
                            ? <button className='g-button' onClick={() => handleSubmit()}>Submit Report</button>
                            : <button className='g-button static'><ThreeDotsLoader /></button>
                        }
                    </div>
                </div>
            </Modal>
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
        </div>
    )
}

export default BugModal;