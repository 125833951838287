import React, { useState } from 'react';
import './prize-pack-weights.styles.scss';
import Modal from '@/components/modal/modal.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import QuantityInput from '@/components/PLAYER/store/store-components/quantity-input.component';

const ManageContentPrizePackWeights = ({
    saveData,
    formData,
    gameElements
}) => {

    const [ showInfoModal, setShowInfoModal ] = useState(false);

    function changeWeight(id, weight) {
        console.log(id, weight);
        let tempWeights = {...formData.opt_prizeWeights};
        tempWeights[id].weight = weight;
        saveData('opt_prizeWeights', tempWeights);
    }

    return (
        <div className='manage-content-pp-weights g-list-item form-section'>
            <h3>Assign Weighting to Selected Prizes</h3>
            <p>To determine the rarity of each item, each item is assigned 
                a <b>Weight Rating</b> between 1 and 20 with 1 being very rare and 20 being very common. 
                <button 
                    className='g-button text-only inline'
                    onClick={() => setShowInfoModal(true)}>Learn more...</button>
            </p>
            <div className='g-space-1'></div>
            <div className='selected-prizes-list'>
                {
                    (formData && formData.opt_prizeWeights && gameElements) &&
                    Object.values(formData.opt_prizeWeights)
                    .sort((a,b) => 
                        gameElements[a.id].name.toLowerCase() > 
                        gameElements[b.id].name.toLowerCase() 
                        ? 1 
                        : -1
                    ).map(prize => {
                        const el = gameElements[prize.id];
                        return (
                            <div key={prize.id} className='g-list-item selected-prize-item'>
                                <div className='weight-icon-div'>
                                    <ImageContainer
                                        src={el.icon}
                                        className='weight-icon' />
                                </div>
                                <div>{el.name}</div>
                                <div className='meta'>{el.opt_cat}</div>
                                <div className='meta'>{el.opt_itemType}</div>
                                <QuantityInput
                                    initialValue={prize.weight}
                                    max={20}
                                    min={1}
                                    onQuantityChange={(e) => changeWeight(prize.id, e)} />
                            </div>
                        )
                    })
                }
                <div className='g-space-1'></div>
            </div>
            <Modal
                show={showInfoModal}
                cancel={() => setShowInfoModal(false)} >
                <div className='weight-info-modal'>
                    <div className='weight-info-image'>
                        <ImageContainer
                            src="/images/icons/mysterytreasure.png"
                            className="weight-image" />
                    </div>
                    <h2>What is a Weight Rating?</h2>
                    <p>
                        The <b>Weight Rating</b> is a number between 1 and 20 to determine just how common a prize 
                        is in the packs your players receive. A rating of 1 will make a prize extremely rare, 
                        while a rating of 20 will make it very common.
                    </p>
                    <p>
                        The rarity of a prize is based also on the number of items you have available for the Prize Packs. 
                        If you only have two items, one has a rating of 1 and the other a rating of 20, 
                        there is a 1/21 chance a player will receive the rare item. 
                        However, if you have three items, one with a 1 rating, one with a 10 rating, 
                        and one with a 20 rating, there will be a 1/31 chance that a player will receive the rare item.
                    </p>
                    <div className='buttons'>
                        <button 
                            className='g-button med-btn'
                            onClick={() => setShowInfoModal(false)}>
                            Done
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ManageContentPrizePackWeights