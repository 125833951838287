import React, { useEffect, useRef, useState } from 'react'
import './challenges.styles.scss';
import { switchPlayerMenu } from '../side-menu.component';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import CategoryFilterModal from '@/components/MANAGE-GAME/create-elements/components/category-filter-modal.component';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PlayerChallengeItem from './player-challenge-item.component';
import PlayerChallengePage from './player-challenge-page.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { prereqFilter } from '@/utils/prereqFilter';

const PlayerChallenges = ({ gameData, playerData, gameElements, actionItems }) => {

    const [ challengesToDisplay, setChallengesToDisplay ] = useState(null); 
    const [ availableChallenges, setAvailableChallenges ] = useState(null);
    const [ showFilterModal, setShowFilterModal ] = useState(false);
    const [ selectedCategories, setSelectedCategories ] = useState([]);
    const [ categoryList, setCategoryList ] = useState([]);
    const catRef = useRef();
    const intRef = useRef();
    const navigate = useNavigate();
    
    useEffect(() => {
        // document.getElementById(`head-logo`).scrollIntoView({'behavior': 'smooth'})
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
            pageTitle.innerText = 'Challenges'
        }
        switchPlayerMenu('challenges-btn');
        catRef.current = [];

        return () => {
            if (intRef.current) clearInterval(intRef.current);
        }
        
    }, []);

    useEffect(() => {
        if (!gameElements) return;
        displayChallenges();
        if (intRef.current) return;
        intRef.current = setInterval(() => {
            displayChallenges();
        }, 60000);
    }, [gameElements, actionItems])

    function displayChallenges() {
        // console.log('display')
        const challengeArr = Object.values(gameElements).filter(e => e.type === 'challenge')
        let filteredArr = []
        const sevenDaysFuture = new Date(new Date().setDate(new Date().getDate() + 7)).getTime();
        const sevenDaysPast = new Date(new Date().setDate(new Date().getDate() - 7)).getTime();
        for (let c of challengeArr) {
            // const access = prereqFilter(c, playerData, gameElements)
            // let flags = []
            // let early = c.opt_dateOpen && c.opt_dateOpen > new Date().getTime();
            // let late = c.opt_dateClose && c.opt_dateClose < new Date().getTime();
            
            // if (!access) {
            //     flags.push('Closed');
            // }
            // if (!access && c.opt_preview) {
            //     flags.push('Preview');
            // }
            // if (!access && early && c.opt_dateOpen < sevenDaysFuture) {
            //     flags.push('Coming Soon');
            // }
            // if (access && !early && c.opt_dateOpen && c.opt_dateOpen > sevenDaysPast) {
            //     flags.push('New');
            // }
            // if (access && !early && c.opt_dateClose && c.opt_dateClose < sevenDaysFuture) {
            //     flags.push('Closing Soon');
            // }
            // c.flags = flags;
            // c.access = access;
            // if (actionItems) {
            //     const relatedActionItems = Object.values(actionItems).filter(i => i.elementId === c.id)
            //     for (let r of relatedActionItems) {
            //         if (!c.opt_multiple && r.status === 'approved') {
            //             c.flags=['Completed'];
            //         }
            //         if (r.status === 'pending') {
            //             c.flags=['Pending'];
            //         }
            //         if (r.status === 'declined') {
            //             c.flags = ['Declined'];
            //         }
            //     }
            // }
            filteredArr.push(c);
        }
        setAvailableChallenges([...filteredArr]);
        setChallengesToDisplay([...filteredArr]);
        let arr = [];
        for (let c of filteredArr) {
            if (c.opt_cat && !arr.includes(c.opt_cat)) {
                arr.push(c.opt_cat)
            }
            // if (c.flags) {
            //     for (let f of c.flags) {
            //         if (!arr.includes(f)) {
            //             arr.push(f);
            //         }
            //     }
            // }
        }
        if (arr.length > 0) {
            arr.sort();
            arr.push('Uncategorized');
        }
        for (let c of arr) {
            let catTitle = c
            if (catTitle) {
                arr[c] = catTitle
            }
        }
        setCategoryList([...new Set(arr)]);
    }

    function search(e) {
        if (!e) {
            setChallengesToDisplay([...availableChallenges]);
            return;
        }
        setChallengesToDisplay(availableChallenges.filter(a => a.type === 'challenge' && (a.name.toLowerCase().includes(e) || a.desc && a.desc.toLowerCase().includes(e))))
    } 

    function chooseCategory(e) {
        let catList = [...catRef.current];
        if (catList.includes(e)) {
            catList.splice(catList.indexOf(e), 1)
        } else {
            catList.push(e)
        }
        catRef.current = catList;
        setSelectedCategories(catRef.current);
        if (catRef.current.length > 0) {
            setChallengesToDisplay(availableChallenges.filter(a => a.type === 'challenge' && (catRef.current.includes(a.opt_cat) || (a.flags && a.flags.some(f => catRef.current.includes(f))))))
        } else {
            setChallengesToDisplay(availableChallenges.filter(a => a.type === 'challenge'))
        }
    }

    return (
        <div className='player-challenges'>
            <div className='player-challenges-content'>
                <Routes>
                    <Route path='' element={
                        <div className='g-card'>
                            <div className='player-title'>
                                <ImageContainer 
                                    src='/images/icons/win.png' 
                                    alt='complete a challenge icon' 
                                    className='player-title-icon' 
                                />
                                Complete a Challenge
                            </div>
                            <hr />
                            <div className='player-challenge-list-search'>
                                <SearchBar search={search} />
                                <button 
                                    type='button' 
                                    className='g-button med-btn' 
                                    onClick={() => setShowFilterModal(true)}
                                >
                                    Filter by Category
                                    <FontAwesomeIcon 
                                        icon={faFilter}
                                        className={selectedCategories.length > 0 ? 'full' : 'empty'} 
                                    />
                                </button>
                            </div>
                            <div className="g-space-1"></div>
                            <div className='player-challenge-list-container'>
                                {
                                    (categoryList.length > 0 && challengesToDisplay) 
                                    ?
                                    categoryList.sort().map(c => {
                                        // console.log(c);
                                        if (c != 'Uncategorized' && Object.values(challengesToDisplay).filter(chall => chall.opt_cat === c).length > 0) {
                                            return (
                                                <div key={c}>
                                                    <h3>{c}</h3>
                                                    <hr/>
                                                    <div className='player-challenge-list'>
                                                        {
                                                            (challengesToDisplay) &&
                                                            challengesToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                                            .filter(chall => chall.opt_cat === c)
                                                            .map(chall => {
                                                                return (
                                                                    <div key={chall.id} >
                                                                        <PlayerChallengeItem 
                                                                            challenge={chall} 
                                                                            playerData={playerData} 
                                                                            gameElements={gameElements} 
                                                                            onClick={() => navigate(chall.path)} 
                                                                            actionItems={actionItems}
                                                                        />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className="g-space-2"></div>
                                                </div>
                                            )
                                        }
                                        else if (c === 'Uncategorized' && Object.values(challengesToDisplay).filter(chall => !chall.opt_cat).length > 0) {
                                            return (
                                                <div key={c}>
                                                    <h3>{c}</h3>
                                                    <hr/>
                                                    <div className='player-challenge-list'>
                                                    {
                                                        (challengesToDisplay) &&
                                                        challengesToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                                        .filter(chall => !chall.opt_cat)
                                                        .map(chall => {
                                                            return (
                                                                <div key={chall.id} >
                                                                    <PlayerChallengeItem 
                                                                        challenge={chall} 
                                                                        playerData={playerData} 
                                                                        gameElements={gameElements} 
                                                                        onClick={() => navigate(chall.path)} 
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                    :
                                    (challengesToDisplay) 
                                    ?
                                    <div className='player-challenge-list'>
                                        {
                                            challengesToDisplay.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                            .map(c => {
                                                return (
                                                    <div key={c.id} >
                                                        <PlayerChallengeItem 
                                                            challenge={c} 
                                                            playerData={playerData} 
                                                            gameElements={gameElements} 
                                                            onClick={() => navigate(c.path)} 
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    <div className='center'>
                                        <h3>No Challenges available...</h3>
                                    </div>
                                }
                                
                            </div>
                            
                        </div>
                    }></Route>
                    <Route path=':challengePath' element={
                        <PlayerChallengePage 
                            gameData={gameData} 
                            gameElements={gameElements} 
                            playerData={playerData} 
                            actionItems={actionItems} 
                        />
                    }></Route>
                </Routes>
            </div>
            <div className="g-space-2"></div>
            <CategoryFilterModal
                show={showFilterModal} 
                cancel={() => setShowFilterModal(false)} 
                chooseCategory={chooseCategory} 
                categoryList={categoryList} 
                selectedCategories={selectedCategories} 
                color='var(--primary)'
            />
        </div>
    )
}

export default PlayerChallenges