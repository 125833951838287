import React, { useEffect, useRef, useState } from 'react';
import './cart-item-card.styles.scss';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import QuantityInput from './quantity-input.component';
import { ModalConfirm } from '@/components/modal/modal.component';
import ItemPrice from '@/components/SHARED/item-price/item-price.component';
import { properCase } from '@/utils/properCase';

const CartItemCard = ({ 
    cartItem, 
    itemData, 
    changeQuantity, 
    removeItem, 
    saleList, 
    gameElements 
}) => {

    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const approveRef = useRef();

    function saveChangedQuantity(newQuantity) {
        changeQuantity(newQuantity, cartItem.id);
    }

    function saveRemovedItem() {
        setConfirmMessage('Are you sure you want to remove this item from your shopping cart?')
        approveRef.current = () => {
            removeItem(cartItem.id);
        }
    }

    return (
        <div className='cart-item-card g-list-item'>
            {
                (cartItem && itemData) &&
                <div className='cart-item-grid'>
                    <div className='card-icon'>
                        <ImageContainer src={itemData.icon} className='icon' />
                    </div>
                    <div className='card-text'>
                        <div className='item-name'>{itemData.name}</div>
                        <div className='meta'>{properCase(itemData.opt_itemType)}</div>
                        {/* <div className='item-quantity'>Quantity: {cartItem.quantity}</div> */}
                        <div className='item-price'>
                            Unit Price: 
                            <ItemPrice 
                                item={itemData} 
                                saleList={saleList} 
                                gameElements={gameElements}
                            />
                        </div>
                        <div className='item-quantity'>
                            <QuantityInput 
                                initialValue={cartItem.quantity}
                                max={40}
                                min={1}
                                onQuantityChange={saveChangedQuantity} 
                            />
                            <button 
                                className='g-button text-only meta'
                                onClick={() => saveRemovedItem()}    
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                    <div className='card-total'>
                        Total: 
                        <ImageContainer 
                            src="/images/icons/coin.png" 
                            className="coin-icon" 
                        />
                        <b>{(cartItem.quantity * cartItem.cost).toLocaleString()}</b>
                    </div>
                </div>
            }
            <ModalConfirm 
                show={confirmMessage} 
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current}
            />
        </div>
    )
}

export default CartItemCard