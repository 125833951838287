import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './create-edit-game-container.styles.scss';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import CreateEditForm1 from './create-edit-form-1.component';
import Layout from '@/components/layout-auth/layout.comp';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useDispatch, useSelector } from 'react-redux';
import CreateEditForm2 from './create-edit-form-2.component';
import CreateEditForm3 from './create-edit-form-3.component';
import CreateEditForm4 from './create-edit-form-4.component';
import CreateEditFormReview from './create-edit-form-review.component';
import { setManagingList, setTempGame } from '@/state/slices/contentSlice';
import CreateEditForm2v2 from './create-edit-form-2v2.component';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { RandomString } from '@/utils/randomString';
import { membershipRestrictions, testMemberRestrictions } from '@/utils/membershipRestrictions';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';

const CreateEditGameContainer = () => {

    const profile = useSelector(state => state.userData.userProfile);
    const managingList = useSelector(state => state.contentData.managingList);
    const membership = useSelector(state => state.userData.membership);
    const [ formData, setFormData ] = useState({joinCode: null})
    const [ validCode, setValidCode ] = useState(true);
    const [ height, setHeight ] = useState(`${window.height - 70}px`);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ title, setTitle ] = useState('Create a New Game');
    const [ loading, setLoading ] = useState(false);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(false);
    const [ joinCodeEdited, setJoinCodeEdited ] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const dataRef = useRef();
    const approveRef = useRef();
    const storage = getStorage();

    useEffect(() => {
        const cardHeight = document.getElementById('new-game-form').clientHeight
        setHeight(`${cardHeight + 117}px`);
        document.getElementById('scroll-point').scrollIntoView({'behavior': 'smooth', 'top': '-20px'});
        switch (location.pathname) {
            case '/creategame/part2':
                setTitle('Design the Game Elements');
                break;
            case '/creategame/part3':
                setTitle('Choose the Game Mechanics');
                break;
            case '/creategame/part4':
                setTitle('Choose How Players Join');
                break;
            case '/creategame/review':
                setTitle('Review Before Finalizing');
                break;
            default:
                setTitle('Create a New Game');
        }
    }, [location])

    useEffect(() => {
        if (!document.getElementById('new-game-form')) return;
        setTimeout(() => {
            let card = document.getElementById('new-game-form');
            if (!card) return;
            const cardHeight = card.clientHeight
            setHeight(`${cardHeight + 117}px`);
        }, 50);
    }, [formData])

    useEffect(() => {
        const tempData = JSON.parse(window.localStorage.getItem('tempData'))
        if (tempData) {
            dataRef.current = tempData;
        } else {
            dataRef.current = {joinCode: null};
        }
        setFormData(dataRef.current);
        console.log(dataRef.current);
        checkMembership();
    }, [])
    
    function checkMembership() {
        const res = testMemberRestrictions({'gameCount': Object.values(managingList).length, 'membership': membership});
        if (res.disabled && res.disabledReason.includes('games')) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.gameCount} active Games.`;
            setShowUpgradeModal(message);
            return;
        }
    }
    // useEffect(() => {
    //     if (!tempGame) { return; }
    //     dataRef.current = tempGame;
    //         setFormData(dataRef.current);
    // }, [tempGame])

    function saveData(key, value) {
        if (key === 'enroll_limitList' && !value) {
            let tempObj = {...dataRef.current};
            delete tempObj[key];
            console.log(tempObj)
            dataRef.current = tempObj;
            setFormData(dataRef.current);
            return;
        }
        if (key === 'gameName' && !joinCodeEdited) {
            const convertedCode = value.toLowerCase().replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '-')
            saveData('joinCode', convertedCode);
        }
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
        console.log(dataRef.current);
        window.localStorage.setItem('tempData', JSON.stringify(dataRef.current));
    }

    function cancel() {
        setConfirmMessage('Are you sure you want to stop creating this game? All of your progress so far will be lost.');
        approveRef.current = approveCancel;
        function approveCancel() {
            dataRef.current = {};
            setFormData(dataRef.current);
            window.localStorage.removeItem('tempData');
            // dispatch(setTempGame(null));
            navigate('/home/managing');
        }
    }

    async function verifyAndMoveOn(req, path) {
        setLoading(true);
        const incomplete = req.filter(r => !formData[r]).length > 0
        if (incomplete) {
            setAlertMessage('Please complete the required parts of the form before moving on.')
            setLoading(false);
            return;
        }
        else {
            console.log(formData)
            if (path === 'part2') {
                const joinCodeIsAvailable = await UseCloudFunction('checkJoinCode', {'joinCode': formData.joinCode, 'appId': profile.appId})
                setLoading(false);
                if (!joinCodeIsAvailable.result) {
                    setValidCode(false)
                    return;
                }
            }
            
            if (path === 'final') {
                setConfirmMessage(`Are you sure you're ready to save this game?`);
                approveRef.current = approveSave;
                async function approveSave() {
                    setLoading(true);
                    const now = new Date().getTime()
                    const newObj = {...formData, ...{
                        'ts': now,
                        'gameId': `${now}-${profile.appId.substring(0,7)}-game`,
                        'path': `${RandomString(8).toLowerCase() + now}`,
                        'creatorId': profile.appId,
                        'creatorName': `${profile.fName} ${profile.lName}`,
                        'ts_created': now,
                        'status': 'active'
                    }};
                    if (newObj.icon.upload) {
                        const newUrl = await uploadImage(newObj.icon.file, newObj.gameId, 'game-icon');
                        newObj.icon = newUrl;
                    } else {
                        newObj.icon = newObj.icon.url;
                    }
                    if (newObj.header.upload) {
                        const newUrl = await uploadImage(newObj.header.file, newObj.gameId, 'game-icon');
                        newObj.header = newUrl;
                    } else {
                        newObj.header = newObj.header.url;
                    }
                    const res = await UseCloudFunction('saveGame', {'gameObj': newObj, 'appId': profile.appId, 'action': 'new game'})
                    if (res.error) {
                        setAlertMessage('Something went wrong. Please try again later.');
                        setLoading(false);
                        return;
                    }
                    dispatch(setManagingList({...managingList, ...{[newObj.gameId]: newObj}}))
                    setLoading(false);
                    window.localStorage.removeItem('tempData');
                    dataRef.current = {};
                    setFormData(dataRef.current);
                    navigate(`/manage/${newObj.path}`)
                }
            } else {
                setLoading(false);
                navigate(path)
            }
        }
    }

    async function uploadImage(blob, gameId, type) {		
		const now = new Date().getTime();
		const profileRef = ref(storage, `${gameId}/${now}-${type}`)
		const metadata = {
			customMetadata: {
                'appId': profile.appId,
                'gameId': gameId,
            }
		};
		return uploadBytes(profileRef, blob, metadata).then(async (snapshot) => {
			const newUrl = await getDownloadURL(profileRef)
			.then((url) => {
				return url;
			})
            return newUrl;
		})
	}

    return (
        <div className='create-edit-game-container'>
            {/* <Layout> */}
                <div className='g-card' style={{'height': height}} >
                    <div className='section-title'>
                        {title}
                    </div>
                    <hr className='no-top-margin' />
                    <div>
                        <Routes>
                            <Route path='' element={
                                <CreateEditForm1 
                                    formData={formData} 
                                    saveData={saveData} 
                                    verifyAndMoveOn={verifyAndMoveOn} 
                                    cancel={cancel} 
                                    validJoinCode={validCode} 
                                    loading={loading}
                                    setJoinCodeEdited={setJoinCodeEdited} 
                                />
                            }></Route>
                            <Route path='part2' element={
                                <CreateEditForm2v2 
                                    formData={formData} 
                                    saveData={saveData} 
                                    verifyAndMoveOn={verifyAndMoveOn} 
                                    cancel={cancel} 
                                />
                            }></Route>
                            <Route path='part3' element={
                                <CreateEditForm3 
                                    formData={formData} 
                                    saveData={saveData} 
                                    verifyAndMoveOn={verifyAndMoveOn} 
                                    cancel={cancel} 
                                />
                            }></Route>
                            <Route path='part4' element={<CreateEditForm4 formData={formData} saveData={saveData} verifyAndMoveOn={verifyAndMoveOn} cancel={cancel} />}></Route>
                            <Route path='review' element={<CreateEditFormReview formData={formData} saveData={saveData} verifyAndMoveOn={verifyAndMoveOn} cancel={cancel} loading={loading} />}></Route>
                        </Routes>
                        
                    </div>
                </div>
                <div className='g-space-20'></div>
            {/* </Layout> */}
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <UpgradeRequiredModal 
                show={showUpgradeModal} 
                cancel={() => setShowUpgradeModal(false)} 
                closeButton={false} 
                message={showUpgradeModal} 
            />
        </div>
    )
}

export default CreateEditGameContainer