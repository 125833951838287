import React, { useEffect, useRef, useState } from 'react'
import './player-market-landing.styles.scss'
import { switchPlayerMenu } from '../side-menu.component';
import { useNavigate } from 'react-router-dom';
import PlayerStore from './player-store.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import PlayerTradePortal from './player-trade-portal.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubesStacked, faDice, faExchange, faGift, faGlobeAmericas, faPiggyBank, faTicket } from '@fortawesome/free-solid-svg-icons';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import PlayerCollectibles from './player-collectibles.component';
import StoreButtonRow from './store-components/store-button-row.component';

const PlayerMarketLanding = ({ 
    gameData, 
    playerData, 
    gameElements, 
    gameStats,
    teamMembers
}) => {

    const [ itemType, setItemType ] = useState('inventory');
    const [ availableItems, setAvailableItems ] = useState(null);
    const [ saleList, setSaleList ] = useState([]);
    const navigate = useNavigate();
    
    useEffect(() => {
        const pageTitle = document.getElementById('page-title');
        if (pageTitle) {
            pageTitle.innerText = 'Shop the Marketplace'
        }
        switchPlayerMenu('store-btn');
    }, []);

    useEffect(() => {
        console.log(gameElements)
        if (!gameElements || !gameData) return;

        const availItems = Object.values(gameElements)
        .filter(e => 
            (
                e.type === 'item' || 
                e.type === 'prizePack'
            ) && 
            e.status === 'active' && 
            e.opt_visible &&
            e.opt_market 
        );
        setAvailableItems(availItems);
        console.log(availItems);
        getSales();
    }, [gameElements, gameData])

    function getSales() {
        const now = new Date().getTime();
        setSaleList(
            Object.values(gameElements)
            .filter(e => 
                e.type === 'sale' &&
                e.status === 'active' &&
                (!e.opt_dateOpen || e.opt_dateOpen < now) &&
                (!e.opt_dateClose || e.opt_dateClose > now)
            )
            .sort((a,b) => a.ts > b.ts ? 1 : -1)
        )
    }

    return (
        <div className='player-market'>
            <div className='g-card market-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className='player-title'>
                    <ImageContainer src='/images/icons/shopping-cart.png' alt='marketplace icon' className='player-title-icon' />
                    Shop the Marketplace
                </div>
                <hr />
                <section className='current-balance'>
                    <span>Current Balance: </span>
                    <ImageContainer src="/images/icons/coin.png" className='balance-coin' />
                    <span>{playerData.currency ? playerData.currency.toLocaleString() : 0}</span>
                </section>
                <div className="g-space-1"></div>
                {
                    (availableItems && gameData) &&
                    <StoreButtonRow
                        availableItems={availableItems}
                        setItemType={setItemType}
                        itemType={itemType}
                        gameData={gameData}
                    />
                }
                <div className="g-space-1"></div>
                <div className='player-market-content'>
                    {
                        (itemType === "tradeportal")
                        ?
                        <PlayerTradePortal
                            gameData={gameData}
                            playerData={playerData}
                            gameElements={gameElements}
                        />
                        :
                        <PlayerStore
                            gameData={gameData}
                            playerData={playerData}
                            availableItems={availableItems}
                            gameElements={gameElements}
                            itemType={itemType}
                            gameStats={gameStats}
                            teamMembers={teamMembers}
                            saleList={saleList}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default PlayerMarketLanding;