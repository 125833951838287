import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFunctions, httpsCallable } from "firebase/functions";

const config = {
    apiKey: "AIzaSyAhsopt7-HhC-ErmHu0dyA33c7OFCsbV1c",
    authDomain: "gamably-app.firebaseapp.com",
    projectId: "gamably-app",
    storageBucket: "gamably-app.appspot.com",
    messagingSenderId: "22760211792",
    appId: "1:22760211792:web:aa67703c11064122b0d855",
    measurementId: "G-7GS30LCXSH"
};

const app = initializeApp(config);

/**
 * 
 * AUTHENTICATION
 * 
 */


export const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
	console.log('signing in')
	signInWithPopup(auth, provider)
	.then((result) => {
		
	}).catch(err => console.log(err))
}

/**
 * 
 * EXPORT DATABASE REFS
 * 
 */

export const db = getFirestore();

export const rtDb = getDatabase();

/**
 * 
 * SIGN OUT
 * 
 */

export const signOut = async () => {
	await auth.signOut();
	return true;
}

/**
 *
 * Use Cloud Function
 *
 */

export const UseCloudFunction = async (command, data) => {
	console.log('cloud function', command)
    const functions = getFunctions();
	const processCommand = httpsCallable(functions, 'processCommands');
	const result = await processCommand({ 
		command: command,
		data: data
	})
  // console.log(result.data)
	const resData = result.data;
	return resData;
}

export const UseCloudDistribute = async (command, data) => {
	console.log('cloud distribute', command)
    const functions = getFunctions();
	const distribute = httpsCallable(functions, 'distributeRewards');
	const result = await distribute({ 
		command: command,
		data: data
	})
  // console.log(result.data)
	const resData = result.data;
	return resData;
}

export const UseCloudGameplay = async (command, data) => {
	console.log('cloud gameplay', command)
    const functions = getFunctions();
	const distribute = httpsCallable(functions, 'gameplay');
	const result = await distribute({ 
		command: command,
		data: data
	})
  // console.log(result.data)
	const resData = result.data;
	return resData;
}