import React, { useEffect, useRef, useState } from 'react'
import './player-challenge-page.styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { format } from 'date-fns';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import linkifyHtml from 'linkify-html';
import PlayerSubmitForm from './player-submit-form.component';
import Modal, { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { useSelector } from 'react-redux';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { UseCloudDistribute, UseCloudGameplay } from '@/utils/firebase.utils';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { prereqFilter } from '@/utils/prereqFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faHourglassHalf, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import UploadedFileButton from '@/components/SHARED/uploaded-file-button/uploaded-file-button.component';
import FullPageLoader from '@/components/loader/full-page-loader.component';
import { RandomString } from '@/utils/randomString';

const PlayerChallengePage = ({ 
    gameData, 
    gameElements, 
    playerData, 
    actionItems 
}) => {
    
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ challengeData, setChallengeData ] = useState(null);
    const { challengePath } = useParams();
    const [ formData, setFormData ] = useState({})
    const [ loading, setLoading ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ completionRecords, setCompletionRecords ] = useState([]);
    const [ completed, setCompleted ] = useState(false);
    const [ access, setAccess ] = useState(false);
    const [ pending, setPending ] = useState(false);
    const [ visible, setVisible ] = useState(true);
    const [ viewSubmissionData, setViewSubmissionData ] = useState(null);
    const dataRef = useRef();
    const navigate = useNavigate();
    const storage = getStorage();
    let challengeListener;
    const approveRef = useRef();

    useEffect(() => {
        return () => {
            if (challengeListener) challengeListener();    
        }
    }, [])

    useEffect(() => {
        if (!challengeData || !gameElements || !playerData) return;
        const accessResult = prereqFilter(challengeData, playerData, gameElements);
        setAccess(accessResult)
    }, [challengeData, gameElements, userProfile]);

    useEffect(() => {
        if (!challengeData || !actionItems) return;
        // console.log(actionItems);
        const relatedActionItems = Object.values(actionItems).filter(i => i.elementId === challengeData.id)
        setCompletionRecords(relatedActionItems);
        for (let r of relatedActionItems) {
            if (
                !challengeData.opt_multipleTries || 
                (
                    !challengeData.opt_multiple && 
                    (
                        r.status === 'approved' || 
                        r.status === 'pending'
                    )
                ) 
            ) {
                console.log('not multiple')
                setCompleted(true);
            }
            if (r.status === 'pending') {
                setPending(true);
            }
        }
    }, [challengeData, actionItems])

    useEffect(() => {
        if (!challengePath || !gameData || !gameElements) return;
        getChallengeData();
    }, [challengePath, gameData, gameElements])

    function getChallengeData() {
        // getSpecificGameElementFromDb({'path': challengePath, 'gameId': gameData.gameId, 'status': 'active', 'callback': callback, 'handleListener': handleListener})
        // function callback(data) {
        //     if (!data.opt_visible) {
        //         setVisible(false);
        //         setAlertMessage('Sorry, this challenge is not currently available.');
        //         return;
        //     }
        //     setVisible(true);
        //     setChallengeData(data);
        // }
        // function handleListener(unsub) {
        //     challengeListener = unsub;
        // }
        const chall = Object.values(gameElements).filter(e => e.path === challengePath)[0];
        if (!chall || chall.type !== 'challenge') {
            navigate(`/play/${gameData.path}`);
            return;
        }
        setChallengeData(chall);
    }

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}}
        setFormData(dataRef.current);
    }

    async function handleSubmit() {
        setConfirmMessage('Are you sure you are ready to submit this Challenge response?');
        approveRef.current = () => {
            continueApproval();
        }

        async function continueApproval() {
            const tempData = {...formData};
            if (!tempData.inputText) {
                setAlertMessage('Be sure to include something in the text box before submitting.');
                return;
            }
            setLoading(true);
            const now = new Date().getTime();
            const gameCode = gameData.gameId.substring(gameData.gameId.indexOf('-')-5, gameData.gameId.indexOf('-')+6)
            tempData.gameId = gameData.gameId;
            tempData.playerId = playerData.playerId;
            tempData.type = 'challenge';
            tempData.elementId = challengeData.id;
            tempData.elementDocNum = challengeData.docNum,
            tempData.id = `${now}-${gameCode}-challenge-actionitem`;
            tempData.status = 'pending';
            tempData.ts = now;
            tempData.ts_submitted = now;
            tempData.path = RandomString(18, 'abcdefghijklmnopqrstuvwxyz-_0123456789');
            if (tempData.upload) {
                let filePath = await uploadFile(tempData.file, now);
                tempData.filePath = filePath;
                delete tempData.upload;
                delete tempData.file;
            }
            console.log(tempData);
            const res = await UseCloudGameplay(
                'submitActionItem', 
                {
                    'playerId': tempData.playerId, 
                    'gameId': gameData.gameId, 
                    'actionItemData': tempData, 
                    'access': 'challenges'
                }
            )
            console.log(res);
            if (res.error) {
                setAlertMessage(`Something went wrong. Please try again later. (Error: ${res.error})`);
                setLoading(false);
                return;
            } else if (res.denied) {
                setAlertMessage(`Sorry, your access was denied. Reason: ${res.denied}`);
                setLoading(false);
                return;
            } 
            setAlertMessage('Your Challenge has been submitted successfully. You will be notified on your home page when your submission has been reviewed!');
            setLoading(false);
            return;
        }
    }

    async function uploadFile(file, now) {
        const filePath = `${gameData.gameId}/${challengeData.id}/${now}-${file.name}`;
        const uploadRef = ref(storage, filePath)
		const metadata = {
			contentType: file.type,
            customMetadata: {
                appId: userProfile.appId,
            }
		};
        await uploadBytes(uploadRef, file, metadata)
        .then(async (snapshot) => {
			// return await getDownloadURL(uploadRef)
			// .then((url) => {
			// 	return url;
			// })
		})
        return filePath;
    }
    
    return (
        <div className='player-challenge-page'>
            <div className='g-card'>
                <BackButton cancel={() => navigate(-1)} />
                {
                    (challengeData && visible)
                    ?
                    <div className='player-challenge-content'>
                        <div className='player-challenge-icon-div'>
                            <ImageContainer src={challengeData.icon} alt={`${challengeData.name} challenge icon`} className='player-challenge-icon' />
                        </div>
                        <h2>{challengeData.name}</h2>
                        <div className='meta'>Posted {challengeData.opt_dateOpen ? format(new Date(challengeData.opt_dateOpen), 'Pp') : format(new Date(challengeData.created), 'Pp')}</div>
                        {
                            (challengeData.opt_preview || access) &&
                            <div className='player-challenge-desc' dangerouslySetInnerHTML={{__html: linkifyHtml(challengeData.desc)}}></div>
                        }
                        <div className='g-space-1'></div>
                        
                        {
                            (access && !completed && !pending)
                            ?
                            <div>
                                <hr />
                                <h3>Submit a Response</h3>
                                <div className='submit-div'>
                                    <PlayerSubmitForm formData={formData} saveData={saveData} />
                                </div>
                                <div className="g-space-1"></div>
                                <hr />
                                {
                                    (loading) 
                                    ?
                                    <div className='buttons'>
                                        <button className='g-button' >Cancel</button>
                                        <button className='g-button ' ><ThreeDotsLoader /></button>
                                    </div>
                                    :
                                    <div className='buttons'>
                                        <button className='g-button' onClick={() => navigate(`/play/${gameData.path}/challenges`)}>Cancel</button>
                                        <button className='g-button primary' onClick={() => handleSubmit()}>Submit Your Response</button>
                                    </div>
                                }
                            </div>
                            :
                            (!access && !completed)
                            ?
                            <div className='no-access'>
                                <hr />
                                <h4>You do not have access to submit a response for this challenge at this time.</h4>
                            </div>
                            :
                            null
                        }
                        {
                            (completionRecords.length > 0) &&
                            <div className='completion-records'>
                                <hr />
                                <h3>Previous Submissions</h3>
                                <div className='record-list'>
                                    <div className='record records-header'>
                                        <div></div>
                                        <div>Status</div>
                                        <div>Submitted</div>
                                        <div>Evaluated</div>
                                        <div>Comments</div>
                                        <div></div>
                                    </div>
                                    {
                                        completionRecords.sort((a,b) => a.ts < b.ts ? 1 : -1)
                                        .map(a => (
                                            <div key={a.id} className='record g-list-item'>
                                                <div>
                                                <FontAwesomeIcon icon={a.status === 'approved' ? faCheck : a.status === 'declined' ? faBan : a.status === 'pending' ? faHourglassHalf : null} className={a.status} size='xl' />
                                                </div>    
                                                <div className='outcome'>{a.status.toUpperCase()}</div>
                                                <div className='date'>{format(new Date(a.ts_submitted), "Pp")}</div>
                                                <div className='evaluated'>{a.approval_ts ? format(new Date(a.approval_ts), "Pp") : ''}</div>
                                                <div className='comments'>{a.comments ? <span>{a.comments}<br />- {a.approval_name}</span> : ''}</div>
                                                <div>
                                                    <button className='g-button small-btn' onClick={() => setViewSubmissionData(a)}><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    :
                    (!visible)
                    ?
                    null
                    :
                    <div className='center'><ThreeDotsLoader /></div>
                }
                <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
                <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
                <Modal show={viewSubmissionData} cancel={() => setViewSubmissionData(null)} closeButton={true} text='View what you submitted'>   
                    {
                        (viewSubmissionData) &&
                        <div className='modal-children'>
                            <div dangerouslySetInnerHTML={{__html: linkifyHtml(viewSubmissionData.inputText, {'target': '_blank'})}}></div>
                            <div className='g-space-1'></div>
                            {
                                (viewSubmissionData.filePath) &&
                                <UploadedFileButton path={viewSubmissionData.filePath} name={viewSubmissionData.fileName} type={viewSubmissionData.fileType} />
                            }
                            {
                                (!viewSubmissionData.filePath && viewSubmissionData.fileName) &&
                                <div className='meta'>
                                    Submitted File: {viewSubmissionData.fileName}
                                </div>
                            }
                        </div>
                    }
                    <div className="g-space-1"></div>
                    <div className='buttons'>
                        <button className='g-button' onClick={() => setViewSubmissionData(null)}>Ok</button>
                    </div>
                </Modal>
            </div>
            <FullPageLoader show={loading} />
        </div>
    )
}

export default PlayerChallengePage