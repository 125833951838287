import React, { useState } from 'react';
import './org-invite-modal.styles.scss';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import Modal from '@/components/modal/modal.component';
import FullPageLoader from '@/components/loader/full-page-loader.component';

const OrgInviteModal = ({ show, cancel, orgData, invites }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ loading, setLoading ] = useState(false);

    async function sendInvites() {
        const existingEmails = Object.values(invites)
        .filter(i => i.status !== 'declined').map(i => i.email);
        const emailList = document.getElementById('email-list').value;
        const regex = /[, \n]/g;
        // Split the string using the regular expression
        const emails = emailList.split(regex);
        // Remove extra spaces from each word and convert to lowercase
        const processedEmails = emails.filter(word => word !== '').map(word => word.trim().toLowerCase());
        
        for (let e of processedEmails) {
            if (existingEmails.includes(e)) {
                processedEmails.splice(processedEmails.indexOf(e), 1);
            }
        }
        console.log(processedEmails);
        document.getElementById('email-list').value = '';
        setLoading(true);
        const res = await UseCloudFunction(
            'saveOrgInvites',
            {
                'orgData': orgData,
                'appId': userProfile.appId,
                'emailArr': processedEmails,
                'role': 'member'
            }
        )
        console.log(res);
        setLoading(false);
        cancel();
    }

    return (
        <div className='org-invite-modal'>
            <Modal 
                show={show}
                cancel={cancel}
                closeButton={true}
                text='Invite Users to the Organization' 
            >
                <div className='field'>
                    <label>Add Email Addresses here, separated by commas, spaces, or new lines.</label>
                    <textarea id='email-list' rows='3'></textarea>
                </div>
                <hr />
                <div className='buttons'>
                    <button 
                        className='g-button'
                        onClick={cancel}
                    >Cancel</button>
                    <button 
                        className='g-button primary'
                        onClick={() => sendInvites()}
                    >
                        Send Invites
                    </button>
                </div>
            </Modal>
            <FullPageLoader
                show={loading}
                />
        </div>
    )
}

export default OrgInviteModal