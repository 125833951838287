import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import PlayerChallenges from './challenges/challenges.component'
import PlayerGroups from './player-blocks/groups.component'
import PlayerHomeContent from './player-blocks/player-home-content.component'
import PlayerQuests from './player-blocks/quests.component'
import PlayerRewards from './rewards/rewards.component'
import './player-game-content.styles.scss'
import PlayerSideMenu from './side-menu.component'
import { useDispatch, useSelector } from 'react-redux'
import NewRewardModal from './player-blocks/modals/new-reward-modal.component'
import SubmitCode from './submit-code/submit-code.component'
import MyXpEarningsList from './player-blocks/components/status-elements/xp/my-xp-earnings-list.component'
import MyBadges from './player-blocks/components/status-elements/badges/my-badges.component'
import BadgeView from './player-blocks/components/status-elements/badges/badge-view.component'
import MyLevelsList from './player-blocks/components/status-elements/levels/my-levels-list.component'
import MyCurrencyEarningsList from './player-blocks/components/status-elements/currency/my-currency-earnings-list.component'
import MySecretCodesList from './player-blocks/components/status-elements/secret-codes/secret-codes-list.component'
import MyChallengesList from './player-blocks/components/status-elements/challenges/my-challenges-list.component'
import PlayerLeaderboardsContainer from './leaderboards/player-leaderboards-container.component'
import PlayerMarketLanding from './store/player-market-landing.component'
import ShoppingCart from './store/shopping-cart.component'
import InventoryLanding from './inventory/inventory-landing.component'
import DeclinedChallengeModal from './player-blocks/modals/declined-challenge-modal.component'
import AcceptedItemModal from './player-blocks/modals/accepted-item-modal.component'
import GameActivity from './player-blocks/game-activity.component'
import PurchaseView from '../SHARED/tranaction-view/transaction-view.component'
import TransactionView from '../SHARED/tranaction-view/transaction-view.component'
import useDocumentTitle from '@/utils/useDocumentTitle'

const PlayerGameContent = ({ 
    gameData, 
    playerData, 
    gameElements, 
    actionItems, 
    gameStats, 
    playerList, 
    gameActivity,
    teamMembers,
    myTeams,
    latestMessage
}) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const [ newReward, setNewReward ] = useState(false);
    const [ playerActivity, setPlayerActivity ] = useState([]);
    const dispatch = useDispatch();

    // useDocumentTitle(gameData.name, gameData.icon);

    useEffect(() => {
        if (!gameActivity || !playerData) return;
        // console.log(gameActivity);
        const playerAct = Object.values(gameActivity).filter(a => a.playerId === playerData.playerId)
        let obj = {}
        for (let p of playerAct) {
            obj[p.id] = p;
        }
        setPlayerActivity({...obj});
        // window.localStorage.setItem(`playerActivity_${playerId}`, JSON.stringify(activityRef.current));
    }, [gameActivity, playerData])

    return (
        <div className='player-game-content'>

            {
                (playerData) &&
                <> 
                <div className="side-menu-container">
                    <div className='g-card'>
                        <PlayerSideMenu 
                            gameData={gameData} 
                            playerData={playerData} 
                            playerActivity={playerActivity}
                        />
                    </div>
                </div>
                <div className="game-content">
                    <Routes>
                        <Route path='' element={
                            <PlayerHomeContent 
                                gameData={gameData} 
                                playerData={playerData} 
                                gameElements={gameElements} 
                                playerActivity={playerActivity} 
                                actionItems={actionItems} 
                                playerList={playerList}
                                latestMessage={latestMessage}
                            />
                        }></Route>
                        <Route path='inventory/*' element={
                            <InventoryLanding
                                gameData={gameData} 
                                playerData={playerData} 
                                gameElements={gameElements} 
                                gameActivity={gameActivity} 
                                gameStats={gameStats}
                                teamMembers={teamMembers}
                                myTeams={myTeams}
                                playerActivity={playerActivity}
                            />
                        }></Route>
                        <Route path='rewards' element={
                            <PlayerRewards 
                                gameData={gameData} 
                                playerData={playerData} 
                                gameElements={gameElements} 
                                playerActivity={playerActivity}
                            />
                        }></Route>
                        <Route path='secretcode' element={
                            <SubmitCode 
                                gameData={gameData} 
                                playerData={playerData} 
                                playerActivity={playerActivity} 
                                gameElements={gameElements} 
                            />
                        }></Route>
                        <Route path='marketplace' element={
                            <PlayerMarketLanding 
                                gameData={gameData} 
                                playerData={playerData} 
                                gameElements={gameElements} 
                                gameStats={gameStats}
                                teamMembers={teamMembers}
                                myTeams={myTeams}
                            />
                        }></Route>
                        <Route path='marketplace/shoppingcart' element={
                            <ShoppingCart
                                gameData={gameData} 
                                playerData={playerData} 
                                gameElements={gameElements}
                                gameStats={gameStats} 
                                teamMembers={teamMembers}
                                myTeams={myTeams}
                            />
                        }></Route>
                        <Route path='challenges/*' element={
                            <PlayerChallenges 
                                gameData={gameData} 
                                playerData={playerData} 
                                gameElements={gameElements} 
                                actionItems={actionItems} 
                                myTeams={myTeams}
                            />
                        }></Route>
                        <Route path='quests/*' element={
                            <PlayerQuests 
                                gameData={gameData} 
                                playerData={playerData} 
                                gameElements={gameElements} 
                                myTeams={myTeams}
                            />
                        }></Route>
                        
                        <Route path='leaderboards/*' element={
                            <PlayerLeaderboardsContainer 
                                gameData={gameData} 
                                gameElements={gameElements} />
                        }></Route>
                        <Route path='gameactivity' element={
                            <GameActivity 
                                gameData={gameData} 
                                playerData={playerData} 
                                gameElements={gameElements} 
                                playerList={playerList}
                                gameActivity={gameActivity}
                            />
                        }></Route>
                        <Route path='myxpearnings' element={
                            <MyXpEarningsList 
                                playerData={playerData} 
                                gameElements={gameElements} 
                                playerActivity={playerActivity} 
                            />
                        }></Route>
                        <Route path='mybadges' element={
                            <MyBadges 
                                playerData={playerData} 
                                gameElements={gameElements} 
                                playerActivity={playerActivity} 
                            />
                        }></Route>
                        <Route path='mybadges/:badgePath' element={
                            <BadgeView 
                                playerData={playerData} 
                                gameElements={gameElements} 
                                playerActivity={playerActivity} 
                            />
                        }></Route>
                        <Route path='mylevels' element={
                            <MyLevelsList 
                                playerData={playerData} 
                                gameElements={gameElements} 
                                playerActivity={playerActivity} 
                            />
                        }></Route>
                        <Route path='mylevels/:levelPath' element={
                            <BadgeView 
                                playerData={playerData} 
                                gameElements={gameElements} 
                                playerActivity={playerActivity} 
                            />
                        }></Route>
                        <Route path='mycurrency' element={
                            <MyCurrencyEarningsList
                                playerData={playerData} 
                                gameElements={gameElements} 
                                playerActivity={playerActivity} 
                            />
                        }></Route>
                        <Route path='mysecretcodes' element={
                            <MySecretCodesList
                                playerData={playerData} 
                                gameElements={gameElements} 
                                playerActivity={playerActivity} 
                            />
                        }></Route>
                        <Route path='mychallenges' element={
                            <MyChallengesList
                                gameData={gameData}
                                playerData={playerData} 
                                gameElements={gameElements} 
                                playerActivity={playerActivity} 
                            />
                        }></Route>
                        <Route path='transaction/:transPath' element={
                            <div className='purchase-details-container'>
                                <div className='purchase-details g-card'>
                                    <TransactionView
                                        gameData={gameData}
                                        gameElements={gameElements}
                                        playerList={playerList}
                                        purchases={Object.values(playerActivity)}
                                        gameActivity={gameActivity}
                                    />
                                </div>
                            </div>
                        }></Route>
                        <Route path='*' element={<Navigate replace to="/404" />}></Route>
                    </Routes>
                </div>
                </>
            }
            <NewRewardModal gameData={gameData} playerData={playerData} playerActivity={playerActivity} />
            <DeclinedChallengeModal gameData={gameData} playerData={playerData} playerActivity={playerActivity} gameElements={gameElements}/>
            <AcceptedItemModal gameData={gameData} playerData={playerData} playerActivity={playerActivity} gameElements={gameElements}/>
        </div>
    )
}

export default PlayerGameContent