import React, { useEffect, useState } from 'react'
import './new-reward-modal.styles.scss'

import Modal from '@/components/modal/modal.component'
import { useNavigate } from 'react-router-dom'
import ImageContainer from '@/components/SHARED/image-container/image-container.component'
import { setNewStuffCount } from '@/state/slices/contentSlice'
import { properCase } from '@/utils/properCase'

const NewRewardModal = ({ 
    gameData, 
    playerData, 
    playerActivity 
}) => {
    
    const [ show, setShow ] = useState(null);
    const [ modalImage, setModalImage ] = useState(null);
    const [ modalText, setModalText ] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!playerData || !playerActivity) return;
        
        let savedNewRewardCount = window.localStorage.getItem(`${gameData.gameId}_newRewardCount`);
        if (!savedNewRewardCount) {
            savedNewRewardCount = 0;
            window.localStorage.setItem(`${gameData.gameId}_newRewardCount`, 0);
        }

        if (playerData.newStuff && playerData.newStuff.length > 0) {
            const rewardList = Object.values(playerActivity)
            .filter(a => 
                playerData.newStuff.includes(a.id) && 
                (
                    a.display === 'reward' ||
                    a.display === 'achievement' ||
                    a.display === 'prizepack'
                )
            );
            setNewStuffCount(rewardList.length)
            if (rewardList.length > savedNewRewardCount) {
                if (rewardList.filter(r => r.display === 'reward').length > 0) {
                    setShow('reward');    
                } else if (rewardList.filter(r => r.display === 'achievement').length > 0) {
                    setShow('achievement');
                } else if (rewardList.filter(r => r.display === 'prizepack').length > 0) {
                    setShow('prizepack');
                }
                return;
            }
        }
    }, [playerData, playerActivity])

    useEffect(() => {
        if (!show) return;
        if (show === 'reward') {
            setModalImage('/images/icons/treasure-cl.png');
            setModalText('You have New Rewards to Discover!');
        } else if (show === 'achievement') {
            setModalImage('/images/icons/achievement_frame.gif');
            setModalText('You have New Achievements!<br />Check out what you have earned.');
        } else if (show === 'prizepack') {
            setModalImage('/images/icons/prizepack_frame.gif');
            setModalText('You Have New Prize Packs to Open!');
        }
    }, [show])

    function goToRewards() {
        window.localStorage.setItem(`${gameData.gameId}_newRewardCount`, playerData.newStuff.length);
        setShow(null);
        navigate('rewards');
    }

    function setLater(e) {
        window.localStorage.setItem(`${gameData.gameId}_newRewardCount`, playerData.newStuff.length);
        setShow(null);
    }

    return (
        <div>
            <Modal show={show} cancel={() => setShow(null)} cls='narrow'>
                <div className='modal-children new-reward-modal'>
                    <ImageContainer src={modalImage} alt='reward notification icon' className='treasure-gif' />
                    <h2><div dangerouslySetInnerHTML={{__html: modalText}}></div></h2>
                    <hr />
                    <div className="buttons">
                        <button className='g-button' onClick={() => setLater()}>Later</button>
                        <button className="g-button primary" onClick={() => goToRewards()}>Go to My Rewards!</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default NewRewardModal